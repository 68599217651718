import { sortValues } from "../../helper/fllter";
import { FilterItemType, RootState } from "../../types";

export const getFactoryList = (state: RootState) => {
  return state.factory.factoryList;
};

export const getFactoryInfoList = (state: RootState) => {
  return state.factory.factoryInfoList;
};

export const getIsLoading = (state: RootState) => {
  return state.factory.isLoading;
};

export const getSelectedFilter = (state: RootState) => {
  return state.factory.selectedFilter;
};

export const getSortingInfo = (state: RootState) => {
  return state.factory.sortingInfo;
};

export const getFilterOptions = (state: RootState) => {
  const equipmentTypes =
    state.factory.equipmentTypes || state.common.equipmentType;
  return {
    country: {
      type: FilterItemType.MultiSelect,
      inputLabelText: "Country",
      labelKey: "value",
      options: sortValues(
        state.common.countries?.map((item) => ({
          id: item.id,
          value: item.name,
        })),
      ),
    },
    factory: {
      type: FilterItemType.MultiSelect,
      inputLabelText: "Factory",
      labelKey: "value",
      options: sortValues(
        state?.factory?.factoryList?.map((item) => ({
          id: item.id,
          value: item?.name,
        })) || [],
      ),
    },
    company: {
      type: FilterItemType.MultiSelect,
      labelKey: "value",
      inputLabelText: "Company",
      options: sortValues(
        state?.common?.company
          ?.filter(
            (item) =>
              item?.company?.companyShortName && item?.factories?.length,
          )
          ?.map((item) => ({
            id: item?.company?.id,
            value: item?.company?.companyShortName,
          })),
      ),
    },
    factoryFunctions: {
      type: FilterItemType.MultiSelect,
      labelKey: "value",
      inputLabelText: "Factory Functions",
      options: sortValues(
        state.common.factoryFunctions?.map((item) => ({
          id: item.id,
          value: item.function,
        })),
      ),
    },
    equipmentType: {
      type: FilterItemType.MultiSelect,
      labelKey: "value",
      inputLabelText: "Equipment Type",
      options: sortValues(
        equipmentTypes?.map((item) => ({
          id: item.id,
          value: item.type,
        })),
      ),
    },
    factoryStatus: {
      type: FilterItemType.MultiSelect,
      labelKey: "value",
      inputLabelText: "Factory Status",
      options: sortValues(
        state.common.factoryStatus?.map((item) => ({
          id: item.id,
          value: item.status,
        })),
      ),
    },
  };
};
