import { InformationRequestBody, LeaseInfo } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = InformationRequestBody;

type ResponseData = { data: LeaseInfo[] };

export const getRegionListInfo: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/Region/ListWithFilters?api-version=1.0`,
  method: "POST",
  data,
});
