import { FC, MouseEvent } from "react";
import { Box } from "@esgian/esgianui";
import moment from "moment";

import { ReactComponent as StartIcon } from "../../assets/star.svg";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import {
  HoverEventParam,
  ThemeModeEnum,
  TIMELINE_COLOR,
  TimelineLeaseRoundKey,
} from "../../types";

type DotProp = {
  resource: string;
  position: number;
  type: TimelineLeaseRoundKey;
  onHover: (param: HoverEventParam | null) => void;
};

const DotIcon: FC<DotProp> = ({ type, position, onHover, resource }) => {
  let color;
  switch (type) {
    case "Accepting proposals":
      color = "#FF9800";
      break;
    case "Closed":
      color = "#EF5350";
      break;
    case "Completed":
      color = "#4CAF50";
      break;
    default:
      color = "#FFF";
  }

  const handleMouseMove = (event: MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLElement;
    const name = target.className.split(" ")?.[0];
    onHover({ name, target, type: "Dot", resource });
  };

  const handleMouseOut = () => {
    onHover(null);
  };

  return (
    <Box
      sx={{
        position: "absolute",
        left: `${position}%`,
        color: color,
        display: "flex",
        height: 22,
        alignItems: "center",
      }}
    >
      <Box
        className={`${type.replace(/\s+/g, "-").toLocaleLowerCase()}-dot`}
        onMouseMove={handleMouseMove}
        onMouseOut={handleMouseOut}
        sx={{
          width: 9,
          height: 9,
          borderRadius: "50%",
          backgroundColor: "currentColor",
        }}
      />
    </Box>
  );
};

type Props = {
  resource: string;
  startDate: Date;
  endDate: Date;
  closeDate?: Date;
  level: number;
  status?: string;
  estimated?: boolean;
  onHover: (param: HoverEventParam | null) => void;
};

export const TimeLineEvent: FC<Props> = ({
  resource,
  startDate,
  endDate,
  closeDate,
  level,
  status,
  estimated,
  onHover,
}) => {
  const mStartDate = moment(startDate);
  const daysCount = moment(endDate).diff(mStartDate, "days");
  const themeMode = useSelector(getThemeMode);

  const handleMouseMove = (
    event: MouseEvent<HTMLButtonElement>,
    selectedResource: string,
  ) => {
    const target = event.target as HTMLElement;
    const name = target.className.split(" ")?.[0];
    onHover({ name, target, type: "Bar", resource: selectedResource });
  };

  const handleMouseOut = () => {
    onHover(null);
  };

  return (
    <Box
      sx={{
        height: 52,
        // pl: 4,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ position: "relative", display: "flex", alignItems: "center" }}>
        {level <= 2 ? (
          <>
            <Box
              sx={{
                width: "100%",
                height: estimated ? 4 : 2,
                background:
                  themeMode === ThemeModeEnum.Dark
                    ? estimated
                      ? `repeating-linear-gradient(
                    45deg,
                    white 0 10px,
                    #88C7FF 10px 15px
                  )`
                      : "white"
                    : estimated
                      ? `repeating-linear-gradient(
                      45deg,
                      black 0 10px,
                      #88C7FF 10px 15px
                    )`
                      : "black",
              }}
            />
            <DotIcon
              position={0}
              type={TimelineLeaseRoundKey.AcceptingProposals}
              resource={resource}
              onHover={(e) => onHover(e)}
            />
            {closeDate ? (
              <DotIcon
                type={TimelineLeaseRoundKey.Closed}
                position={
                  (moment(closeDate).diff(startDate, "days") / daysCount) * 100
                }
                resource={resource}
                onHover={(e) => onHover(e)}
              />
            ) : null}
            <DotIcon
              resource={resource}
              onHover={(e) => onHover(e)}
              position={97}
              type={TimelineLeaseRoundKey.Completed}
            />
          </>
        ) : (
          <Box sx={{ width: "100%", position: "relative" }}>
            <Box
              sx={{
                background: status
                  ? TIMELINE_COLOR[status.toLocaleLowerCase()]
                  : TIMELINE_COLOR.submitted,
                height: 24,
                mt: 1,
                borderRadius: "4px",
                width: "100%",
              }}
              className={`${status?.toLocaleLowerCase()}-bar`}
              onMouseMove={(e: MouseEvent<HTMLButtonElement>) =>
                handleMouseMove(e, resource)
              }
              onMouseOut={handleMouseOut}
            />
            {status?.toLocaleLowerCase() === "successful" ? (
              <StartIcon
                style={{ position: "absolute", top: "10px", right: "-18px" }}
              />
            ) : undefined}
          </Box>
        )}
      </Box>
    </Box>
  );
};
