import { AnyLayer, Map } from "mapbox-gl";
import wellknown from "wellknown";

import { THRESHOLD_ZOOM } from "../../../helper/map";
import {
  FeatureTypeEnum,
  GenericType,
  MapFeature,
  MapLayer,
  MapSource,
  ThemeModeEnum,
} from "../../../types";

const DarkPolygonLayer: AnyLayer = {
  id: MapLayer.Polygon,
  type: "fill",
  source: MapSource.Polygon,
  paint: {
    "fill-color": [
      "case",
      ["==", ["get", "regionTypeId"], 3],
      "#FFFFFF",
      ["==", ["feature-state", "highlighted"], true],
      [
        "case",
        ["==", ["get", "status"], "Proposed"],
        "#5776FF",
        ["==", ["get", "status"], "Confirmed"],
        "#8DCCFF",
        ["==", ["get", "status"], "Installing"],
        "#9DF9FF",
        ["==", ["get", "status"], "Operational"],
        "#C4A9F9",
        ["==", ["get", "status"], "Decommissioned"],
        "#929292",
        ["==", ["get", "status"], "Suspended"],
        "#818589",
        ["==", ["get", "regionTypeId"], 3],
        "#D1D4EE",
        "green",
      ],
      ["==", ["get", "status"], "Proposed"],
      "#143BE7",
      ["==", ["get", "status"], "Confirmed"],
      "#2DA0FF",
      ["==", ["get", "status"], "Installing"],
      "#53C6CD",
      ["==", ["get", "status"], "Operational"],
      "#795CB2",
      ["==", ["get", "status"], "Decommissioned"],
      "#929292",
      ["==", ["get", "status"], "Suspended"],
      "#696969",
      "yellow",
    ],
    "fill-outline-color": "#000000",
  },
  minzoom: THRESHOLD_ZOOM,
};

const LightPolygonLayer: AnyLayer = {
  id: MapLayer.Polygon,
  type: "fill",
  source: MapSource.Polygon,
  paint: {
    "fill-color": [
      "case",
      ["==", ["get", "regionTypeId"], 3],
      "#000000",
      ["==", ["feature-state", "highlighted"], true],
      [
        "case",
        ["==", ["get", "status"], "Proposed"],
        "#5776FF",
        ["==", ["get", "status"], "Confirmed"],
        "#8DCCFF",
        ["==", ["get", "status"], "Installing"],
        "#9DF9FF",
        ["==", ["get", "status"], "Operational"],
        "#C4A9F9",
        ["==", ["get", "status"], "Decommissioned"],
        "#929292",
        ["==", ["get", "status"], "Suspended"],
        "#818589",
        ["==", ["get", "regionTypeId"], 3],
        "#000000",
        "green",
      ],
      ["==", ["get", "status"], "Proposed"],
      "#143BE7",
      ["==", ["get", "status"], "Confirmed"],
      "#2DA0FF",
      ["==", ["get", "status"], "Installing"],
      "#53C6CD",
      ["==", ["get", "status"], "Operational"],
      "#795CB2",
      ["==", ["get", "status"], "Decommissioned"],
      "#929292",
      ["==", ["get", "status"], "Suspended"],
      "#696969",
      "yellow",
    ],
    "fill-outline-color": "#D1D4EE",
  },
  minzoom: THRESHOLD_ZOOM,
};

const DarkPointLayer: AnyLayer = {
  id: MapLayer.Point,
  type: "circle",
  source: MapSource.Point,
  paint: {
    "circle-color": [
      "case",
      ["==", ["get", "regionTypeId"], 3],
      "#FFFFFF",
      ["==", ["feature-state", "highlighted"], true],
      [
        "case",
        ["==", ["get", "status"], "Proposed"],
        "#5776FF",
        ["==", ["get", "status"], "Confirmed"],
        "#8DCCFF",
        ["==", ["get", "status"], "Installing"],
        "#9DF9FF",
        ["==", ["get", "status"], "Operational"],
        "#C4A9F9",
        ["==", ["get", "status"], "Decommissioned"],
        "#929292",
        ["==", ["get", "status"], "Suspended"],
        "#818589",
        ["==", ["get", "regionTypeId"], 3],
        "#D1D4EE",
        "green",
      ],
      ["==", ["get", "status"], "Proposed"],
      "#143BE7",
      ["==", ["get", "status"], "Confirmed"],
      "#2DA0FF",
      ["==", ["get", "status"], "Installing"],
      "#53C6CD",
      ["==", ["get", "status"], "Operational"],
      "#795CB2",
      ["==", ["get", "status"], "Decommissioned"],
      "#696969",
      ["==", ["get", "status"], "Suspended"],
      "#818589",
      "yellow",
    ],
    "circle-stroke-color": "#000000",
    "circle-stroke-width": 1,
    "circle-radius": 5,
  },
  minzoom: THRESHOLD_ZOOM,
};

const LightPointLayer: AnyLayer = {
  id: MapLayer.Point,
  type: "circle",
  source: MapSource.Point,
  paint: {
    "circle-color": [
      "case",
      ["==", ["get", "regionTypeId"], 3],
      "#FFFFFF",
      ["==", ["feature-state", "highlighted"], true],
      [
        "case",
        ["==", ["get", "status"], "Proposed"],
        "#5776FF",
        ["==", ["get", "status"], "Confirmed"],
        "#8DCCFF",
        ["==", ["get", "status"], "Installing"],
        "#9DF9FF",
        ["==", ["get", "status"], "Operational"],
        "#C4A9F9",
        ["==", ["get", "status"], "Decommissioned"],
        "#929292",
        ["==", ["get", "status"], "Suspended"],
        "#818589",
        ["==", ["get", "regionTypeId"], 3],
        "#D1D4EE",
        "green",
      ],
      ["==", ["get", "status"], "Proposed"],
      "#143BE7",
      ["==", ["get", "status"], "Confirmed"],
      "#2DA0FF",
      ["==", ["get", "status"], "Installing"],
      "#53C6CD",
      ["==", ["get", "status"], "Operational"],
      "#795CB2",
      ["==", ["get", "status"], "Decommissioned"],
      "#929292",
      ["==", ["get", "status"], "Suspended"],
      "#818589",
      "yellow",
    ],
    "circle-stroke-color": "#000000",
    "circle-stroke-width": 1,
    "circle-radius": 5,
  },
  minzoom: THRESHOLD_ZOOM,
};

export class Polygon {
  init(map: Map, themeMode: ThemeModeEnum) {
    map.addSource(MapSource.Polygon, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    if (themeMode === ThemeModeEnum.Dark) {
      map.addLayer(DarkPolygonLayer);
    } else {
      map.addLayer(LightPolygonLayer);
    }

    map.addSource(MapSource.PolygonLabel, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer({
      id: MapLayer.PolygonLabel,
      type: "symbol",
      source: MapSource.PolygonLabel,
      layout: {
        "text-field": ["get", "name"],
        "text-size": ["interpolate", ["linear"], ["zoom"], 6, 14, 7, 18, 8, 22],
        "text-line-height": 1,
        "text-anchor": "center",
        "text-offset": [0, 0],
        "text-allow-overlap": false,
        "text-ignore-placement": false,
      },
      paint: {
        "text-color": "#FFFFFF",
        "text-halo-color": "#000000",
        "text-halo-width": 2,
        "text-halo-blur": 0,
      },
      minzoom: THRESHOLD_ZOOM,
    });

    map.addSource(MapSource.Point, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });
    if (themeMode === ThemeModeEnum.Dark) {
      map.addLayer(DarkPointLayer);
    } else {
      map.addLayer(LightPointLayer);
    }

    map.addSource(MapSource.PointLabel, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    map.addLayer({
      id: MapLayer.PointLabel,
      type: "symbol",
      source: MapSource.PointLabel,
      layout: {
        "text-field": ["get", "name"],
        "text-size": 16,
        "text-line-height": 1,
        "text-anchor": "center",
        "text-offset": [0, 0],
        "text-allow-overlap": false,
        "text-ignore-placement": false,
      },
      paint: {
        "text-color": "#FFFFFF",
        "text-halo-color": "#000000",
        "text-halo-width": 2,
        "text-halo-blur": 0,
      },
      minzoom: THRESHOLD_ZOOM,
    });
  }

  polygonFeature = (feature: GenericType): GeoJSON.Feature => {
    return {
      id: feature.windProjectId || feature.regionId,
      type: "Feature",
      properties: {
        id: feature.windProjectId || feature.regionId,
        status: feature.status,
        regionTypeId: feature.regionTypeId,
        name: feature.name,
      },
      geometry: wellknown.parse(feature.polygon),
    } as GeoJSON.Feature;
  };

  update(map: Map, polygonsData: MapFeature[]) {
    const polygonsFeatures: GeoJSON.Feature[] = [];
    const pointFeatures: GeoJSON.Feature[] = [];
    const undefinedProjectList: MapFeature[] = [];
    const sortedData = [...polygonsData].sort((a, b) => {
      if (a.regionTypeId === 3 && b.regionTypeId !== 3) {
        return -1;
      }
      if (a.regionTypeId !== 3 && b.regionTypeId === 3) {
        return 1;
      }
      return 0;
    });
    sortedData?.forEach((item) => {
      if (item.status === FeatureTypeEnum.ProjectUndefined) {
        undefinedProjectList.push(item);
      } else {
        const feature = this.polygonFeature(item);
        if (feature.geometry.type === "Point") {
          pointFeatures.push(feature);
        } else {
          polygonsFeatures.push(feature);
        }
      }
    });
    const newPolygonData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: "FeatureCollection",
      features: polygonsFeatures,
    };

    const source = map.getSource(MapSource.Polygon) as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData(newPolygonData);
    }

    const pointSource = map.getSource(
      MapSource.Point,
    ) as mapboxgl.GeoJSONSource;
    if (pointSource) {
      pointSource.setData({
        type: "FeatureCollection",
        features: pointFeatures,
      });
    }
    this.visible(map);
  }

  disable(map: Map) {
    if (map.getLayer(MapLayer.Polygon)) {
      map.setLayoutProperty(MapLayer.Polygon, "visibility", "none");
      map.setLayoutProperty(MapLayer.PolygonLabel, "visibility", "none");
      map.setLayoutProperty(MapLayer.Point, "visibility", "none");
      map.setLayoutProperty(MapLayer.PointLabel, "visibility", "none");
      // map.setLayoutProperty(MapLayer.UndefinedIcon, "visibility", "none");
    }
  }

  visible(map: Map) {
    if (map.getLayer(MapLayer.Polygon)) {
      map.setLayoutProperty(MapLayer.Polygon, "visibility", "visible");
      // map.setLayoutProperty(MapLayer.UndefinedIcon, "visibility", "visible");
    }
    if (map.getLayer(MapLayer.Point)) {
      map.setLayoutProperty(MapLayer.Point, "visibility", "visible");
    }
  }

  remove(map: Map) {
    if (map.getSource(MapSource.Polygon)) {
      map.removeLayer(MapLayer.Polygon);
      map.removeLayer(MapLayer.PolygonLabel);
      map.removeSource(MapSource.Polygon);
      map.removeSource(MapSource.PolygonLabel);
    }

    if (map.getSource(MapSource.Undefined)) {
      map.removeLayer(MapLayer.UndefinedIcon);
      map.removeImage(MapLayer.UndefinedImage);
      map.removeSource(MapSource.Undefined);
    }

    if (map.getSource(MapSource.Point)) {
      map.removeLayer(MapLayer.Point);
      map.removeLayer(MapLayer.PointLabel);
      map.removeSource(MapSource.Point);
      map.removeSource(MapSource.PointLabel);
    }
  }

  resetState(map: Map) {
    map.getCanvas().style.cursor = "";
    map.removeFeatureState({
      source: MapSource.Polygon,
      sourceLayer: MapLayer.Polygon,
    });
    map.removeFeatureState({
      source: MapSource.Point,
      sourceLayer: MapLayer.Point,
    });
  }
}
