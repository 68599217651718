import { getLeaseRegion } from "./getLeaseRegion";
import { getRegionListInfo } from "./getLeaseRegionListInfo";
import { getLeaseRound } from "./getLeaseRound";
import { getRoundListInfo } from "./getLeaseRoundListInfo";
import { getLeaseRoundTimeline } from "./getLeaseRoundTimeline";

export const lease = {
  getLeaseRound,
  getLeaseRegion,
  getRegionListInfo,
  getRoundListInfo,
  getLeaseRoundTimeline
};
