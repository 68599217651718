import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@esgian/esgianui";

import { ProjectDrawer } from "../../components";
import { FactoryDrawer } from "../../components/Drawer/FactoryDrawer";
import { FloatingTechnologyDrawer } from "../../components/Drawer/FloatingTechnologyDrawer";
import { InstallationDrawer } from "../../components/Drawer/Installation";
import { TurbineModelDrawer } from "../../components/Drawer/TurbineModelDrawer";
import { EquipmentContract } from "../../components/EquipmentContract";
import { VesselContract } from "../../components/VesselContract";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getSelectedFactoryId,
  getSelectedFloatingTechnologyId,
  getSelectedInstallationId,
  getSelectedProjectId,
  getSelectedTurbineModelId,
} from "../../store/selector/common";
import {
  fetchLookup,
  setSearchObjectCoordinates,
  setSearchText,
  setSelectedFactoryId,
  setSelectedFloatingTechnologyId,
  setSelectedInstallationId,
  setSelectedProjectId,
  setSelectedTurbineModelId,
  setViewTab,
} from "../../store/slice/common";
import { ContractTabEnum, ROUTES_CONFIG, ViewTabEnum } from "../../types";

export const Contract = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedProjectId = useSelector(getSelectedProjectId);
  const selectedFactoryId = useSelector(getSelectedFactoryId);
  const selectedInstallationId = useSelector(getSelectedInstallationId);
  const selectedTurbineModelId = useSelector(getSelectedTurbineModelId);
  const selectedFloatingTechnologyId = useSelector(
    getSelectedFloatingTechnologyId,
  );
  const [tab, setTab] = useState<ContractTabEnum>(ContractTabEnum.Equipment);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTab(event.target.value as ContractTabEnum);
  };

  useEffect(() => {
    dispatch(fetchLookup(["equipmentType"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(setViewTab(ViewTabEnum.List));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLeaseRoundClick = (leaseRoundId: number) => {
    navigate(`${ROUTES_CONFIG.LeaseRoundTimeLineId.baseUrl}/${leaseRoundId}`);
  };

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <FormControl component="fieldset" sx={{ px: 2 }} fullWidth={true}>
          <Grid
            container={true}
            alignItems="center"
            spacing={2}
            fullWidth={true}
          >
            <Grid item={true}>
              <RadioGroup
                row={true}
                aria-labelledby="view-radio-buttons"
                name="view-radio-buttons"
                value={tab}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={ContractTabEnum.Equipment}
                  control={<Radio />}
                  label="Equipment contracts"
                />
                <FormControlLabel
                  value={ContractTabEnum.Vessel}
                  control={<Radio />}
                  label="Vessel contracts"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
      <ProjectDrawer
        id={selectedProjectId}
        onClose={() => dispatch(setSelectedProjectId(null))}
        onLeaseRoundClick={onLeaseRoundClick}
      />
      <FactoryDrawer
        id={selectedFactoryId}
        onClose={() => dispatch(setSelectedFactoryId(null))}
      />
      <TurbineModelDrawer
        id={selectedTurbineModelId}
        onClose={() => dispatch(setSelectedTurbineModelId(null))}
      />
      <FloatingTechnologyDrawer
        id={selectedFloatingTechnologyId}
        onClose={() => dispatch(setSelectedFloatingTechnologyId(null))}
      />
      <InstallationDrawer
        id={selectedInstallationId}
        onClose={() => dispatch(setSelectedInstallationId(null))}
      />
      {tab === ContractTabEnum.Equipment ? (
        <EquipmentContract />
      ) : (
        <VesselContract />
      )}
    </Box>
  );
};
