import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Divider, getEsgianTheme, Modal } from "@esgian/esgianui";
import moment from "moment";

import { api } from "../../../api/api";
import { keyToReadableString } from "../../../helper/translate";
import { useSelector } from "../../../hooks/use-selector";
import { getCountries, getThemeMode } from "../../../store/selector/common";
import { getImoMapping } from "../../../store/selector/installation";
import {
  GenericType,
  ROUTES_CONFIG,
  THEME,
  ThemeModeEnum,
  VesselInformation,
} from "../../../types";
import { Loading } from "../../Loading";
import DropDownList from "../DropDownList";
import Paragraph from "../Paragraph";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
  };
};

type Prop = {
  id?: number | null;
  onClose: () => void;
};

export const InstallationDrawer: FC<Prop> = ({ id, onClose }) => {
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);
  const countries = useSelector(getCountries);
  const imoMapping = useSelector(getImoMapping);
  const [data, setData] = useState<GenericType | null>(null);
  const [bassoeId, setBassoeId] = useState<number | null>(null);

  const fetchInstallationData = async () => {
    try {
      const today = moment();
      const { data: res }: { data: VesselInformation[] } =
        await api.wind.getVesselInformation({
          imos: [id],
        });
      const resp = res[0].vessel;
      const contracts = res[0]?.turbineContracts?.map((c) => ({
        title: `${c?.equipmentTypes?.[0]?.type} - ${c?.windProject?.name}`,
        awardDate: c?.awardDate,
        projectName: c?.windProject?.name,
        projectCountry:
          (c?.windProject?.countryId &&
            countries?.find(
              (country) => country.id === c?.windProject?.countryId,
            )?.name) ||
          "",
        equipmentType: c?.equipmentTypes?.[0]?.type,
        contractStartDate: c?.startDate,
        contractEndDate: c?.endDate,
        turbineModel: c?.windProject?.turbineModels?.[0]?.name,
        foundationType: c?.windProject?.foundationType?.[0]?.type,
        estimated: c?.contractStatus?.status,
      }));
      const currentBassoeId = imoMapping.find(
        (m: GenericType) => m.imo === resp?.imo,
      )?.bassoeId;
      const cranes = res[0]?.vessel?.cranes.map((c: GenericType) => ({
        title: c?.craneConfiguration?.craneDetails?.craneModel?.modelName,
        craneMaxCapacity: c?.craneConfiguration?.craneDetails?.capacityMt,
        craneType:
          c?.craneConfiguration?.craneDetails?.craneModel?.craneType?.type,
        craneModel: c?.craneConfiguration?.craneDetails?.craneModel?.modelName,
        hookHeightAboveDeck: c?.craneConfiguration?.craneDetails?.hookHeightM,
        shortBoomWeight: c?.craneConfiguration?.craneDetails?.shortBoomMassMt,
        shortBoomRadius: c?.craneConfiguration?.craneDetails?.shortBoomRadiusM,
        longBoomWeight: c?.craneConfiguration?.craneDetails?.longBoomMassMt,
        longBoomRadius: c?.craneConfiguration?.craneDetails?.longBoomRadiusM,
        boomLength: c?.craneConfiguration?.craneDetails?.boomLengthM,
        craneInstallationDate: c?.installationDate,
        craneRemovalDate: c?.removalDate,
      }));

      const currentCranes: GenericType[] = [];
      const historicCranes: GenericType[] = [];
      const futureCranes: GenericType[] = [];

      cranes.forEach((crane) => {
        const craneInstallationDate = moment(crane.craneInstallationDate);
        const craneRemovalDate = crane.craneRemovalDate
          ? moment(crane.craneRemovalDate)
          : null;

        if (
          today.isAfter(craneInstallationDate) &&
          (!craneRemovalDate || today.isBefore(craneRemovalDate))
        ) {
          currentCranes.push(crane);
        } else if (craneRemovalDate && today.isAfter(craneRemovalDate)) {
          historicCranes.push(crane);
        } else if (today.isBefore(craneInstallationDate)) {
          futureCranes.push(crane);
        }
      });

      let status = "";
      const dateOfDelivery = resp.dateOfDelivery;
      if (today.isBefore(moment(dateOfDelivery))) {
        status = "Under construction or ordered";
      } else if (today.isAfter(moment(dateOfDelivery))) {
        const { turbineContracts, cableContracts } = res[0];
        const allContracts = [...turbineContracts, ...cableContracts];
        let statusSet = false;

        allContracts.forEach((contract) => {
          if (
            today.isAfter(moment(contract.startDate)) &&
            today.isBefore(moment(contract.endDate))
          ) {
            status = keyToReadableString(
              `${contract?.equipmentTypes?.[0]?.type} ${contract?.workType?.type}`,
            );
            statusSet = true;
          }
        });

        if (!statusSet) {
          status = "";
        }
      } else {
        status = "";
      }
      setBassoeId(currentBassoeId);
      setData({
        id: resp.id,
        vesselName: resp.vesselName,
        owner: resp?.owners?.map((c) => c.companyName).join(", "),
        design: resp?.vesselDesign?.design,
        craneMake:
          resp?.cranes?.[0]?.craneConfiguration?.craneDetails?.craneModel
            ?.manufacturingCompanyName,
        craneMaxCapacity: resp?.cableLayingDetails?.capacityMt,
        maxLiftAboveDeck:
          resp?.cranes?.[0]?.craneConfiguration?.craneDetails?.hookHeightM,
        deckArea: resp?.vesselCapacityDetail?.deckAreaSqm,
        dateOfDelivery: resp?.dateOfDelivery,
        newbuild: moment(resp?.dateOfDelivery).isAfter(moment()) ? "Yes" : "No",
        vesselType: resp?.vesselType?.type,
        status,
        vesselCapability: resp?.vesselCapabilities
          ?.map((v) => v.capability)
          .join(", "),
        class: resp?.classification?.class,
        imo: resp?.imo,
        flag: resp?.flag?.name,
        constructedYard: resp?.shipyard?.name,
        mmsi: resp?.mmsi,
        loa: resp?.loa,
        futureCraneUpgrade: resp?.futureCraneUpgrade,
        width: resp?.mouldedBreadth,
        draught: resp?.draught,
        craneType: resp?.craneDetails
          ?.map((detail) => detail?.craneModel?.craneType?.type)
          .join(", "),
        craneModel: resp?.craneDetails
          ?.map((detail) => detail?.craneModel?.modelName)
          .join(", "),
        hookHeightAboveDeck: currentCranes.map((c) => c?.maxLiftAboveDeck),
        longBoomWeight: currentCranes.map((c) => c?.longBoomWeight),
        longBoomRadius: currentCranes.map((c) => c?.longBoomRadius),
        shortBoomWeight: currentCranes.map((c) => c?.shortBoomWeight),
        shortBoomRadius: currentCranes.map((c) => c?.shortBoomRadius),
        boomLength: currentCranes.map((c) => c?.boomLength),
        maxDeckStrength: resp?.vesselCapacityDetail?.deckStrengthMtSqm,
        maxDeckLoadCapacity: resp?.vesselCapacityDetail?.deckCapacityMt,
        maxSpeed: resp?.maximumSpeed,
        legLength: resp?.vesselJackupDetail?.jackingLegLengthM,
        elevatingJackingSpeed: resp?.vesselJackupDetail?.jackingSpeedMMin,
        preLoadCapacity: resp?.vesselJackupDetail?.jackingPreloadCapMt,
        maxJackingLoad: resp?.vesselJackupDetail?.jackingMaxLoadMt,
        jackingSystemMake:
          resp?.vesselJackupDetail?.jackupModel?.makerCompanyName,
        jackingSystemModel: resp?.vesselJackupDetail?.jackupModel?.model,
        maxPob: resp?.vesselCapacityDetail?.accommodation,
        minWaterDepth: resp?.vesselJackupDetail?.minWaterDepthM,
        maxWaterDepth: resp?.vesselJackupDetail?.maxWaterDepthM,
        contracts: contracts || [],
        currentCranes,
        historicCranes,
        futureCranes,
      });
    } catch (err) {
      console.log(">>>", err);
      onClose();
    }
  };

  useEffect(() => {
    setData(null);
    if (id) {
      fetchInstallationData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Modal
      sx={classes.modalContainer}
      open={!!id}
      size="sm"
      key={id}
      title={data?.vesselName || "Installation"}
      handleClose={onClose}
    >
      {data ? (
        <Box sx={classes.contentBox}>
          <Paragraph
            title="Key Info"
            properties={{
              vesselName: { key: "Vessel name" },
              owner: { key: "Owner" },
              design: { key: "Design" },
              maxLiftAboveDeck: { key: "Maximum lift above deck", unit: "m" },
              newbuild: { key: "Newbuild" },
              vesselCapability: { key: "Vessel capability" },
              imo: { key: "IMO" },
              mmsi: { key: "MMSI" },
              flag: { key: "Flag" },
              dateOfDelivery: { key: "Date of delivery", type: "date" },
              constructedYard: { key: "Constructed at yard" },
              class: { key: "Class" },
            }}
            data={data}
          />
          <Divider sx={classes.divider} />
          <Paragraph
            title="Vessel info"
            properties={{
              vesselType: { key: "Vessel type" },
              status: { key: "Status" },
              deckArea: { key: "Deck area", unit: "m", mathUnit: <sup>2</sup> },
              loa: { key: "LOA", unit: "m" },
              width: { key: "Width", unit: "m" },
              draught: { key: "Draught", unit: "m" },
              maxDeckStrength: {
                key: "Maximum deck strength",
                unit: "t/m",
                mathUnit: <sup>2</sup>,
              },
              maxDeckLoadCapacity: {
                key: "Maximum deck load capacity",
                unit: "t",
              },
              maxSpeed: {
                key: "Maximum speed",
                unit: "kn",
              },
              legLength: {
                key: "Leg length",
                unit: "m",
              },
              elevatingJackingSpeed: {
                key: "Elevating / jacking speed",
                unit: "m/s",
              },
              preLoadCapacity: {
                key: "Pre-load capacity",
                unit: "t",
              },
              maxJackingLoad: {
                key: "Maximum jacking load",
                unit: "t",
              },
              jackingSystemMake: {
                key: "Jacking system make",
              },
              jackingSystemModel: {
                key: "Jacking system model",
                unit: "t",
              },
              maxPob: {
                key: "Maxiumum POB",
              },
              minWaterDepth: {
                key: "Minimum water depth",
                unit: "m",
              },
              maxWaterDepth: {
                key: "Maximum water depth",
                unit: "m",
              },
            }}
            data={data}
          />
          <Divider sx={classes.divider} />
          <Button
            sx={{ cursor: "pointer" }}
            component={Link}
            to={`${ROUTES_CONFIG.ActivityVisualisation.baseUrl}/vessel/${bassoeId}`}
            variant="text"
          >
            Display activity visualisation
          </Button>
          {data?.currentCranes?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Crane capabilities"
                subTitleKey="title"
                properties={{
                  craneMaxCapacity: {
                    key: "Crane maximum capacity",
                    unit: "t",
                  },
                  craneType: { key: "Crane type" },
                  craneModel: { key: "Crane model" },
                  hookHeightAboveDeck: {
                    key: "Hook height above deck",
                    unit: "m",
                  },
                  shortBoomWeight: { key: "Short boom weight", unit: "t" },
                  shortBoomRadius: { key: "Short boom radius", unit: "m" },
                  longBoomWeight: { key: "Long boom weight", unit: "t" },
                  longBoomRadius: { key: "Long boom radius", unit: "m" },
                  boomLength: { key: "Boom length", unit: "m" },
                  craneInstallationDate: {
                    key: "Crane installation date",
                    type: "date",
                  },
                  craneRemovalDate: { key: "Crane removal date", type: "date" },
                }}
                data={data?.currentCranes}
              />
            </>
          ) : null}
          {data?.futureCranes?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Future crane capabilities"
                subTitleKey="title"
                properties={{
                  craneMaxCapacity: {
                    key: "Crane maximum capacity",
                    unit: "t",
                  },
                  craneType: { key: "Crane type" },
                  craneModel: { key: "Crane model" },
                  hookHeightAboveDeck: {
                    key: "Hook height above deck",
                    unit: "m",
                  },
                  shortBoomWeight: { key: "Short boom weight", unit: "t" },
                  shortBoomRadius: { key: "Short boom radius", unit: "m" },
                  longBoomWeight: { key: "Long boom weight", unit: "t" },
                  longBoomRadius: { key: "Long boom radius", unit: "m" },
                  boomLength: { key: "Boom length", unit: "m" },
                  craneInstallationDate: {
                    key: "Crane installation date",
                    type: "date",
                  },
                  craneRemovalDate: { key: "Crane removal date", type: "date" },
                }}
                data={data?.futureCranes}
              />
            </>
          ) : null}
          {data?.historicCranes?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Historical crane capabilities"
                subTitleKey="title"
                properties={{
                  craneMaxCapacity: {
                    key: "Crane maximum capacity",
                    unit: "t",
                  },
                  craneType: { key: "Crane type" },
                  craneModel: { key: "Crane model" },
                  hookHeightAboveDeck: {
                    key: "Hook height above deck",
                    unit: "m",
                  },
                  shortBoomWeight: { key: "Short boom weight", unit: "t" },
                  shortBoomRadius: { key: "Short boom radius", unit: "m" },
                  longBoomWeight: { key: "Long boom weight", unit: "t" },
                  longBoomRadius: { key: "Long boom radius", unit: "m" },
                  boomLength: { key: "Boom length", unit: "m" },
                  craneInstallationDate: {
                    key: "Crane installation date",
                    type: "date",
                  },
                  craneRemovalDate: { key: "Crane removal date", type: "date" },
                }}
                data={data?.historicCranes}
              />
            </>
          ) : null}

          {data?.contracts?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Contracts"
                subTitleKey="title"
                name="contract"
                data={data.contracts}
                defaultExpand={true}
                properties={{
                  awardDate: { key: "Award date", type: "date" },
                  projectName: { key: "Project" },
                  projectCountry: { key: "Project country" },
                  equipmentType: { key: "Equipment type" },
                  contractStartDate: {
                    key: "Contract start date",
                    type: "date",
                  },
                  contractEndDate: { key: "Contract end date", type: "date" },
                  turbineModel: { key: "Turbine model" },
                  foundationType: { key: "Foundation type" },
                  estimated: { key: "Estimated" },
                }}
              />
            </>
          ) : null}
        </Box>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
