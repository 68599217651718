import { useEffect, useMemo } from "react";
import { Box, Table, Typography } from "@esgian/esgianui";

import {
  calculateFilterCount,
  filterDataBasedOnDynamicKeys,
} from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getRowsPerPage,
  getSearchText,
  getThemeMode,
} from "../../store/selector/common";
import {
  getSelectedFilter,
  getSortingInfo,
  getTurbineModelList,
} from "../../store/selector/turbineModels";
import {
  setRowsPerPage,
  setSelectedTurbineModelId,
} from "../../store/slice/common";
import {
  fetchTurbineModelList,
  setSortingInfo,
} from "../../store/slice/turbineModels";
import { GenericType, IdNamePair, TurbineModel } from "../../types";
import { FilterBtn } from "../FilterBtn";
import { Loading } from "../Loading";

export const TurbineModelsList = () => {
  const dispatch = useDispatch();
  const theme = useSelector(getThemeMode);
  const searchText = useSelector(getSearchText);
  const rowsPerPage = useSelector(getRowsPerPage);
  const turbineModeList = useSelector(getTurbineModelList);
  const selectedFilter = useSelector(getSelectedFilter);
  const sortingInfo = useSelector(getSortingInfo);

  const COLUMNS = [
    {
      label: "Turbine model",
      name: "turbineModel",
      options: {
        setCellProps: () => ({ style: { cursor: "pointer" } }),
        customBodyRender: (value: IdNamePair) => value || "N/A",
      },
    },
    {
      label: "Turbine supplier",
      name: "suppliers",
    },
    { label: "Turbine nominal power [MW]", name: "turbineNominalPower" },
    { label: "Rotor diameter [m]", name: "rotorDiameter" },
    { label: "Swept area [m2]", name: "sweptArea" },
    { label: "Number of blades", name: "numberOfBlades" },
    { label: "Cut in speed [m/s]", name: "cutInSpeed" },
    { label: "Cut out speed [m/s]", name: "cutOutSpeed" },
    { label: "Rated wind speed [m/s]", name: "ratedWindSpeed" },
    { label: "Generator system", name: "generatorSystem" },
  ];

  const data = useMemo(() => {
    if (!turbineModeList || !turbineModeList?.length) return turbineModeList;
    let list = filterDataBasedOnDynamicKeys(
      turbineModeList,
      selectedFilter,
    ) as TurbineModel[];

    if (searchText?.length && list?.length) {
      list = list?.filter((item: TurbineModel) => isMatch(item, [searchText]));
    }
    return list;
  }, [searchText, turbineModeList, selectedFilter]);

  useEffect(() => {
    dispatch(fetchTurbineModelList());
  }, [dispatch]);

  return (
    <Box sx={{ px: 2 }}>
      {data ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ py: 2 }}>
              {`Displaying ${data?.length} Turbine Models`}
            </Typography>
            <FilterBtn count={calculateFilterCount(selectedFilter)} />
          </Box>
          <Table
            app="wind"
            columnDefs={[]}
            columns={COLUMNS}
            data={data}
            elevation={0}
            mode={theme}
            options={{
              download: true,
              search: false,
              downloadOptions: {
                filename: `turbine_models_data.csv`,
              },
              expandableRows: false,
              filter: false,
              hideToolbarMargin: false,
              pagination: true,
              responsive: "standard",
              rowsPerPage,
              onChangeRowsPerPage: (numberOfRows: number) => {
                dispatch(setRowsPerPage(numberOfRows));
              },
              onRowClick: (_: number, rowMeta: GenericType) => {
                const { dataIndex }: GenericType = rowMeta;
                const item: GenericType = data[dataIndex];
                dispatch(setSelectedTurbineModelId(item?.id || null));
              },
              rowsPerPageOptions: [25, 50, 100],
              selectableRows: "none",
              sortOrder: sortingInfo,
              onColumnSortChange: (name: string, direction: string) => {
                dispatch(setSortingInfo({ name, direction }));
              },
              onViewColumnsChange: () => {},
              showResponsive: true,
              tableBodyHeight: "calc(100vh - 290px)",
              toolbar: true,
              print: false,
              viewColumns: true,
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
