import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { api } from "../../api/api";
import {
  aggregateMinMaxValues,
  generateOptions,
  transformNumericAttributesForFilter,
} from "../../helper/fllter";
import { GenericType, SortingInfo, TurbineModel } from "../../types";

type State = {
  isLoading: boolean;
  turbineModelList: TurbineModel[] | null;
  filterOptions: GenericType | null;
  selectedFilter: GenericType;
  sortingInfo: SortingInfo;
};

const initialState: State = {
  isLoading: false,
  turbineModelList: null,
  filterOptions: null,
  selectedFilter: {},
  sortingInfo: {
    direction: "asc",
    name: "turbineModel",
  },
};

export const fetchTurbineModelList = createAsyncThunk(
  "turbineModel/list",
  async () => {
    const { data } = await api.turbine.getTurbines({});
    const tableData = data.map((item: GenericType) => ({
      ...item.turbineModel,
      turbineModel: item.turbineModel.name,
      suppliers: item.turbineModel?.suppliers?.map(
        (s: GenericType) => s.companyName,
      ),
      generatorSystem: item?.turbineModel?.generatorSystem?.system,
    }));
    const attributesList = [
      "turbineNominalPower",
      "rotorDiameter",
      "sweptArea",
      "numberOfBlades",
      "cutInSpeed",
      "cutOutSpeed",
      "ratedWindSpeed",
    ];

    const attributes = aggregateMinMaxValues(tableData, attributesList);
    return {
      data: tableData.map((item: GenericType) => ({
        ...item,
        suppliers: item.suppliers.join(", "),
      })),
      filterOptions: {
        ...generateOptions(tableData, [
          "suppliers",
          "turbineModel",
          "generatorSystem",
        ]),
        ...transformNumericAttributesForFilter(attributes),
      },
      selectedFilter: {},
    };
  },
);

const turbineModelsSlice = createSlice({
  name: "turbineModels",
  initialState,
  reducers: {
    setSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      state.selectedFilter = action.payload;
    },
    setSortingInfo: (state, action: PayloadAction<SortingInfo>) => {
      state.sortingInfo = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTurbineModelList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchTurbineModelList.rejected, (state) => {
        state.isLoading = false;
        state.turbineModelList = null;
      })
      .addCase(fetchTurbineModelList.fulfilled, (state, action) => {
        state.turbineModelList = action.payload.data;
        state.filterOptions = action.payload.filterOptions;
        state.selectedFilter = action.payload.selectedFilter;
      });
  },
});

export const { setSelectedFilter, setSortingInfo } = turbineModelsSlice.actions;
export const turbineModels = turbineModelsSlice.reducer;
