import { getEsgianTheme } from "@esgian/esgianui";
import {
  ROUTES_CONFIG,
  SelectedFilter,
  THEME,
  ThemeModeEnum,
} from "../../../types";

export const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);

  return {
    filterButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "32px",
      width: "32px",
      borderRadius: "50%",
      border: "none",
      marginTop: "16px",
      overflow: "visible",
      position: "relative",
      background: theme.palette.common.white,
    },
    map: {
      overflow: "hidden",
      height: "100%",
      width: "100%",
      "& .filter-count": {
        position: "absolute",
        background: "#80DFEB",
        height: "12px",
        width: "12px",
        fontSize: 10,
        top: -5,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        color: "black",
      },
      "& .marker-popup": {
        cursor: "pointer",
        color: theme.palette.common.black,
      },
    },
  };
};
