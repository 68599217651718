import { GenericType } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = GenericType[];

type ResponseData = { data: GenericType[] };

export const getPowerOutput: ApiMethodDeclaration<ParamsData, ResponseData> = (
  data,
) => ({
  url: `/PowerGeneration/ByFilters?api-version=1.0`,
  method: "POST",
  data,
});
