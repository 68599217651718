import { Factory, InformationRequestBody } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = InformationRequestBody;

type ResponseData = { data: Factory[] };

export const getFactoryInfo: ApiMethodDeclaration<ParamsData, ResponseData> = (
  data,
) => ({
  url: `/Factory/Information?api-version=1.0`,
  method: "POST",
  data,
});
