import { InformationRequestBody, Lookup } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = InformationRequestBody;

type ResponseData = { data: Lookup[] };

export const getCountryListInfo: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/Wind/ListCountryInformation?api-version=1.0`,
  method: "POST",
  data,
});
