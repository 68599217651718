import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
const { REACT_APP_ACTIVITY_URL } = process.env;

import { api } from "../../api/api";
import { getAuthToken } from "../../helper/cookies";
import { GenericType } from "../../types";

type State = {
  isLoading: boolean;
  activityList: GenericType[] | null;
  chartData: GenericType[];
  title: string;
};

const initialState: State = {
  isLoading: true,
  activityList: null,
  chartData: [],
  title: "",
};

export const fetchActivityList = createAsyncThunk(
  "fetchActivityList",
  async (body: GenericType) => {
    try {
      const token = getAuthToken();
      const { data: resp } = await axios.post(
        `${REACT_APP_ACTIVITY_URL}/wind-vessels/wind-vessel-chart/`,
        body,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        },
      );
      let title = "";
      if (body.relatedWindFarmId) {
        const { data: project } = await api.project.getProject(
          body.relatedWindFarmId,
        );
        title = project.projectName;
      }
      const chartData = resp.data.chartData;
      return { chartData, title };
    } catch (err) {
      return { chartData: [], title: "" };
    }
  },
);

const activityVisualisationSlice = createSlice({
  name: "activityVisualisation",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchActivityList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchActivityList.rejected, (state) => {
        state.isLoading = false;
        state.activityList = null;
      })
      .addCase(fetchActivityList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chartData = action.payload.chartData;
        state.title = action.payload.title;
      });
  },
});

// export const { setSelectedFilter } = activityVisualisationSlice.actions;
export const activityVisualisation = activityVisualisationSlice.reducer;
