import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { api } from "../../api/api";
import { findContractStatus } from "../../helper/chart";
import {
  Country,
  GenericType,
  RootState,
  SortingInfo,
  VesselAvailability,
  VesselContractChart,
  WindVesselAvailability,
} from "../../types";

type State = {
  isLoading: boolean;
  vesselAvailabilityList: VesselAvailability[] | null;
  filterOptions: GenericType | null;
  selectedFilter: GenericType;
  vesselChartData: VesselContractChart[] | null;
  sortingInfo: SortingInfo;
};

const initialState: State = {
  isLoading: false,
  vesselAvailabilityList: null,
  filterOptions: null,
  selectedFilter: {},
  vesselChartData: null,
  sortingInfo: {
    direction: "asc",
    name: "vesselName",
  },
};

export const fetchVesselAvailabilityList = createAsyncThunk(
  "vesselAvailability/list",
  async () => {
    const { data } = await api.vesselAvailability.getVesselAvailability();
    const tableData = data.map((item: WindVesselAvailability) => ({
      vesselId: item?.vessel?.id,
      vesselName: item?.vessel?.vesselName,
      vesselType: item?.vesselType?.type,
      owner: item?.owners?.map((o) => o.companyName).join(", "),
      draught: item?.vessel?.draught,
      maxWaterDepth: item?.vesselJackupDetail?.maxWaterDepthM,
      maxHookHeight:
        item?.cranes?.[0]?.craneConfiguration?.craneDetails?.hookHeightM,
      shortBoomMass:
        item?.cranes?.[0]?.craneConfiguration?.craneDetails?.shortBoomMassMt,
      vesselCapability: item?.capabilities?.map((c) => c.capability).join(", "),
    }));
    return tableData;
  },
);

export const fetchVesselContractChart = createAsyncThunk(
  "vesselContract/chart",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const countries = state.common.countries;
    const { data } = await api.vesselAvailability.getVesselAvailability();
    const list = data
      .map((item: WindVesselAvailability) => ({
        id: item?.vessel?.imo,
        name: item?.vessel?.vesselName,
        contract: item?.contracts?.map((c) => {
          const status = findContractStatus(c);
          return {
            windFarm: c?.windProject?.name,
            countryId: countries.find(
              (country: Country) => country.id === c.windProject.countryId,
            )?.name,
            startDate: c?.contract?.startDate,
            endDate: c?.contract?.endDate,
            contractAwardedDate: c?.contract?.awardDate,
            turbineModel: c?.windProject?.turbineModels
              .map((t) => t.name)
              .join(","),
            foundationType: c?.windProject?.foundationType
              .map((t) => t.type)
              .join(","),
            status,
          };
        }),
      }))
      .sort((a: VesselContractChart, b: VesselContractChart) =>
        a.name.localeCompare(b.name),
      );
    return list as VesselContractChart[];
  },
);

const vesselAvailabilitySlice = createSlice({
  name: "vesselAvailability",
  initialState,
  reducers: {
    setSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      state.selectedFilter = action.payload;
    },
    setSortingInfo: (state, action: PayloadAction<SortingInfo>) => {
      state.sortingInfo = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVesselAvailabilityList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchVesselAvailabilityList.rejected, (state) => {
        state.isLoading = false;
        state.vesselAvailabilityList = null;
      })
      .addCase(fetchVesselAvailabilityList.fulfilled, (state, action) => {
        state.vesselAvailabilityList = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(fetchVesselContractChart.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchVesselContractChart.rejected, (state) => {
        state.isLoading = false;
        state.vesselChartData = null;
      })
      .addCase(fetchVesselContractChart.fulfilled, (state, action) => {
        state.vesselChartData = action.payload;
        state.isLoading = false;
      });
  },
});

export const { setSelectedFilter, setSortingInfo } =
  vesselAvailabilitySlice.actions;
export const vesselAvailability = vesselAvailabilitySlice.reducer;
