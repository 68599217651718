import { useEffect } from "react";
import { Box } from "@esgian/esgianui";

import { CableLayList } from "../../components/CableLayList";
import { CableLayDrawer } from "../../components/Drawer/CableLayDrawer";
import { FilterModal } from "../../components/Modal/FilterModal";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getCableLayFilterOptions } from "../../store/selector/cableLay";
import {
  getFilterModal,
  getSelectedCableLayId,
  getVesselType,
} from "../../store/selector/common";
import {
  fetchCableLayList,
  setSelectedFilter,
} from "../../store/slice/cableLay";
import {
  fetchLookup,
  setFilterModal,
  setSearchObjectCoordinates,
  setSearchText,
  setSelectedCableLayId,
} from "../../store/slice/common";

export const CableLay = () => {
  const dispatch = useDispatch();
  const isFilterModalOpen = useSelector(getFilterModal);
  const options = useSelector(getCableLayFilterOptions);
  const vesselType = useSelector(getVesselType);
  const selectedCableLayId = useSelector(getSelectedCableLayId);

  useEffect(() => {
    dispatch(fetchLookup(["vesselType"]));
  }, [dispatch]);

  useEffect(() => {
    if (vesselType?.length) {
      dispatch(fetchCableLayList());
    }
  }, [dispatch, vesselType]);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <CableLayDrawer
        id={selectedCableLayId}
        onClose={() => dispatch(setSelectedCableLayId(null))}
      />
      {options ? (
        <>
          <FilterModal
            open={isFilterModalOpen}
            handleClose={() => dispatch(setFilterModal(false))}
            onChange={(filterValues) =>
              dispatch(setSelectedFilter(filterValues))
            }
            groupsOption={options}
          />
        </>
      ) : null}
      <CableLayList />
    </Box>
  );
};
