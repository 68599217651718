import { RootState } from "../../types";

export const getCableLayList = (state: RootState) => {
  return state.cableLay.cableLayList;
};

export const getSelectedFilter = (state: RootState) => {
  return state.cableLay.selectedFilter;
};

export const getCableLayFilterOptions = (state: RootState) => {
  return state.cableLay.filterOptions;
};

export const getIsLoading = (state: RootState) => {
  return state.cableLay.isLoading;
};

export const getSortingInfo = (state: RootState) => {
  return state.cableLay.sortingInfo;
};

export const getVisibleColumns = (state: RootState) => {
  return state.cableLay.visibleColumns;
};
