import { sortValues } from "../../helper/fllter";
import { FilterItemType, RootState } from "../../types";

export const getLeaseRoundTimelineEvents = (state: RootState) => {
  return state.leaseRoundTimeline.events;
};

export const getLeaseRoundTimelineResource = (state: RootState) => {
  return state.leaseRoundTimeline.resource;
};

export const getLeaseRoundTimelineStartAndEndDate = (state: RootState) => {
  return {
    startDate: state.leaseRoundTimeline.startDate,
    endDate: state.leaseRoundTimeline.endDate,
  };
};

export const getLeaseRoundIsLoading = (state: RootState) => {
  return state.leaseRoundTimeline.isLoading;
};

export const getLeaseRoundTotalCount = (state: RootState) => {
  return state.leaseRoundTimeline.totalLeaseRoundCount;
};

export const getSelectedFilter = (state: RootState) => {
  return state.leaseRoundTimeline.selectedFilter;
};

export const getFilterOptions = (state: RootState) => {
  const countries = sortValues(
    state.common.countries?.map((item) => ({
      id: item.id,
      value: item.name,
      type: "country",
    })),
  );
  const landArea = sortValues(
    state.common.landArea?.map((item) => ({
      id: item.regionId,
      value: item.regionName,
      type: "other region types",
    })),
  );
  const seaArea = sortValues(
    state.common.seaArea.map((item) => ({
      id: item.regionId,
      value: item.regionName,
      type: "sea region",
    })),
  );

  return {
    location: {
      type: FilterItemType.Autocomplete,
      inputLabelText: "Location",
      labelKey: "value",
      groupBy: "type",
      options: [...countries, ...landArea, ...seaArea],
    },
    leaseRoundStatus: {
      type: FilterItemType.MultiSelect,
      labelKey: "value",
      inputLabelText: "Lease round status",
      options: sortValues(
        state.common.leaseRoundStatus?.map((item) => ({
          id: item.id,
          value: item.status,
        })),
      ),
    },
  };
};
