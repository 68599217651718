import { GenericType } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = GenericType[];

type ResponseData = undefined;

export const getPowerOutputLookup: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = () => ({
  url: `/Lookup/PowerGeneration?api-version=1.0`,
  method: "Get",
});
