import { sortValues } from "../../helper/fllter";
import { FilterItemType, GenericType, RootState } from "../../types";

export const getCountryOption = (state: RootState) => {
  return state.common.countries?.map((item) => ({
    id: item.id,
    value: item.name,
    type: "country",
  }));
};

export const getSelectedFilter = (state: RootState) => {
  return state.analytic.selectedFilter;
};

export const getAnalytics = (state: RootState) => {
  return state.analytic.analyticList;
};

export const getFilterOptions = (state: RootState) => {
  return {
    projects: {
      type: FilterItemType.MultiSelect,
      labelKey: "value",
      inputLabelText: "Project",
      options: sortValues(
        state.common.projects
          ?.map((item) => ({
            id: item.windProjectId,
            value: item.name,
          }))
          .filter((item) => item.value && item.id),
      ),
    },
    company: {
      type: FilterItemType.MultiSelect,
      labelKey: "value",
      inputLabelText: "Company",
      options: sortValues(
        state.common.company
          ?.filter((c: GenericType) => c.windProjects.length)
          ?.map((item) => ({
            id: item.company.id,
            value: item.company.companyName,
          })),
      ),
    },
    projectStatus: {
      type: FilterItemType.MultiSelect,
      labelKey: "value",
      inputLabelText: "Project Status",
      options: sortValues(
        state.common.projectStatus?.map((item) => ({
          id: item.id,
          value: item.status,
        })),
      ),
    },
    projectType: {
      type: FilterItemType.MultiSelect,
      labelKey: "value",
      inputLabelText: "Project Type",
      options: sortValues(
        state.common.projectType?.map((item) => ({
          id: item.id,
          value: item.type,
        })),
      ),
    },
    date: {
      type: FilterItemType.Date,
      inputLabelText: "Date",
      format: "YYYY MM",
      labelKey: "value",
    },
  };
};
