import { Lookup } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = { ids: number[] };

type ResponseData = { data: Lookup[] };

export const getRegions: ApiMethodDeclaration<ParamsData, ResponseData> = (
  RegionTypeIds,
) => ({
  url: `Regions?api-version=1.0`,
  method: "POST",
  data: { RegionTypeIds },
});
