/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Button, Divider, getEsgianTheme, Modal } from "@esgian/esgianui";

import { api } from "../../../api/api";
import { ReactComponent as FullscreenIcon } from "../../../assets/fullscreen.svg";
import { getErrorMessage } from "../../../helper/errorHandling";
import { useSelector } from "../../../hooks/use-selector";
import { getCountries, getThemeMode } from "../../../store/selector/common";
import {
  CoordinatesObj,
  Country,
  IdNamePair,
  MESSAGE,
  THEME,
  ThemeModeEnum,
  TimelineLeaseDrawer,
} from "../../../types";
import { Loading } from "../../Loading";
import { WindLeasingAndProjectMap } from "../../WindLeasingAndProjectMap";
import Paragraph from "../Paragraph";

const useStyles = (themeMode: string, fullscreen: boolean) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: fullscreen ? "100vw" : "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
        maxWidth: fullscreen ? "100vw" : 599,
        overflow: fullscreen ? "hidden" : "auto",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
      "& .MuiDialogTitle-root": {
        width: "94%",
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
    mapContainer: {
      height: fullscreen ? "100vh" : 336,
      width: "100%",
      position: fullscreen ? "absolute" : "relative",
      mt: 4,
      top: fullscreen && "-30px",
      right: fullscreen && 0,
    },
    fullscreenBtn: {
      background: "#fff",
      color: "black",
      position: "absolute",
      bottom: fullscreen ? "auto" : 0,
      right: fullscreen ? 16 : 8,
      top: fullscreen ? 16 : "auto",
      zIndex: 2,
    },
  };
};

type Prop = {
  id?: number | null;
  type: "round" | "area";
  onClose: () => void;
};

export const LeaseWithMapDrawer: FC<Prop> = ({ id, type, onClose }) => {
  const themeMode = useSelector(getThemeMode);

  const countryList = useSelector(getCountries);

  const [data, setData] = useState<TimelineLeaseDrawer | null>(null);
  const [center, setCenter] = useState<CoordinatesObj | null>(null);
  const [fullscreen, setFullscreen] = useState(false);
  const classes = useStyles(themeMode, fullscreen);

  const fetchLeaseRoundData = async () => {
    try {
      let res: TimelineLeaseDrawer;
      if (type === "area") {
        const { data: leasRegionRes } = await api.lease.getLeaseRegion(id);
        leasRegionRes.basicInfo = {
          name: leasRegionRes?.leaseRegion?.leaseRegion?.[0]?.name,
          country:
            leasRegionRes?.leaseRegion?.countryId &&
            countryList?.find(
              (item: Country) =>
                item.id === leasRegionRes.leaseRegion.countryId,
            )?.name,
          seaRegion: leasRegionRes.leaseRegion.seaRegion
            .map((item: IdNamePair) => item.name)
            .join(", "),
        };
        //TO DO WHEN api HAS RESPONSE FOR THIS
        leasRegionRes.areaDetails = {};
        leasRegionRes.centroidCoordinates =
          leasRegionRes?.leaseRegion?.leaseRegion?.[0]?.centroidCoordinates;

        res = leasRegionRes;
      } else {
        const { data: leasRoundRes } = await api.lease.getLeaseRound(id);
        leasRoundRes.basicInfo = {
          name: leasRoundRes?.leaseRoundName,
          country:
            leasRoundRes.countryId &&
            countryList?.find(
              (item: Country) => item.id === leasRoundRes.countryId,
            )?.name,
          seaRegion: leasRoundRes?.seaRegion
            ?.map((item: IdNamePair) => item.name)
            .join(", "),
        };
        res = leasRoundRes;
      }
      setData(res);
      if (res?.centroidCoordinates?.lat && res?.centroidCoordinates?.lon) {
        setCenter(res.centroidCoordinates);
      }
    } catch (err) {
      toast.error(`${MESSAGE.LeaseRound}: ${getErrorMessage(err)}`);
    }
  };

  const handleClose = () => {
    return onClose();
  };

  useEffect(() => {
    setData(null);
    if (id) {
      fetchLeaseRoundData();
    }
  }, [id]);

  const handleFullScreen = () => {
    setFullscreen(!fullscreen);
  };

  return (
    <Modal
      sx={classes.modalContainer}
      open={!!id}
      size="sm"
      key={id}
      title={data?.basicInfo?.name ? data.basicInfo.name : `Lease ${type}`}
      handleClose={handleClose}
    >
      {data ? (
        <Box sx={classes.contentBox}>
          <Paragraph
            title="Basic Info"
            properties={{
              name: { key: `Lease ${type} name` },
              ...(type === "area" ? { country: { key: "Country" } } : {}),
              // state: { key: "State" },
              // seaRegion: { key: "Sea Region" },
            }}
            data={data.basicInfo}
          />

          {type === "area" ? (
            <>
              <Divider sx={classes.divider} />
              <Paragraph
                title="Area details"
                properties={{
                  estimatedCapacityMW: {
                    key: "Estimated capacity",
                    unit: "MW",
                  },
                  size: { key: "Size", unit: "sq. km" },
                  maxDepth: { key: "Max. depth", unit: "m" },
                  distanceFromOffshore: {
                    key: "Distance from shore",
                    unit: "nm",
                  },
                }}
                data={data.areaDetails}
              />
            </>
          ) : null}
          <Box sx={classes.mapContainer}>
            {center ? (
              <>
                <WindLeasingAndProjectMap
                  key={fullscreen ? "fullscreen" : "default"}
                  controller={fullscreen ? true : false}
                  zoom={4.5}
                  move={false}
                  center={center}
                  markerInfo={
                    type === "area"
                      ? { name: data.basicInfo.name, center }
                      : undefined
                  }
                />
                <Button
                  color="inherit"
                  sx={classes.fullscreenBtn}
                  startIcon={<FullscreenIcon />}
                  onClick={handleFullScreen}
                >
                  {fullscreen ? "Fullscreen exit" : "Fullscreen"}
                </Button>
              </>
            ) : null}
          </Box>
        </Box>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
