import { Map } from "mapbox-gl";

import { MapLayer, MapSource, Target } from "../../../types";

export class Circle {
  init(map: Map) {
    map.addSource(MapSource.CircleSource, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      cluster: false,
    });

    map.addLayer({
      id: MapLayer.Circle,
      type: "circle",
      source: MapSource.CircleSource,
      paint: {
        "circle-radius": [
          "step",
          ["get", "capacity"],
          20,
          20,
          25,
          40,
          30,
          60,
          35,
          80,
          40,
        ],
        "circle-color": [
          "match",
          ["get", "type"],
          "Total",
          "#C4A9F9",
          "Floating foundation",
          "#9DF9FF",
          "Fixed foundation",
          "#FFFFFF",
          "#FFFFFF",
        ],
        "circle-stroke-width": 4,
        "circle-stroke-color": [
          "case",
          ["==", ["get", "isSelectedDate"], true],
          "#0000FF",
          "#75757e",
        ],
      },
    });

    map.addLayer({
      id: MapLayer.CircleCount,
      type: "symbol",
      source: MapSource.CircleSource,
      layout: {
        "text-field": ["concat", ["to-string", ["get", "capacity"]], "GW"],
        "text-size": 12,
      },
      paint: {
        "text-color": "#000000",
      },
    });
  }

  update(map: Map, data: Target[], selectedDate: number | null) {
    const features: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: "FeatureCollection",
      features: data.map((item: Target) => {
        const coordinates = [
          item.centroidCoordinates.lon,
          item.centroidCoordinates.lat,
        ];
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates,
          },
          properties: {
            ...item,
            isSelectedDate: Number(item.year) === selectedDate,
          },
        };
      }),
    };
    const source = map.getSource(
      MapSource.CircleSource,
    ) as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData(features);
    }
  }

  disable(map: Map) {
    map.setLayoutProperty(MapLayer.Circle, "visibility", "none");
    map.setLayoutProperty(MapLayer.CircleCount, "visibility", "none");
  }

  visible(map: Map) {
    map.setLayoutProperty(MapLayer.Circle, "visibility", "visible");
    map.setLayoutProperty(MapLayer.CircleCount, "visibility", "visible");
  }

  remove(map: Map) {
    if (map.getSource(MapSource.CircleSource)) {
      map.removeLayer(MapLayer.CircleCount);
      map.removeLayer(MapLayer.Circle);
      map.removeSource(MapSource.CircleSource);
    }
  }
}
