import { InformationRequestBody, Option } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = InformationRequestBody;

type ResponseData = { data: Option[] };

export const getFactoryFunctions: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/FactoryFunctions?api-version=1.0`,
  method: "POST",
  data,
});
