import { FC } from "react";
import { Box, Button, getEsgianTheme, Typography } from "@esgian/esgianui";

import { ReactComponent as FilterIcon } from "../../assets/filter.svg";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import { setFilterModal } from "../../store/slice/common";
import { THEME, ThemeModeEnum } from "../../types";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    filterBox: {
      position: "relative",
    },
    filterBtn: {
      fontSize: 14,
      py: "3px",
      px: "16px",
      "& path": {
        fill:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.white
            : theme.palette.common.black,
      },
      maxHeight: 36,
    },
    filterCount: {
      position: "absolute",
      background: "#80DFEB",
      height: "12px",
      width: "12px",
      fontSize: 10,
      top: -5,
      right: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      color: "black",
    },
  };
};

type Prop = {
  count?: number | null;
};

export const FilterBtn: FC<Prop> = ({ count }) => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);
  return (
    <Box sx={classes.filterBox}>
      <Button
        variant="outlined"
        color="inherit"
        sx={classes.filterBtn}
        startIcon={<FilterIcon />}
        onClick={() => dispatch(setFilterModal(true))}
      >
        Filter
      </Button>
      {count ? (
        <Typography variant="string" sx={classes.filterCount}>
          {count}
        </Typography>
      ) : null}
    </Box>
  );
};
