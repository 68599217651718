import { FC, useEffect, useMemo, useState } from "react";
import {
  Box,
  DeleteOutlineIcon,
  getEsgianTheme,
  IconButton,
  Table,
  Typography,
} from "@esgian/esgianui";

import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import {
  getList,
  getSelectedItem,
  getTab,
} from "../../store/selector/powerOutput";
import { setSelectedItem } from "../../store/slice/powerOutput";
import { GenericType, THEME, ThemeModeEnum } from "../../types";
import { StyledAutocomplete } from "../StyledAutocomplete";

type Prop = {
  sx?: GenericType;
};

export const PowerOutputCompare: FC<Prop> = ({ sx }) => {
  const dispatch = useDispatch();

  const tab = useSelector(getTab);
  const themeMode = useSelector(getThemeMode);
  const itemsObject = useSelector(getList);
  const theme = getEsgianTheme(themeMode, THEME);
  const selectedItem = useSelector(getSelectedItem);
  const [tableData, setTableData] = useState<GenericType[]>([{ name: null }]);

  const handleRemoveProject = (index: number) => {
    dispatch(setSelectedItem(selectedItem[index]));
  };

  const list = useMemo(() => {
    return itemsObject?.[tab] || [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (selectedItem.length < 7 && list.length) {
      const entities: GenericType[] = selectedItem
        .map((item: number) => {
          return list.find((i: GenericType) => i.id == Number(item));
        })
        .filter((entity): entity is GenericType => entity !== undefined);
      if (selectedItem.length < 6) {
        entities.push({ name: null });
      }
      setTableData(entities);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, list]);

  const COLUMNS = useMemo(() => {
    return [
      {
        label: "Name",
        name: "name",
        options: {
          filter: true,
          sort: true,
          setCellProps: () => ({
            style: {
              minWidth: 250,
              color:
                themeMode === ThemeModeEnum.Dark
                  ? theme.palette.common.white
                  : theme.palette.common.black,
            },
          }),
          customBodyRender: (
            value: string,
            tableMeta: { rowIndex: number | undefined },
            updateValue: (arg0: string) => void,
          ) => {
            if (!value) {
              const availableList = list?.filter(
                (project) => !selectedItem?.includes(project.id),
              );

              return (
                <StyledAutocomplete
                  disableClearable={true}
                  disableCloseOnSelect={false}
                  options={availableList || []}
                  setSelectedItem={(v: GenericType) => {
                    if (selectedItem.length < 6) {
                      dispatch(setSelectedItem(v.id));
                      updateValue(v.name);
                    }
                  }}
                  labelKey="name"
                  multipleSelection={false}
                  size="medium"
                  placeholder="Add Compare Item"
                  maxHeight="30vh"
                />
              );
            }
            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="string"> {value}</Typography>
                {tableMeta.rowIndex !== 0 ? (
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleRemoveProject(tableMeta.rowIndex || 0)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                ) : null}
              </Box>
            );
          },
        },
      },
      {
        label: "1-day",
        name: "dayCapacityFactor",
      },
      {
        label: "1-week",
        name: "weekCapacityFactor",
      },
      {
        label: "1 month",
        name: "monthCapacityFactor",
      },
      {
        label: "YID",
        name: "yidCapacityFactor",
      },
      {
        label: "1-year",
        name: "yearCapacityFactor",
      },
      {
        label: "3-year",
        name: "yearCapacityFactor",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, selectedItem]);

  return (
    <Box
      sx={{
        background:
          themeMode === ThemeModeEnum.Dark ? "#171717" : "rgb(217, 217, 217)",
        p: 2,
        height: "100%",
        borderRadius: 4,
        // color: themeMode === ThemeModeEnum.Dark ? "#ffffff" : "#000000",
        ...sx,
      }}
    >
      <Typography variant="h5">Capacity factor overview</Typography>
      <Box sx={{ overflowX: "auto" }}>
        <Table
          app="wind"
          columnDefs={[]}
          columns={COLUMNS}
          data={tableData}
          elevation={0}
          mode={themeMode}
          options={{
            download: false,
            search: false,
            downloadOptions: {
              filename: `turbine_models_data.csv`,
            },
            expandableRows: false,
            filter: false,
            hideToolbarMargin: false,
            pagination: false,
            responsive: "standard",
            selectableRows: "none",
            showResponsive: false,
            toolbar: true,
            print: false,
            viewColumns: false,
          }}
        />
      </Box>
    </Box>
  );
};
