import { RootState } from "../../types";

export const getSupplyDemandList = (state: RootState) => {
  return state.supplyDemand.supplyDemandList;
};

export const getInitialFilter = (state: RootState) => {
  return state.supplyDemand.initialFilter;
};

export const getSelectedFilter = (state: RootState) => {
  return state.supplyDemand.selectedFilter;
};

export const getSupplyDemandFilterOptions = (state: RootState) => {
  return state.supplyDemand.filterOptions;
};

export const getDateList = (state: RootState) => {
  return state.supplyDemand.dateList;
};
