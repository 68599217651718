import { LeaseRound } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = { id: number };

type ResponseData = { data: { leaseRound: LeaseRound } };

export const getLeaseRound: ApiMethodDeclaration<ParamsData, ResponseData> = (
  id,
) => ({
  url: `/Wind/LeaseRound/${id}/?api-version=1.0`,
  method: "GET",
});
