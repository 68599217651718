import React, { FC } from "react";
import {
  Box,
  Button,
  getEsgianTheme,
  List,
  ListItem,
  Typography,
} from "@esgian/esgianui";
import moment from "moment";

import { ReactComponent as DetailsIcon } from "../../../assets/details.svg";
import { useSelector } from "../../../hooks/use-selector";
import { getThemeMode } from "../../../store/selector/common";
import { GenericType, THEME, ThemeModeEnum } from "../../../types";

const useStyles = (themeMode: string, titleVariant?: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    container: {
      width: "100%",
    },
    titleBar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontWeight: !titleVariant && 600,
    },
    titleText: {
      textTransform: "capitalize",
    },
    listItem: {
      pt: 1,
      display: "flex",
      alignItems: "center",
      "&::before": {
        px: 0.75,
        content: '"\u2022"',
        color:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.white
            : theme.palette.common.black,
        fontWeight: "bold",
        display: "inline-block",
      },
    },
    valueItem: {
      ml: 0.5,
    },
    shareBtn: {
      display: "flex",
      alignItems: "center",
      marginLeft: "auto",
      minHeight: 0,
      minWidth: 0,
      padding: 0,
    },
  };
};

type Prop = {
  title: string;
  data: GenericType;
  displayIndex?: boolean;
  displayDetailedBtn?: boolean;
  titleVariant?: string;
  handleClick?: (id: number) => void;
};

const DottedList: FC<Prop> = ({
  title,
  data,
  displayIndex = true,
  displayDetailedBtn = false,
  titleVariant,
  handleClick,
}) => {
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode, titleVariant);

  return (
    <Box sx={classes.container} key={title}>
      <Box sx={classes.titleBar}>
        <Typography
          variant={titleVariant || "subtitle1"}
          sx={classes.titleText}
        >
          {title}
        </Typography>
      </Box>
      <List disablePadding={true}>
        {Array.isArray(data) &&
          data.map((item, i) => (
            <ListItem disablePadding={true} key={i} sx={classes.listItem}>
              {item?.key ? (
                <>
                  {displayIndex && (
                    <Typography
                      variant="body2"
                      sx={{ maxWidth: item.value && 130 }}
                    >
                      {item.key}:
                    </Typography>
                  )}
                  <Typography variant="body2" sx={classes.valueItem}>
                    {moment(item.value, moment.ISO_8601).isValid()
                      ? moment(item.value).format("DD-MM-YYYY")
                      : item.value !== "null"
                        ? item.value
                        : ""}
                  </Typography>
                </>
              ) : (
                <Typography variant="body2">{item}</Typography>
              )}
              {displayDetailedBtn && handleClick && (
                <Button
                  variant="text"
                  size="small"
                  sx={classes.shareBtn}
                  onClick={() => handleClick(item.key)}
                  endIcon={<DetailsIcon />}
                >
                  Details
                </Button>
              )}
            </ListItem>
          ))}
      </List>
    </Box>
  );
};

export default DottedList;
