import { FC, useEffect, useState } from "react";
import { Box, Divider, getEsgianTheme, Modal } from "@esgian/esgianui";

import { api } from "../../../api/api";
import { useSelector } from "../../../hooks/use-selector";
import { getCountries, getThemeMode } from "../../../store/selector/common";
import {
  GenericType,
  THEME,
  ThemeModeEnum,
  TurbineDrawer,
  TurbineModelInfo,
} from "../../../types";
import { Loading } from "../../Loading";
import DropDownList from "../DropDownList";
import Paragraph from "../Paragraph";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
    loadingContainer: {
      height: "calc(100vh - 66px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
};

export const TurbineModelDrawer: FC<{
  id?: number | null;
  onClose: () => void;
}> = ({ id, onClose }) => {
  const countries = useSelector(getCountries);
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);
  const [data, setData] = useState<TurbineDrawer | null>(null);

  const fetchTurbineData = async (turbineId: number) => {
    try {
      const { data: turbineModelResponse } = await api.turbine.getTurbines({
        ids: [turbineId],
      });
      const resp: TurbineModelInfo = turbineModelResponse[0];

      const result = {
        keyInfo: {
          turbineId: resp?.turbineModel?.id,
          suppliers: resp?.turbineModel?.suppliers
            ?.map((item) => item.name)
            .join(" "),
          turbineModel: resp?.turbineModel?.name,
          turbineNominalPower: resp?.turbineModel?.turbineNominalPower,
          rotorDiameter: resp?.turbineModel?.rotorDiameter,
          sweptArea: resp?.turbineModel?.sweptArea,
          numberOfBlades: resp?.turbineModel?.numberOfBlades,
          cutInSpeed: resp?.turbineModel?.cutInSpeed,
          cutOutSpeed: resp?.turbineModel?.cutOutSpeed,
          ratedWindSpeed: resp?.turbineModel?.ratedWindSpeed,
          generatorSystem: resp?.turbineModel?.generatorSystem?.system,
        },
        contracts: resp?.contracts
          ?.filter(
            (item: GenericType) =>
              item?.equipmentType?.[0]?.type === "Turbine" &&
              item?.contract?.contractWorkType?.type === "Manufacturing",
          )
          ?.map((item: GenericType) => {
            const equipmentType = item?.equipmentType?.[0]?.type;
            return {
              equipmentType,
              model: resp.turbineModel.name,
              awardDate: item?.contract?.awardDate,
              projectCountry:
                (item?.windProject?.countryId &&
                  countries?.find((c) => c.id === item?.windProject?.countryId)
                    ?.name) ||
                "",
              unitsOrdered: item?.contract?.unitsOrdered,
              operationDate: item?.windProject?.operationDate,
              title: `${equipmentType} - ${item?.windProject?.name}`,
            };
          }),
      };
      setData(result);
    } catch (err) {
      console.log(">>>", err);
      onClose();
    }
  };

  useEffect(() => {
    setData(null);
    if (id) {
      fetchTurbineData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Modal
      sx={classes.modalContainer}
      open={!!id}
      size="sm"
      key={id}
      title={data ? data?.keyInfo?.turbineModel : "Turbine Model"}
      handleClose={onClose}
    >
      {data ? (
        <Box sx={classes.contentBox}>
          <Paragraph
            title="Key Info"
            properties={{
              turbineSupplier: { key: "Turbine supplier" },
              turbineModel: { key: "Turbine model" },
              turbineNominalPower: { key: "Turbine nominal power", unit: "MW" },
              rotorDiameter: { key: "Rotor diameter", unit: "m" },
              sweptArea: {
                key: "Swept area",
                unit: "m",
                mathUnit: <sup>2</sup>,
              },
              numberOfBlades: { key: "Number of blades" },
              cutInSpeed: { key: "Cut in speed", unit: "m/s" },
              cutOutSpeed: { key: "Cut out speed", unit: "m/s" },
              ratedWindSpeed: { key: "Rated wind speed", unit: "m/s" },
              generatorSystem: { key: "Generator System" },
            }}
            data={data.keyInfo}
          />
          <Divider sx={classes.divider} />
          {data?.contracts?.length ? (
            <DropDownList
              title="Contracts"
              subTitleKey="title"
              name="contract"
              data={data.contracts}
              defaultExpand={true}
              properties={{
                equipmentType: { key: "Equipment type" },
                awardDate: { key: "Award date", type: "date" },
                projectCountry: { key: "Project country" },
                unitsOrdered: { key: "Units ordered" },
                operationDate: { key: "Operation date", type: "date" },
              }}
            />
          ) : null}
        </Box>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
