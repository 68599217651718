import { FC } from "react";
import { useSelector } from "react-redux";
import { Divider, getEsgianTheme } from "@esgian/esgianui";

import { getThemeMode } from "../../../store/selector/common";
import { GenericType, THEME, ThemeModeEnum } from "../../../types";
import DropDownList from "../DropDownList";

type Prop = {
  properties: GenericType;
};

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
    loadingContainer: {
      height: "calc(100vh - 66px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
};

const EquipmentDropdownList: FC<Prop> = ({ properties }) => {
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);

  return (
    <>
      {Object.hasOwn(properties, "turbines") && properties.turbines && (
        <>
          <Divider sx={classes.divider} />
          <DropDownList
            title="Turbines contracts"
            subTitleKey="title"
            data={properties.turbinesData}
            defaultExpand={false}
            properties={{
              awardDate: { key: "Contract award date", type: "date" },
              contractedCompanies: { key: "Supplier" },
              numOrdered: { key: "Number of turbines" },
              turbineModel: { key: "Turbine model" },
              contractStatus: { key: "Contract status" },
            }}
          />
        </>
      )}

      {Object.hasOwn(properties, "foundations") && properties.foundations && (
        <>
          <Divider sx={classes.divider} />
          <DropDownList
            title="Fixed foundations contracts"
            subTitleKey="title"
            data={properties.foundationsData}
            defaultExpand={false}
            properties={{
              awardDate: { key: "Contract award date", type: "date" },
              contractedCompanies: { key: "Supplier" },
              numOrdered: { key: "Number of foundations" },
              foundationType: { key: "Foundation type" },
              contractStatus: { key: "Contract status" },
              factory: { key: "Factory" },
              foundationMass: { key: "Foundation mass", unit: "t" },
              foundationLength: { key: "Foundation length", unit: "m" },
              foundationDiameter: {
                key: "Foundation diameter",
                unit: "m",
              },
              foundationThickness: {
                key: "Foundation thickness",
                unit: "mm",
              },
            }}
          />
        </>
      )}

      {Object.hasOwn(properties, "cables") && properties.cables && (
        <>
          <Divider sx={classes.divider} />
          <DropDownList
            title="Cable contracts"
            subTitleKey="title"
            data={properties.cablesData}
            defaultExpand={false}
            properties={{
              awardDate: { key: "Contract award date", type: "date" },
              contractedCompanies: { key: "Supplier" },
              cableType: { key: "Cable type" },
              factory: { key: "Factory" },
              voltage: { key: "Voltage", unit: "kV" },
              outputCurrent: { key: "Output current" },
              numOrdered: { key: "Number of cables" },
              cableLength: { key: "Cable length", unit: "km" },
            }}
          />
        </>
      )}

      {Object.hasOwn(properties, "substations") && properties.substations && (
        <>
          <Divider sx={classes.divider} />
          <DropDownList
            title="Substation contracts"
            subTitleKey="title"
            data={properties.substationsData}
            defaultExpand={false}
            properties={{
              awardDate: { key: "Contract award date", type: "date" },
              contractedCompanies: { key: "Supplier" },
              substation: { key: "Substation" },
              components: { key: "Components" },
              contractStatus: { key: "Contract status" },
              factory: { key: "Factory" },
              outputCurrent: { key: "Output current" },
              voltageIn: { key: "Voltage in", unit: "kV" },
              voltageOut: { key: "Voltage out", unit: "kV" },
            }}
          />
        </>
      )}

      {Object.hasOwn(properties, "towers") && properties.towers && (
        <>
          <Divider sx={classes.divider} />
          <DropDownList
            title="Tower contracts"
            subTitleKey="title"
            data={properties.towersData}
            defaultExpand={false}
            properties={{
              awardDate: { key: "Contract award date", type: "date" },
              contractedCompanies: { key: "Supplier" },
              contractStatus: { key: "Contract status" },
              numOrdered: { key: "Number of towers" },
            }}
          />
        </>
      )}

      {Object.hasOwn(properties, "towers") && properties.towers && (
        <>
          <Divider sx={classes.divider} />
          <DropDownList
            title="Floating contracts"
            subTitleKey="title"
            data={properties.floatingData}
            defaultExpand={false}
            properties={{
              awardDate: { key: "Contract award date", type: "date" },
              contractedCompanies: { key: "Supplier" },
              contractType: { key: "Contract type" },
              factory: { key: "Factory" },
              contractStatus: { key: "Contract status" },
              technologyType: { key: "Technology type" },
            }}
          />
        </>
      )}
    </>
  );
};

export default EquipmentDropdownList;
