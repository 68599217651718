import { FC, useEffect, useState } from "react";
import { Box, Divider, getEsgianTheme, Modal } from "@esgian/esgianui";
import moment from "moment";

import { api } from "../../../api/api";
import { useSelector } from "../../../hooks/use-selector";
import { getCountries, getThemeMode } from "../../../store/selector/common";
import {
  GenericType,
  THEME,
  ThemeModeEnum,
  VesselInformation,
} from "../../../types";
import { Loading } from "../../Loading";
import DropDownList from "../DropDownList";
import Paragraph from "../Paragraph";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
    loadingContainer: {
      height: "calc(100vh - 66px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
};

export const CableLayDrawer: FC<{
  id?: number | null;
  onClose: () => void;
}> = ({ id, onClose }) => {
  const themeMode = useSelector(getThemeMode);
  const countries = useSelector(getCountries);
  const classes = useStyles(themeMode);
  const [data, setData] = useState<GenericType | null>(null);

  const fetchCableLayData = async (cableLayId: number) => {
    try {
      const { data: res }: { data: VesselInformation[] } =
        await api.wind.getVesselInformation({
          imos: [cableLayId],
        });

      const resp = res[0].vessel;
      const contracts: object[] = [];
      res?.[0]?.turbineContracts?.forEach((contract) => {
        contract.equipmentTypes?.forEach((eqCon) => {
          if (
            eqCon.id === 31 ||
            eqCon.id === 32 ||
            eqCon.id === 1 ||
            contract.workType?.id === 2
          ) {
            contracts.push({
              title: `${eqCon.type} - ${contract?.windProject?.name}`,
              awardDate: contract?.awardDate,
              projectName: contract?.windProject?.name,
              projectCountry:
                (contract?.windProject?.countryId &&
                  countries?.find(
                    (country) =>
                      country.id === contract?.windProject?.countryId,
                  )?.name) ||
                "",
              equipmentType: contract?.equipmentTypes?.[0]?.type,
              contractStartDate: contract?.startDate,
              contractEndDate: contract?.endDate,
              cableType: eqCon.type,
              estimated: contract?.contractStatus?.status,
            });
          }
        });
      });
      const result = {
        keyInfo: {
          vesselName: resp.vesselName,
          owner: resp?.owners?.map((c) => c.companyName).join(", "),
          design: resp?.vesselDesign?.design,
          maxCableCapacity: resp?.cableLayingDetails?.capacityMt,
          dateOfDelivery: resp?.dateOfDelivery,
          newbuild: moment(resp?.dateOfDelivery).isAfter(moment())
            ? "Yes"
            : "NO",
          vesselType: resp?.vesselType?.type,
          status: resp?.vesselStatus?.status,
          vesselCapability: resp?.vesselCapabilities
            ?.map((cap) => cap.capability)
            .join(", "),
        },
        vesselInfo: {
          class: resp?.classification?.class,
          imo: resp?.imo,
          flag: resp?.flag?.name,
          constructedYard: resp?.shipyard?.name,
          mmsi: resp?.mmsi,
          length: resp?.loa,
          width: resp?.mouldedBreadth,
          draught: resp?.draught,
          maxDeckStrength: resp?.vesselCapacityDetail?.deckStrengthMtSqm,
          deckArea: resp?.vesselCapacityDetail?.deckAreaSqm,
          maxSpeed: resp?.maximumSpeed,
          maxPob: resp?.vesselCapacityDetail?.accommodation,
        },
        cableLayDetails: {
          primaryTurntableCapacity:
            resp?.cableLayingDetails?.primaryTurntableCapacityMt,
          primaryOuterDiameter: resp?.cableLayingDetails?.primaryOuterDiameterM,
          primaryCoreDiameter: resp?.cableLayingDetails?.primaryCoreDiameterM,
          primaryCoreHeight: resp?.cableLayingDetails?.primaryCoreHeightM,
          secondaryTurntableCapacity:
            resp?.cableLayingDetails?.secondaryTurntableCapacityMt,
          secondaryOuterDiameter:
            resp?.cableLayingDetails?.secondaryOuterDiameterM,
          secondaryCoreHeight: resp?.cableLayingDetails?.secondayCoreDiameterM,
          maxLayingVelocity: resp?.cableLayingDetails?.maxLayingVelocityMHr,
          bollardPull: resp?.vesselCapacityDetail?.bollardPullMt,
          craneType: resp?.craneDetails
            ?.map((detail) => detail?.craneModel?.craneType?.type)
            .join(", "),
          craneModel: resp?.craneDetails
            ?.map((detail) => detail?.craneModel?.modelName)
            .join(", "),
        },
        contracts: contracts || [],
      };
      setData(result);
    } catch (err) {
      onClose();
    }
  };

  useEffect(() => {
    setData(null);
    if (id) {
      fetchCableLayData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Modal
      sx={classes.modalContainer}
      open={!!id}
      size="sm"
      key={id}
      title={data ? data?.keyInfo?.vesselName : "Cable Lay"}
      handleClose={onClose}
    >
      {data ? (
        <Box sx={classes.contentBox}>
          <Paragraph
            title="Key Info"
            properties={{
              vesselName: { key: "Vessel name" },
              owner: { key: "Owner" },
              design: { key: "Design" },
              maxCableCapacity: { key: "Maximum cable capacity", unit: "t" },
              dateOfDelivery: { key: "Date of delivery", type: "date" },
              newbuild: { key: "Newbuild" },
              vesselType: { key: "Vessel type" },
              status: { key: "Status" },
              vesselCapability: { key: "Vessel Capability" },
            }}
            data={data.keyInfo}
          />
          <Divider sx={classes.divider} />
          <Paragraph
            title="Vessel Info"
            properties={{
              class: { key: "Class" },
              imo: { key: "IMO" },
              flag: { key: "Flag" },
              constructedYard: { key: "Constructed at yard" },
              mmsi: { key: "MMSI" },
              length: { key: "Length", unit: "m" },
              width: { key: "Width", unit: "m" },
              draught: { key: "Draught", unit: "m" },
              maxDeckStrength: {
                key: "Maximum deck strength",
                unit: "t/m",
                mathUnit: <sup>2</sup>,
              },
              deckArea: { key: "Deck area", unit: "m", mathUnit: <sup>2</sup> },
              maxSpeed: { key: "Maximum speed", unit: "kn" },
              maxPob: { key: "Maximum POB" },
            }}
            data={data.vesselInfo}
          />
          <Divider sx={classes.divider} />
          <Paragraph
            title="Cable lay details"
            properties={{
              primaryTurntableCapacity: {
                key: "Primary turntable capacity",
                unit: "t",
              },
              primaryOuterDiameter: {
                key: "Primary outer diameter",
                unit: "m",
              },
              primaryCoreDiameter: { key: "Primary core diameter", unit: "m" },
              primaryCoreHeight: { key: "Primary core height", unit: "m" },
              secondaryTurntableCapacity: {
                key: "Secondary turntable capacity",
                unit: "t",
              },
              secondaryOuterDiameter: {
                key: "Secondary outer diameter",
                unit: "m",
              },
              secondaryCoreHeight: { key: "Secondary core height", unit: "m" },
              maxLayingVelocity: { key: "Max laying velocity", unit: "m/s" },
              bollardPull: { key: "Bollard pull", unit: "t" },
              craneType: { key: "Crane type" },
              craneModel: { key: "Crane model" },
            }}
            data={data.cableLayDetails}
          />
          <Divider sx={classes.divider} />
          {data.contracts.length > 0 ? (
            <DropDownList
              title="Contracts"
              subTitleKey="title"
              name="contract"
              data={data.contracts}
              defaultExpand={true}
              properties={{
                awardDate: { key: "Award date" },
                projectName: { key: "Project" },
                projectCountry: { key: "Project country" },
                equipmentType: { key: "Equipment type" },
                contractStartDate: { key: "Contract start date", type: "date" },
                contractEndDate: { key: "Contract end date", type: "date" },
                cableType: { key: "Cable Type" },
                estimated: { key: "Estimated" },
              }}
            />
          ) : null}
        </Box>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
