import { InformationRequestBody, LeaseInfo } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = InformationRequestBody;

type ResponseData = { data: LeaseInfo[] };

export const getLeaseRoundTimeline: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/Wind/LeaseRound/TimeLine?api-version=1.0`,
  method: "POST",
  data,
});
