import { FC } from "react";
import { Box, HelpOutlineIcon, ToolTip, Typography } from "@esgian/esgianui";

import { GenericType } from "../../types";

type Prop = {
  radius: string;
  color?: string;
  hatchedColor?: string;
  title: string;
  sx?: GenericType;
  toolTip?: string;
};

export const Legend: FC<Prop> = ({
  radius,
  color,
  hatchedColor,
  title,
  toolTip,
  sx,
}) => (
  <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
    <Box
      sx={{
        width: 12,
        height: 12,
        borderRadius: radius,
        backgroundColor: color,
        mr: 1,
        backgroundImage: hatchedColor,
      }}
    />
    <Typography sx={{ fontSize: "12px" }}>{title}</Typography>
    {toolTip ? (
      <ToolTip placement="right" title={toolTip}>
        <HelpOutlineIcon sx={{ height: 16, width: 16, ml: 0.5 }} />
      </ToolTip>
    ) : null}
  </Box>
);
