/* eslint-disable no-unsafe-optional-chaining */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { api } from "../../api/api";
import { GenericType, RootState } from "../../types";
import {
  NewsFeedAuthor,
  NewsFeedItem,
  NewsFeedLabels,
} from "../../types/newsFeed";

type State = {
  isLoading: boolean;
  tags: NewsFeedLabels[] | null;
  productId: number | null;
  authorList: NewsFeedAuthor[] | null;
  newsFeed: NewsFeedItem[];
  totalCount: number;
  selectedLabelIds: number[];
  search: string | null;
  page: number;
  selectedItem: {
    selectedItem: NewsFeedItem;
    relatedItems: NewsFeedItem[];
  } | null;
};

const initialState: State = {
  isLoading: true,
  tags: null,
  productId: null,
  authorList: null,
  newsFeed: [],
  totalCount: 0,
  selectedLabelIds: [],
  page: 1,
  search: "",
  selectedItem: null,
};

export const fetchProduct = createAsyncThunk("newsFeed/products", async () => {
  try {
    const { data } = await api.newsFeed.getProducts();
    const windProduct: GenericType = data.filter(
      (item: GenericType) => item.product === "Wind",
    )?.[0];

    return windProduct.id;
  } catch (err) {
    return null;
  }
});

export const fetchTagList = createAsyncThunk("newsFeed/tags", async () => {
  try {
    const { data } = await api.newsFeed.getNewsFeedTagList();
    const windTags: GenericType = data.filter(
      (item: GenericType) => item.product === "Wind",
    )?.[0];

    return windTags?.tags;
  } catch (err) {
    return null;
  }
});

export const fetchAuthorList = createAsyncThunk(
  "newsFeed/authors",
  async () => {
    try {
      const { data } = await api.newsFeed.getAuthor();
      const authorList = data
        .map((item: GenericType) => item.author)
        .filter((item: GenericType) => item?.id);
      return authorList;
    } catch (err) {
      return null;
    }
  },
);

export const fetchNewsFeed = createAsyncThunk(
  "newsFeed",
  async (page: number, { getState }) => {
    try {
      const state = getState() as RootState;
      const productId = state.newsFeed.productId;
      const authorList = state.newsFeed.authorList;
      let tags = state.newsFeed.selectedLabelIds;
      const searchTerm = state.newsFeed.search;
      if (!tags.length) {
        tags = state.newsFeed.tags?.map((t) => t.id) || [];
      }
      const { data } = await api.newsFeed.getNewsFeed({
        productId,
        page,
        count: 20,
        tags,
        searchTerm,
      });
      const list = data.items.map((item: NewsFeedItem) => {
        const authors =
          authorList?.length &&
          item?.authors?.map((itemAuthor: GenericType) => {
            authorList?.find((author) => author.id === itemAuthor?.userId);
          });
        return {
          ...item,
          authors,
        };
      });
      return { list, total: data.pagination.rowCount, page };
    } catch (err) {
      return { list: [], total: 0, page: 0 };
    }
  },
);

export const fetchNewsFeedItem = createAsyncThunk(
  "newsFeedItem",
  async (id: number, { getState }) => {
    try {
      const state = getState() as RootState;
      const authorList = state.newsFeed.authorList;
      const productId = state.newsFeed.productId;
      const { data: selectedData } = await api.newsFeed.getNewsFeed({
        contentIds: [id],
      });
      const selectedItem = selectedData.items.map((item: NewsFeedItem) => {
        const authors =
          authorList?.length &&
          item?.authors?.map((itemAuthor: GenericType) => {
            authorList?.find((author) => author.id === itemAuthor?.userId);
          });
        return {
          ...item,
          authors,
        };
      })[0];
      const tags = selectedItem?.tags?.map((tag: GenericType) => tag.id);
      const { data } = await api.newsFeed.getNewsFeed({
        productId,
        page: 0,
        count: 3,
        tags,
      });
      const relatedItems = data.items.map((item: NewsFeedItem) => {
        const authors =
          authorList?.length &&
          item?.authors?.map((itemAuthor: GenericType) => {
            authorList?.find((author) => author.id === itemAuthor?.userId);
          });
        return {
          ...item,
          authors,
        };
      });
      if (selectedItem) {
        return { selectedItem, relatedItems: relatedItems };
      }
      return null;
    } catch (err) {
      return null;
    }
  },
);

const newsFeedSlice = createSlice({
  name: "newsFeed",
  initialState,
  reducers: {
    setLabelId: (state, action: PayloadAction<number>) => {
      const index = state.selectedLabelIds.indexOf(action.payload);
      if (index >= 0) {
        state.selectedLabelIds.splice(index, 1);
      } else {
        state.selectedLabelIds.push(action.payload);
      }
      state.newsFeed = [];
      state.totalCount = 0;
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
      state.newsFeed = [];
      state.totalCount = 0;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setSelectedItem: (
      state,
      action: PayloadAction<{
        selectedItem: NewsFeedItem;
        relatedItems: NewsFeedItem[];
      } | null>,
    ) => {
      state.selectedItem = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchProduct.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchProduct.rejected, (state) => {
        state.productId = null;
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.productId = action.payload;
      });
    builder
      .addCase(fetchTagList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchTagList.rejected, (state) => {
        state.tags = null;
      })
      .addCase(fetchTagList.fulfilled, (state, action) => {
        state.tags = action.payload;
      });
    builder
      .addCase(fetchAuthorList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchAuthorList.rejected, (state) => {
        state.authorList = null;
      })
      .addCase(fetchAuthorList.fulfilled, (state, action) => {
        state.authorList = action.payload;
      });
    builder
      .addCase(fetchNewsFeed.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchNewsFeed.rejected, (state) => {
        state.isLoading = false;
        state.newsFeed = [];
      })
      .addCase(fetchNewsFeed.fulfilled, (state, action) => {
        state.newsFeed =
          action?.payload?.page == 0
            ? action?.payload?.list
            : [...state?.newsFeed, ...action?.payload?.list];
        state.totalCount = action.payload?.total;
        state.isLoading = false;
      });
    builder
      .addCase(fetchNewsFeedItem.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchNewsFeedItem.rejected, (state) => {
        state.isLoading = false;
        state.selectedItem = null;
      })
      .addCase(fetchNewsFeedItem.fulfilled, (state, action) => {
        state.selectedItem = action.payload;
        state.isLoading = false;
      });
  },
});

export const { setLabelId, setSearch, setPage, setSelectedItem } =
  newsFeedSlice.actions;
export const newsFeed = newsFeedSlice.reducer;
