import { FloatingTechnologyChart } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = { ids?: number[] };

type ResponseData = { data: FloatingTechnologyChart[] };

export const getFloatingTechnologyAdaption: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/Market/Adaption?api-version=1.0`,
  method: "POST",
  data,
});
