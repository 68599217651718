import { FC } from "react";
import {
  Box,
  Checkbox,
  Chip,
  CloseIcon,
  Divider,
  getEsgianTheme,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@esgian/esgianui";

import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import { GenericType, THEME, ThemeModeEnum } from "../../types";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);

  return {
    label: {
      backgroundColor:
        themeMode === ThemeModeEnum.Dark
          ? theme.palette.common.black
          : theme.palette.common.white,
    },

    select: {
      width: 1,
      "& .MuiSelect-select": {
        py: 2,
        px: 1,
      },
      "& .Mui-selected": {
        background: "red",
      },
    },
    selectMenu: {
      background:
        themeMode === ThemeModeEnum.Dark
          ? theme.palette.common.black
          : theme.palette.common.white,
      maxHeight: 288,
    },
    selectItem: {
      p: 0,
    },
    listItem: {
      maxWidth: 280,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    selectedItem: {
      background: `${theme.palette.secondary.dark} !important`,
      color: theme.palette.primary.main,
    },
    chip: {
      height: 20,
      pr: 1,
      position: "relative",
      "& .MuiChip-label": {
        mr: "4px !important",
      },
      "& svg": {
        position: "absolute",
        right: 0,
        fontSize: `16px !important`,
        "& path": {
          fill: theme.palette.common.white,
        },
      },
    },
  };
};
type Prop = {
  options: GenericType[];
  labelKey: string;
  valueKey: string;
  selectedItem: number[];
  setSelectedItem: (items: number[]) => void;
  inputLabelText: string;
};

export const StylesSelect: FC<Prop> = ({
  options,
  selectedItem,
  setSelectedItem,
  labelKey,
  valueKey,
  inputLabelText,
}) => {
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);

  const selectMenuProps = {
    PaperProps: {
      style: {
        ...classes.selectMenu,
      },
    },
    variant: "menu",
  };
  return (
    <Box>
      <InputLabel id="label-id" sx={classes.label}>
        {inputLabelText}
      </InputLabel>
      <Select
        labelId="label-id"
        multiple={true}
        sx={classes.select}
        value={selectedItem}
        size="small"
        onChange={({ target: { value } }: GenericType) => {
          if (value.includes("All")) {
            if (value.length > options.length) {
              setSelectedItem([]);
            } else {
              if (valueKey) {
                setSelectedItem(
                  options.map((item: GenericType) => item[valueKey]),
                );
              }
            }
          } else {
            setSelectedItem(value);
          }
        }}
        MenuProps={selectMenuProps}
        renderValue={(selected: number[]) =>
          selected.length !== options.length ? (
            <>
              {selected.slice(0, 2).map((value) => {
                const item = options.find(
                  (i) => i[valueKey] === value,
                ) as GenericType;
                return (
                  <Chip
                    key={value}
                    label={item[labelKey]}
                    onMouseDown={(event: Event) => {
                      event.stopPropagation();
                    }}
                    sx={classes.chip}
                    deleteIcon={<CloseIcon />}
                    onDelete={() => {
                      setSelectedItem(selectedItem.filter((i) => i !== value));
                    }}
                  />
                );
              })}

              {selected.length - 2 > 0 && `+${selected.length - 2}`}
            </>
          ) : (
            "All"
          )
        }
      >
        <MenuItem
          key="All"
          value="All"
          sx={{
            ...(options.length === selectedItem.length && classes.selectedItem),
            ...classes.selectItem,
          }}
        >
          <Checkbox
            checked={
              options.length === selectedItem.length && classes.selectedItem
            }
          />
          <ListItemText sx={classes.listItem} primary="All" />
        </MenuItem>
        <Divider />
        {options.map((item: GenericType, i) => (
          <MenuItem
            key={i}
            value={valueKey ? item[valueKey] || item : item}
            sx={{
              ...(selectedItem.includes(item[valueKey]) &&
                classes.selectedItem),
              ...classes.selectItem,
            }}
          >
            <Checkbox checked={selectedItem.includes(item[valueKey])} />
            <ListItemText sx={classes.listItem} primary={item[labelKey]} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
