import { GenericType } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = { imo: number };

type ResponseData = { data: GenericType[] };

export const getEquipmentContract: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/Equipment/Contracts?api-version=1.0`,
  method: "GET",
  data,
});
