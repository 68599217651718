import React, { FC, useEffect, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import { Box, getEsgianTheme } from "@esgian/esgianui";
import { ApexOptions } from "apexcharts";
import moment from "moment";

import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import {
  getDateRange,
  getHistoricalCapacity,
  getSelectedTimePeriod,
} from "../../store/selector/powerOutput";
import { fetchPowerOutput, setDateRange } from "../../store/slice/powerOutput";
import { GenericType, THEME, ThemeModeEnum } from "../../types";

export const POWER_OUTPUT_TIME_PERIOD = {
  1: "1-day",
  2: "1-week",
  3: "1-month",
  4: "3-month",
  5: "6-month",
  6: "YTD",
  7: "1-year",
  8: "3-years",
  9: "5-years",
  10: "10-years",
} as const;

type Prop = {
  sx: GenericType;
};

const generateCategories = (s: string, e: string, yAxisType: string) => {
  const categories = [];
  const startDate = moment(s);
  const endDate = moment(e);
  while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
    const format = "DD-MMM-YY";

    if (yAxisType === "Hourly") {
      categories.push(startDate.format("DD-MM-YY HH:mm"));
      startDate.add(1, "hour");
    } else if (yAxisType === "Daily") {
      categories.push(startDate.format(format));
      startDate.add(1, "day");
    } else if (yAxisType === "Weekly") {
      categories.push(startDate.format(format));
      startDate.add(1, "week");
    } else if (yAxisType === "Monthly") {
      categories.push(startDate.format("MMM-YY"));
      startDate.add(1, "month");
    } else if (yAxisType === "Yearly") {
      categories.push(startDate.format("MMM-YY"));
      startDate.add(1, "year");
    } else {
      categories.push(startDate.format("MMM-YYYY"));
      startDate.add(1, "day");
    }
  }

  return categories;
};

const calculateDateRange = (period: string) => {
  const endDate = moment().startOf("hour");
  let startDate;
  let yAxisType;

  switch (period) {
    case "1-day":
      startDate = endDate
        .clone()
        .subtract(1, "day")
        .add(1, "hour")
        .startOf("hour");
      yAxisType = "Hourly";
      break;
    case "1-week":
      startDate = endDate
        .clone()
        .subtract(1, "week")
        .add(1, "hour")
        .startOf("hour");
      yAxisType = "Hourly";
      break;
    case "1-month":
      startDate = endDate
        .clone()
        .subtract(1, "month")
        .add(1, "hour")
        .startOf("hour");
      yAxisType = "Hourly";
      break;
    case "3-month":
      startDate = endDate.clone().subtract(3, "months").startOf("day");
      yAxisType = "Daily";
      break;
    case "6-month":
      startDate = endDate.clone().subtract(6, "months").startOf("day");
      yAxisType = "Daily";
      break;
    case "YTD":
      startDate = moment().startOf("year");
      yAxisType = "Daily";
      break;
    case "1-year":
      startDate = endDate.clone().subtract(1, "year").startOf("day");
      yAxisType = "Daily";
      break;
    case "3-years":
      startDate = endDate.clone().subtract(3, "years").startOf("week");
      yAxisType = "Weekly";
      break;
    case "5-years":
      startDate = endDate.clone().subtract(5, "years").startOf("week");
      yAxisType = "Weekly";
      break;
    case "10-years":
      startDate = endDate.clone().subtract(10, "years").startOf("month");
      yAxisType = "Monthly";
      break;
    default:
      startDate = endDate.clone().subtract(15, "years").startOf("year");
      yAxisType = "Yearly";
      break;
  }

  return {
    startDate: startDate.utc().format(),
    endDate: endDate.utc().format(),
    yAxisType,
  };
};

const timeFormatMapping: Record<string, string> = {
  Hourly: "HH:mm",
  Daily: "DD MMM",
  Weekly: "DD MMM",
  Monthly: "MMM YY",
  Yearly: "MMM YYYY",
};

const tooltipFormatMapping: Record<string, string> = {
  Hourly: "DD-MM-YY HH:mm",
  Daily: "DD-MMM-YY",
  Weekly: "DD-MMM-YY",
  Monthly: "MMM-YY",
  Yearly: "MMM-YYYY",
};

export const PowerOutputHistoricalCapacityAreaChart: FC<Prop> = ({ sx }) => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const selectedTimePeriod = useSelector(getSelectedTimePeriod);
  const dateRange = useSelector(getDateRange);
  const historicalCapacity = useSelector(getHistoricalCapacity);
  const theme = getEsgianTheme(themeMode, THEME);

  useEffect(() => {
    if (selectedTimePeriod?.title) {
      const range = calculateDateRange(selectedTimePeriod.title);
      dispatch(setDateRange(range));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimePeriod]);

  useEffect(() => {
    dispatch(fetchPowerOutput());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const categories = useMemo(() => {
    if (!dateRange) return [];
    return generateCategories(
      dateRange.startDate,
      dateRange.endDate,
      dateRange.yAxisType,
    );
  }, [dateRange]);

  const series = useMemo(() => {
    if (!categories.length || !historicalCapacity?.length) return [];
    return historicalCapacity?.map((capacityItem: GenericType) => {
      const dataMap = new Map(
        capacityItem?.values?.map((item: GenericType) => [
          moment(item.date).format(
            tooltipFormatMapping[dateRange?.yAxisType || "Daily"],
          ),
          item.value?.toFixed(2),
        ]),
      );
      const result = categories.map((date) => dataMap.get(date) || null);

      return {
        name: capacityItem.name,
        data: result,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, historicalCapacity]);

  const options: ApexOptions = useMemo(() => {
    if (!dateRange) return {};
    return {
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
        background:
          themeMode === ThemeModeEnum.Dark && theme.palette.common.black,
        toolbar: {
          show: true,
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          },
        },
      },
      title: {
        text: "Power Output Over Time", // Add your chart title here
        align: "left",
        style: {
          fontSize: "20px",
          color:
            themeMode === ThemeModeEnum.Dark
              ? theme.palette.common.white
              : theme.palette.common.black,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        categories: categories,
        labels: {
          formatter: (val) => {
            const index = categories.indexOf(val);
            if (
              categories.length > 30 &&
              index % 10 !== 0 &&
              index !== categories.length - 1
            ) {
              return "";
            }
            return moment(val).format(timeFormatMapping[dateRange.yAxisType]);
          },
          style: {
            colors:
              themeMode === ThemeModeEnum.Dark
                ? theme.palette.common.white
                : theme.palette.common.black,
          },
        },
      },
      yaxis: {
        labels: {
          formatter: (val: number) => Math.round(val).toFixed(0),
          style: {
            colors:
              themeMode === ThemeModeEnum.Dark
                ? theme.palette.common.white
                : theme.palette.common.black,
          },
        },
      },
      tooltip: {
        x: {
          formatter: (i, { dataPointIndex }) => {
            const format = tooltipFormatMapping[dateRange.yAxisType];
            return moment(categories[dataPointIndex]).format(format);
          },
        },
        theme: themeMode === ThemeModeEnum.Dark ? "dark" : "light",
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "left",
        labels: {
          colors:
            themeMode === ThemeModeEnum.Dark
              ? theme.palette.common.white
              : theme.palette.common.black,
        },
      },
    };
  }, [theme, themeMode, categories, dateRange]);

  if (!dateRange) {
    return null;
  }

  return (
    <Box
      sx={{
        "& .apexcharts-menu": {
          background:
            themeMode === ThemeModeEnum.Dark
              ? theme.palette.common.black
              : theme.palette.common.white,
          "& .apexcharts-menu-item": {
            ":hover": {
              background: "#eee",
              color: theme.palette.common.black,
            },
          },
        },
        ...sx,
      }}
    >
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={450}
      />
    </Box>
  );
};
