import { RootState } from "../../types";

export const getTags = (state: RootState) => {
  return state.newsFeed.tags;
};

export const getProductId = (state: RootState) => {
  return state.newsFeed.productId;
};

export const getIsLoading = (state: RootState) => {
  return state.newsFeed.isLoading;
};

export const getAuthorList = (state: RootState) => {
  return state.newsFeed.authorList;
};

export const getNewsFeed = (state: RootState) => {
  return state.newsFeed.newsFeed;
};

export const getNewsFeedSelectedItem = (state: RootState) => {
  return state.newsFeed.selectedItem;
};

export const getTotalCount = (state: RootState) => {
  return state.newsFeed.totalCount;
};

export const getSelectedLabelIds = (state: RootState) => {
  return state.newsFeed.selectedLabelIds;
};

export const getSearch = (state: RootState) => {
  return state.newsFeed.search;
};

export const getPage = (state: RootState) => {
  return state.newsFeed.page;
};
