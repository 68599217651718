import { getCountry } from "./getCountry";
import { getCountryList } from "./getCountryList";
import { getCountryListInfo } from "./getCountryListInfo";
import { getAllCountries } from "./getAllCountries";

export const country = {
  getCountry,
  getCountryList,
  getCountryListInfo,
};

export const countries = {
  getAllCountries,
};
