import { GenericType } from "../types";

export function exportToCSV(
  rows: GenericType[],
  filename: string,
  mapping: GenericType,
) {
  const headers = Object.keys(mapping);

  const csvContent =
    headers
      .map((header) => {
        const displayName = mapping[header] || header;
        return `"${displayName}"`;
      })
      .join(",") +
    "\n" +
    rows
      .map((row) => {
        return headers
          .map((header) => {
            const value = row[header] || "N/A";
            return value.includes(",") ? `"${value}"` : value;
          })
          .join(",");
      })
      .join("\n");

  const encodedUri =
    "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
}
