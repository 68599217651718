import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@esgian/esgianui";

import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import {
  AuthorizeUser,
  GenericType,
  ROUTES_CONFIG,
  ThemeModeEnum,
} from "../../types";

interface BlurredComponentProps {
  blurAmount?: string;
  sx?: GenericType;
  children: React.ReactNode;
  isAuthenticated: AuthorizeUser;
  publicItem: boolean;
}

export const BlurredComponent: React.FC<BlurredComponentProps> = ({
  blurAmount = "6px",
  sx = {},
  children,
  isAuthenticated,
  publicItem,
}) => {
  const themeMode = useSelector(getThemeMode);
  const navigate = useNavigate();
  const blur = isAuthenticated !== AuthorizeUser.Authorized && !publicItem;
  return (
    <Box
      sx={{
        filter: blur ? `blur(${blurAmount})` : "none",
        backgroundColor: blur
          ? themeMode === ThemeModeEnum.Dark
            ? "rgba(255, 255, 255, 0.5)"
            : "rgba(0, 0, 0, 0.5)"
          : "none",
        height: blur ? 22 : "auto",
        width: blur ? 130 : 218,
        ...sx,
      }}
      onClick={() => (blur ? navigate(ROUTES_CONFIG.Enquiry.url) : null)}
    >
      {isAuthenticated === AuthorizeUser.Authorized || publicItem
        ? children
        : null}
    </Box>
  );
};
