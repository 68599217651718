import { Box, Link, Typography } from "@esgian/esgianui";

export const Faq = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Box mb={4}>
        <Typography variant="h5">
          What data can I find on the platform?
        </Typography>
        <Typography mt={1}>
          The Esgian Wind Analytics platform provides users with up-to-date
          data, aimed at reflecting the activity in the offshore wind industry.
          Data includes details on all phases in the life cycle of an offshore
          wind installation, including supply chain contracts, vessel activity,
          current and future projects and power outputs.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5">How often is the data updated?</Typography>
        <Typography mt={1}>
          The Esgian team strives to keep the dataset as up-to-date as possible
          and reflecting current market developments. Updates to the dataset are
          done continuously, with daily adjustments in all sections of the data
          platform.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5">How do you ensure data quality?</Typography>
        <Typography mt={1}>
          Esgian’s Wind Analytics’ team of analysts and database services
          veterans continuously monitors, tracks, and interacts with its
          industry network to ensure that our data is updated and accurate.
          Estimates in the database are generated, tested, and proved models and
          checked by the analyst team to ensure quality.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5">Can I export or download the data?</Typography>
        <Typography mt={1}>
          All data tables in the Esgian Wind Analytics platform are available
          for clients for download in a CSV format. Figures and charts are also
          available for download and use, including in SVG, PNG, and CSV format.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5">
          How can I get user support or more information?
        </Typography>
        <Typography mt={1} sx={{ display: "flex" }}>
          For technical support, questions regarding the usage of the site, or
          to report issues with the site,
          <Link
            href={`mailto:${process.env.REACT_APP_FEEDBACK_EMAIL}`}
            color="primary"
            ml={1}
          >
            contact us
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  );
};
