import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box } from "@esgian/esgianui";
import mapboxgl, { Map as MapType, MapMouseEvent, Marker } from "mapbox-gl";

import { Circle } from "../../helper/CircleLayer";
import { calculateFilterCount } from "../../helper/fllter";
import {
  AddFilterButton,
  CENTER,
  CIRCLE_CLICK_ZOOM,
  CreateMap,
  removeMarkers,
  searchZoom,
  THRESHOLD_ZOOM,
  UpdateFilterButton,
  // ZOOM,
} from "../../helper/map";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getCompany,
  getSearchObject,
  getThemeMode,
  getUserLocationCoordinates,
} from "../../store/selector/common";
import {
  getFactoryList,
  getSelectedFilter,
} from "../../store/selector/factory";
import { setFilterModal, setSelectedFactoryId } from "../../store/slice/common";
import { fetchFactoryList } from "../../store/slice/factory";
import {
  CompanyOption,
  CoordinatesObj,
  GenericType,
  MapLayer,
  MapSource,
} from "../../types";

import { FactoryIcon } from "./helper/FactoryLayer";
import { useStyles } from "./helper";

import "mapbox-gl/dist/mapbox-gl.css";

const { REACT_APP_MAP_TOKEN } = process.env;

type Prop = {
  controller?: boolean;
  zoom?: number;
  center?: [number, number] | CoordinatesObj;
  move?: boolean;
  markerInfo?: {
    name: string;
    center: [number, number] | CoordinatesObj;
  };
};

export const FactoriesMap: FC<Prop> = ({
  controller = true,
  zoom = 7,
  center = CENTER,
  move = true,
  // markerInfo,
}) => {
  const dispatch = useDispatch();
  const [markerList, setMarkerList] = useState<Marker[]>([]);

  const company = useSelector(getCompany);
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);
  const factoriesList = useSelector(getFactoryList);
  const userLocationCoordinates = useSelector(getUserLocationCoordinates);
  const searchObject = useSelector(getSearchObject);
  const selectedFilter = useSelector(getSelectedFilter);
  const mapInstanceRef = useRef<MapType | null>(null);

  const factoryIcon = new FactoryIcon();
  const circle = new Circle();

  const filterCount = useMemo(
    () => (selectedFilter ? calculateFilterCount(selectedFilter) : 0),
    [selectedFilter],
  );

  const fetchList = async () => {
    await dispatch(fetchFactoryList());
  };

  useEffect(() => {
    if (mapInstanceRef.current) {
      fetchList();
      UpdateFilterButton(mapInstanceRef.current, filterCount);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  const handleUserLocationMove = useCallback(() => {
    if (mapInstanceRef.current && userLocationCoordinates) {
      mapInstanceRef.current.flyTo({
        center: userLocationCoordinates,
        zoom: 3,
      });
    }
  }, [userLocationCoordinates, mapInstanceRef]);

  const handleMouseEnter = (e: GenericType) => {
    if (!mapInstanceRef.current) return;
    if (e.features.length > 0) {
      mapInstanceRef.current.getCanvas().style.cursor = "pointer";
      mapInstanceRef.current.setFeatureState(
        { source: MapSource.FactorySource, id: e.features[0].id },
        { hover: true },
      );
    }
  };

  const handleClick = (e: MapMouseEvent) => {
    if (!mapInstanceRef.current) return;
    const firstFeature: mapboxgl.MapboxGeoJSONFeature =
      mapInstanceRef.current.queryRenderedFeatures(e.point, {
        layers: [MapLayer.Factory, MapLayer.Circle],
      })[0];

    if (
      firstFeature &&
      firstFeature.source === MapSource.CircleSource &&
      firstFeature.geometry.type === "Point"
    ) {
      const coordinates = firstFeature.geometry.coordinates;
      mapInstanceRef.current.flyTo({
        center: coordinates as [number, number],
        zoom: CIRCLE_CLICK_ZOOM,
      });
      return;
    }

    if (firstFeature) {
      dispatch(setSelectedFactoryId(Number(firstFeature.id)));
    }
  };

  const handleMouseLeave = () => {
    if (!mapInstanceRef.current) return;
    mapInstanceRef.current.getCanvas().style.cursor = "";
    mapInstanceRef.current.removeFeatureState({
      source: MapSource.FactorySource,
      sourceLayer: MapLayer.Factory,
    });
  };

  const handleZoom = () => {
    if (!mapInstanceRef.current) return;
    const zoomLevel = mapInstanceRef.current.getZoom();
    if (zoomLevel >= THRESHOLD_ZOOM) {
      circle.disable(mapInstanceRef.current);
      factoryIcon.visible(mapInstanceRef.current);
    } else {
      circle.visible(mapInstanceRef.current);
    }
  };

  useEffect(() => {
    fetchList();
    mapboxgl.accessToken = REACT_APP_MAP_TOKEN ?? "";
    const coordinates = (userLocationCoordinates ?? center) as CoordinatesObj;
    const map = CreateMap(zoom, coordinates, move, themeMode);
    map.on("style.load", () => {
      mapInstanceRef.current = map;
      factoryIcon.init(map);
      circle.init(map);

      map.on("mouseenter", MapLayer.Factory, handleMouseEnter);
      map.on("click", handleClick);
      map.on("mouseleave", MapLayer.Factory, handleMouseLeave);
      map.on("zoom", handleZoom);
      handleUserLocationMove();
    });

    if (controller) {
      AddFilterButton(map, classes.filterButton, filterCount, () =>
        dispatch(setFilterModal(true)),
      );

      map.addControl(new mapboxgl.NavigationControl(), "top-right");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeMode]);

  useEffect(() => {
    if (mapInstanceRef.current && move) {
      if (searchObject) {
        if (searchObject.type === "Company") {
          removeMarkers(markerList, (v) => setMarkerList(v));
          const selectedCompanyWindProjects = company.find(
            (com: CompanyOption) => com.company.id === searchObject.id,
          )?.factories;
          const markers: Marker[] = [];
          selectedCompanyWindProjects?.forEach((selectedProject) => {
            if (
              selectedProject.coordinates.lat &&
              selectedProject.coordinates.lon
            ) {
              const marker = new mapboxgl.Marker({ color: "red" })
                .setLngLat(selectedProject.coordinates)
                .addTo(mapInstanceRef.current as MapType);
              const popup = new mapboxgl.Popup({
                offset: 35,
                className: "marker-popup",
              }).setText(selectedProject?.name);

              marker.setPopup(popup).togglePopup();
              markers.push(marker);
            }
          });
          mapInstanceRef.current.flyTo({
            zoom: 2,
          });
          setMarkerList(markers);
        } else {
          searchZoom(mapInstanceRef.current, searchObject);
        }
      }
      if (!searchObject) {
        removeMarkers(markerList, (v) => setMarkerList(v));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchObject, move]);

  useEffect(() => {
    if (mapInstanceRef.current && factoriesList) {
      const map = mapInstanceRef.current;

      circle.update(map, factoriesList);
      factoryIcon.update(map, factoriesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstanceRef, factoriesList]);

  return (
    <Box sx={{ height: "100%" }}>
      <Box id="map" sx={classes.map} />
    </Box>
  );
};
