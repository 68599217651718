import { sortValues } from "../../helper/fllter";
import { FilterItemType, GenericType, RootState } from "../../types";

export const getProjectPPAList = (state: RootState) => {
  return state.ppaContract.ppaProjectList;
};

export const getSelectedFilter = (state: RootState) => {
  return state.ppaContract.selectedFilter;
};

const filterDuplicates = (data: GenericType[]) => {
  const ids: GenericType[] = [];
  const results: GenericType[] = [];
  data.forEach((item) => {
    if (!ids.includes(item.id)) {
      results.push(item);
      ids.push(item.id);
    }
  });
  return results;
};

export const getFilterOptions = (state: RootState) => {
  return {
    project: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Wind project",
      options: sortValues(
        filterDuplicates(
          [...(state?.ppaContract?.ppaProjectList ?? [])].map((item) => ({
            id: item.project,
            value: item.project,
          })) || [],
        ),
      ),
    },
    country: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Country",
      options: sortValues(
        filterDuplicates(
          [...(state?.ppaContract?.ppaProjectList ?? [])].map((item) => ({
            id: item.country,
            value: item.country,
          })) || [],
        ),
      ),
    },
    projectStatus: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Wind project status",
      options: sortValues(
        filterDuplicates(
          [...(state?.ppaContract?.ppaProjectList ?? [])].map((item) => ({
            id: item.projectStatus,
            value: item.projectStatus,
          })) || [],
        ),
      ),
    },
    ppaType: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "PPA type",
      options: sortValues(
        filterDuplicates(
          [...(state?.ppaContract?.ppaProjectList ?? [])].map((item) => ({
            id: item.ppaType,
            value: item.ppaType,
          })) || [],
        ),
      ),
    },
    buyer: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Buyer",
      options: sortValues(
        filterDuplicates(
          [...(state?.ppaContract?.ppaProjectList ?? [])].map((item) => ({
            id: item.buyer,
            value: item.buyer,
          })) || [],
        ),
      ),
    },
    seller: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Seller",
      options: sortValues(
        filterDuplicates(
          [...(state?.ppaContract?.ppaProjectList ?? [])].map((item) => ({
            id: item.seller,
            value: item.seller,
          })) || [],
        ),
      ),
    },
  };
};

export const getIsLoading = (state: RootState) => {
  return state.ppaContract.isLoading;
};

export const getSortingInfo = (state: RootState) => {
  return state.ppaContract.sortingInfo;
};

export const getVisibleColumns = (state: RootState) => {
  return state.ppaContract.visibleColumns;
};
