import { FC } from "react";
import { Box, InfoOutlineIcon, Typography } from "@esgian/esgianui";

export const NotFound: FC = () => {
  return (
    <Box>
      <Box
        sx={{
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
          flexDirection: "row",
          mt: 2,
          p: 2,
          gap: 2,
          backgroundColor: "#C4C4C4",
          display: "flex",
          alignItems: "center",
        }}
      >
        <InfoOutlineIcon
          sx={{
            path: {
              fill: "#000000",
            },
          }}
        />
        <Box>
          <Typography variant="subtitle1" color="#000000">
            No results found
          </Typography>
          <Typography variant="body2" color="#000000">
            Try adjusting filters or search again.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
