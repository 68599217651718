/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Divider, getEsgianTheme, Modal } from "@esgian/esgianui";

import { api } from "../../../api/api";
import { getErrorMessage } from "../../../helper/errorHandling";
import { useSelector } from "../../../hooks/use-selector";
import { getCountries, getThemeMode } from "../../../store/selector/common";
import {
  BidDrawerData,
  Country,
  GenericType,
  IdNamePair,
  MESSAGE,
  THEME,
  ThemeModeEnum,
} from "../../../types";
import { Loading } from "../../Loading";
import DropDownList from "../DropDownList";
import Paragraph from "../Paragraph";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
      "& .MuiDialogTitle-root": {
        width: "94%",
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
  };
};

type Prop = {
  id?: number | null;
  onClose: () => void;
};

export const BidDrawer: FC<Prop> = ({ id, onClose }) => {
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);
  const countryList = useSelector(getCountries);

  const [data, setData] = useState<BidDrawerData | null>(null);

  const fetchLeaseRoundData = async () => {
    try {
      const { data: res } = await api.bid.getBidById(id);
      setData({
        keyInfo: {
          name: res?.bidName,
          status: res?.bidStatus,
          developers: res?.developers
            ?.map((item: GenericType) => item?.companyName)
            .join(", "),
          country: countryList?.find(
            (item: Country) => item.id === res.countryId,
          )?.name,
          seaRegion: res?.seaRegion
            ?.map((item: IdNamePair) => item.name)
            .join(", "),
        },
        bidDetails: res.windProjectsInformation,
      });
    } catch (err) {
      toast.error(`${MESSAGE.LeaseRound}: ${getErrorMessage(err)}`);
    }
  };

  const handleClose = () => {
    return onClose();
  };

  useEffect(() => {
    setData(null);
    if (id) {
      fetchLeaseRoundData();
    }
  }, [id]);

  return (
    <Modal
      sx={classes.modalContainer}
      open={!!id}
      size="sm"
      key={id}
      title={data?.keyInfo?.name || `Bid`}
      handleClose={handleClose}
    >
      {data ? (
        <Box sx={classes.contentBox}>
          <Paragraph
            title="Key Info"
            properties={{
              name: { key: "Bid Name" },
              status: { key: "Status" },
              developers: { key: "Developers" },
              country: { key: "Country" },
              seaRegion: { key: "Sea Region" },
            }}
            data={data.keyInfo}
          />
          {data?.bidDetails?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Bid details"
                subTitleKey="projectName"
                name="bid"
                data={data.bidDetails}
                defaultExpand={true}
                properties={{
                  capacityMW: { key: "Planned capacity", unit: "MW" },
                  turbine: { key: "Number of turbines" },
                  turbineModel: { key: "Turbine Model" },
                  foundationType: { key: "Foundation Type" },
                  foundationModel: { key: "Foundation model" },
                }}
              />
            </>
          ) : null}
        </Box>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
