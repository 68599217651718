import { CompanyOption } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = undefined;

type ResponseData = { data: CompanyOption[] };

export const getCompanies: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = () => ({
  url: `/Companies?api-version=1.0`,
  method: "GET",
});
