import { RootState } from "../../types";

export const getActivityChartData = (state: RootState) => {
  return state.activityVisualisation.chartData;
};

export const getIsLoading = (state: RootState) => {
  return state.activityVisualisation.isLoading;
};

export const getTitle = (state: RootState) => {
  return state.activityVisualisation.title;
};
