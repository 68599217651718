import { InformationRequestBody, ProjectInfo } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = InformationRequestBody;

type ResponseData = { data: ProjectInfo[] };

export const getProjectListInfo: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/ListWithFilters?api-version=1.0`,
  method: "POST",
  data,
});
