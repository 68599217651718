import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  getEsgianTheme,
  MenuItem,
  Select,
  Typography,
} from "@esgian/esgianui";
import {
  Eventcalendar,
  MbscCalendarEvent,
  MbscEventcalendarView,
  MbscResource,
  Popup,
} from "@mobiscroll/react";
import html2canvas from "html2canvas";
import moment from "moment";

import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { Legend, ProjectDrawer } from "../../components";
import { InstallationDrawer } from "../../components/Drawer/Installation";
import { Loading } from "../../components/Loading";
import { calculateDurationByDaysAndHours } from "../../helper/chart";
import { exportToCSV } from "../../helper/exportCsv";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getActivityChartData,
  getIsLoading,
  getTitle,
} from "../../store/selector/activityVisualisation";
import {
  getSelectedInstallationId,
  getSelectedProjectId,
  getThemeMode,
} from "../../store/selector/common";
import { getImoMapping } from "../../store/selector/installation";
import { fetchActivityList } from "../../store/slice/activityVisualisation";
import {
  setSearchObjectCoordinates,
  setSearchText,
  setSelectedInstallationId,
  setSelectedProjectId,
} from "../../store/slice/common";
import { fetchVesselImo } from "../../store/slice/installation";
import {
  ActivityTypeEnum,
  GenericType,
  Resource,
  ROUTES_CONFIG,
  THEME,
  ThemeModeEnum,
  ViewType,
} from "../../types";

import "@mobiscroll/react/dist/css/mobiscroll.min.css";

const statusColor: GenericType = {
  foundation: "rgb(145, 51, 200)",
  maintenance: "rgb(214, 236, 11)",
  turbine: "rgb(82, 202, 238)",
  other: "rgb(0, 70, 179)",
  standBy: "rgb(117, 223, 115)",
  inPort: "rgb(66, 107, 46)",
  transit: "rgb(254, 81, 91)",
};

const resolution = {
  year: "month",
  month: "day",
  week: "day",
  day: "day",
};

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    background: {
      background:
        themeMode === ThemeModeEnum.Light
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
    topBtnBox: {
      position: "absolute",
      top: "16px",
      right: "8px",
    },
    topBtn: {
      mr: 2,
      color:
        themeMode === ThemeModeEnum.Dark
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
  };
};

export const ActivityVisualisation: FC = () => {
  const timerRef = useRef<GenericType | null>(null);
  const captureRef = useRef();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const [viewType, setViewType] = useState<ViewType>("year");
  const [selectedItem, setSelectedItem] = useState<GenericType | null>(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().subtract(1, "year"),
  );
  const title = useSelector(getTitle);
  const isLoading = useSelector(getIsLoading);
  const themeMode = useSelector(getThemeMode);
  const chartData = useSelector(getActivityChartData);
  const classes = useStyles(themeMode);
  const imoMapping = useSelector(getImoMapping);
  const selectedInstallationId = useSelector(getSelectedInstallationId);
  const selectedProjectId = useSelector(getSelectedProjectId);
  const { type, id: itemId } = useParams();

  const myView = useMemo<MbscEventcalendarView>(
    () => ({
      timeline: {
        size: 2,
        resolutionHorizontal: resolution[viewType] as ViewType,
        type: viewType,
      },
    }),
    [viewType],
  );

  const resource: MbscResource[] = useMemo(() => {
    let list: MbscResource[] = [];
    if (type === "vessel") {
      const windFarmMap = new Map();

      chartData.forEach((vessel) => {
        vessel.orderedStatusList.forEach((status: GenericType) => {
          status.activities.forEach((activity: GenericType) => {
            const windFarm = activity.windFarm;
            if (windFarm && !windFarmMap.has(windFarm.windFarmId)) {
              windFarmMap.set(windFarm.windFarmId, {
                id: windFarm.windFarmId,
                name: windFarm.farmName,
              });
            }
          });
        });
      });
      list = [
        {
          id: "all_activities",
          name: "All activities",
          children: Array.from(windFarmMap.values()),
          collapsed: true,
        },
      ];
    }

    if (type === "project") {
      list = chartData.map((item: GenericType) => ({
        id: item.id,
        name: item.vesselName,
      }));
    }

    return list;
  }, [chartData, type]);

  const events: MbscCalendarEvent[] = useMemo(() => {
    let list: MbscCalendarEvent[] = [];
    if (type === "project") {
      const ids = new Set();
      list = chartData.reduce((acc: GenericType[], vessel: GenericType) => {
        vessel.orderedStatusList.forEach((status: GenericType) => {
          status.activities.forEach((activity: GenericType) => {
            const baseId = `${activity.id}_${activity.startDate}_${activity.endDate}_${activity.type}_${vessel.id}`;
            if (!ids.has(baseId)) {
              ids.add(baseId);
              acc.push({
                id: baseId,
                resource: vessel.id,
                start: activity.startDate,
                end: activity.endDate,
                type: activity.type,
                name: vessel.vesselName,
                duration: calculateDurationByDaysAndHours(
                  activity.startDate,
                  activity.endDate,
                ),
              });
            }
          });
        });
        return acc;
      }, []);
    }
    if (type === "vessel") {
      const activityMap = new Map();

      chartData.forEach((vessel) => {
        vessel.orderedStatusList.forEach((status: GenericType) => {
          status.activities.forEach((activity: GenericType) => {
            if (!activityMap.has(activity.id)) {
              const baseId = `${activity.id}_${activity.startDate}_${activity.endDate}_${activity?.windFarm?.windFarmId}`;
              // Prepare the basic activity object
              const activityObject = {
                id: baseId,
                resource: activity?.windFarm?.windFarmId,
                start: activity.startDate,
                end: activity.endDate,
                type: activity.type,
                name: vessel?.windFarm?.farmName,
                duration: calculateDurationByDaysAndHours(
                  activity.startDate,
                  activity.endDate,
                ),
              };

              activityMap.set(activity.id, activityObject);
              const allActivitiesObject = {
                ...activityObject,
                resource: "all_activities",
              };

              activityMap.set(`${activity.id}_all`, allActivitiesObject);
            }
          });
        });
      });

      list = Array.from(activityMap.values());
    }

    const filtered = list.filter(
      (item: MbscCalendarEvent) =>
        item.resource === "all_activities" ||
        !["standBy", "inPort", "transit"].includes(item.type),
    );
    return filtered as MbscCalendarEvent[];
  }, [chartData, type]);

  const handleEventHoverIn = useCallback((args: GenericType) => {
    setSelectedItem(args.event);
    setAnchor(args.domEvent.target);
    setOpen(true);
  }, []);

  const handleEventHoverOut = useCallback(() => {
    setOpen(false);
  }, []);

  const handleEventClick = useCallback(() => {
    setOpen(true);
  }, []);

  const onMouseEnter = useCallback(() => {
    if (timerRef.current) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      clearTimeout(timerRef.current as any);
    }
  }, []);

  useEffect(() => {
    const startDate = moment(selectedDate).startOf("year").format("YYYY-MM-DD");
    const endDate = moment(startDate)
      .add(1, "year")
      .endOf("year")
      .format("YYYY-MM-DD");

    let body = {};
    if (type === "project") {
      body = {
        nickname: "windVesselActivityByWindFarmTimeline",
        relatedWindFarmId: itemId,
        dateScale: "year",
        startDate,
        endDate,
      };
    }

    if (type === "vessel") {
      body = {
        filterList: [
          {
            type: "int",
            column: "id",
            valueList: [itemId],
          },
        ],
        nickname: "windVesselActivityTimeline",
        dateScale: "year",
        startDate,
        endDate,
      };
    }
    dispatch(fetchActivityList(body));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const exportCsv = () => {
    const resourceList =
      type === "project" ? resource : resource?.[0]?.children || [];
    const rows = events?.map((e) => ({
      name:
        e.resource === "all_activities"
          ? "ALL activities"
          : resourceList?.find((r) => r.id === e.resource)?.name,
      start: moment(e.start).format("DD-MM-YYYY HH:MM:SS"),
      end: moment(e.end).format("DD-MM-YYYY HH:MM:SS"),
      type: e.type,
    }));
    const mapping = {
      name: "Name",
      start: "Start Date",
      end: "End Date",
      type: "Type",
    };
    exportToCSV(rows, "activity_visualization.csv", mapping);
  };

  const handleCaptureClick = async () => {
    if (captureRef.current) {
      const canvas = await html2canvas(captureRef.current);
      const dataUrl = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "activity_visualization_screenshot.png";
      link.click();
    }
  };

  useEffect(() => {
    if (type === "project") {
      dispatch(fetchVesselImo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const renderResource = useCallback(
    (r: Resource) => {
      return (
        <Box
          sx={{
            color: "#0E9EFF",
            cursor: "pointer",
          }}
          onClick={() => {
            if (type === "project") {
              if (imoMapping?.length) {
                const imo = imoMapping?.find(
                  (v: GenericType) => v.bassoeId === r.id,
                )?.imo;
                if (imo) {
                  dispatch(setSelectedInstallationId(imo));
                }
              }
            }
            if (type === "vessel") {
              dispatch(setSelectedProjectId(r.id));
            }
          }}
        >
          <Typography>{r.name}</Typography>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resource, type, imoMapping],
  );
  const onLeaseRoundClick = (leaseRoundId: number) => {
    navigate(`${ROUTES_CONFIG.LeaseRoundTimeLineId.baseUrl}/${leaseRoundId}`);
  };

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box sx={classes.topBtnBox}>
        <Button
          onClick={exportCsv}
          variant="text"
          sx={classes.topBtn}
          startIcon={<DownloadIcon />}
        >
          DOWNLOAD CSV
        </Button>
        <Button
          onClick={handleCaptureClick}
          variant="text"
          sx={classes.topBtn}
          startIcon={<DownloadIcon />}
        >
          DOWNLOAD Image
        </Button>
      </Box>
      <Box ref={captureRef} sx={classes.background}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pl: 1,
          }}
        >
          <Typography variant="h5">{title}</Typography>
          <FormControl>
            <Select
              value={viewType}
              onChange={(event: GenericType) => setViewType(event.target.value)}
              sx={{
                viewSelect: {
                  width: 120,
                  padding: 1,
                  "& div": { p: 0 },
                  mr: 1,
                },
              }}
            >
              <MenuItem value="year">Year</MenuItem>
              <MenuItem value="month">Month</MenuItem>
              <MenuItem value="week">Week</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Legend
            radius="1px"
            title={ActivityTypeEnum.Foundation}
            color={statusColor.foundation}
            sx={{ ml: 1 }}
            toolTip="Installation of pin piles, jackets, monopiles, or gravity-based foundations offshore"
          />
          <Legend
            radius="1px"
            title={ActivityTypeEnum.Maintenance}
            color={statusColor.maintenance}
            sx={{ ml: 1 }}
            toolTip="Extensive maintenance performed offshore by a jack-up vessel"
          />
          <Legend
            radius="1px"
            title={ActivityTypeEnum.Turbine}
            color={statusColor.turbine}
            sx={{ ml: 1 }}
            toolTip="Installation of turbines, tower, nacelle, and / or blades"
          />
          <Legend
            radius="1px"
            title={ActivityTypeEnum.Other}
            color={statusColor.other}
            sx={{ ml: 1 }}
            toolTip="All offshore work not specifically mentioned"
          />
          <Legend
            radius="1px"
            title={ActivityTypeEnum.StandBy}
            color={statusColor.standBy}
            sx={{ ml: 1 }}
            toolTip="Vessel is outside the wind farm perimeter"
          />
          <Legend
            radius="1px"
            title={ActivityTypeEnum.InPort}
            color={statusColor.inPort}
            sx={{ ml: 1 }}
            toolTip="Vessel is docked in port"
          />
          <Legend
            radius="1px"
            title={ActivityTypeEnum.Transit}
            color={statusColor.transit}
            sx={{ ml: 1 }}
            toolTip="Vessel is moving from point A to point B"
          />
        </Box>
        <Box sx={{ overflow: "scroll" }}>
          {!isLoading ? (
            <Eventcalendar
              theme="material"
              themeVariant={themeMode}
              clickToCreate={false}
              dragToCreate={false}
              dragToMove={false}
              dragToResize={false}
              eventDelete={true}
              view={myView}
              data={events}
              refDate={selectedDate}
              resources={resource}
              showEventTooltip={false}
              selectedDate={selectedDate}
              onSelectedDateChange={(e: GenericType) => {
                setSelectedDate(e.date);
              }}
              onEventHoverIn={handleEventHoverIn}
              onEventHoverOut={handleEventHoverOut}
              onEventClick={handleEventClick}
              renderScheduleEvent={(item: GenericType) => (
                <Box
                  key={item.id}
                  sx={{
                    background: statusColor[item.original.type],
                    height: 24,
                    mt: 1,
                    borderRadius: "4px",
                    width: "100%",
                  }}
                />
              )}
              renderResource={renderResource}
            />
          ) : (
            <Loading />
          )}
          <Popup
            display="anchored"
            isOpen={isOpen}
            anchor={anchor || undefined}
            touchUi={false}
            showOverlay={false}
            contentPadding={false}
            closeOnOverlayClick={false}
            themeVariant={themeMode}
            width={280}
          >
            {selectedItem ? (
              <Box onMouseEnter={onMouseEnter} sx={{ p: 1 }}>
                {selectedItem?.country ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="string" sx={{ fontSize: 12 }}>
                      Country:
                    </Typography>
                    <Typography
                      variant="string"
                      sx={{ fontSize: 14, fontWeight: "bold" }}
                    >
                      {selectedItem.country}
                    </Typography>
                  </Box>
                ) : null}
                {selectedItem?.start ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="string" sx={{ fontSize: 12 }}>
                      Start date:
                    </Typography>
                    <Typography
                      variant="string"
                      sx={{ fontSize: 14, fontWeight: "bold" }}
                    >
                      {moment(selectedItem?.start).format("DD-MM-YYYY")}
                    </Typography>
                  </Box>
                ) : null}
                {selectedItem?.end ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="string" sx={{ fontSize: 12 }}>
                      End date:
                    </Typography>
                    <Typography
                      variant="string"
                      sx={{ fontSize: 14, fontWeight: "bold" }}
                    >
                      {moment(selectedItem?.end).format("DD-MM-YYYY")}
                    </Typography>
                  </Box>
                ) : null}
                {selectedItem?.duration ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="string" sx={{ fontSize: 12 }}>
                      Duration:
                    </Typography>
                    <Box>
                      {selectedItem?.duration.days ? (
                        <Typography
                          variant="string"
                          sx={{ fontSize: 14, fontWeight: "bold", mr: 1 }}
                        >
                          {selectedItem?.duration.days} days
                        </Typography>
                      ) : null}
                      <Typography
                        variant="string"
                        sx={{ fontSize: 14, fontWeight: "bold" }}
                      >
                        {selectedItem?.duration.hours} hours
                      </Typography>
                    </Box>
                  </Box>
                ) : null}
                {selectedItem?.contractAwardedDate ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="string" sx={{ fontSize: 12 }}>
                      Contract award date:
                    </Typography>
                    <Typography
                      variant="string"
                      sx={{ fontSize: 14, fontWeight: "bold" }}
                    >
                      {moment(selectedItem?.contractAwardedDate).format(
                        "DD-MM-YYYY",
                      )}
                    </Typography>
                  </Box>
                ) : null}
                {selectedItem?.turbineModel ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="string" sx={{ fontSize: 12 }}>
                      Turbine model:
                    </Typography>
                    <Typography
                      variant="string"
                      sx={{ fontSize: 14, fontWeight: "bold" }}
                    >
                      {selectedItem?.turbineModel}
                    </Typography>
                  </Box>
                ) : null}
                {selectedItem?.type ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="string" sx={{ fontSize: 12 }}>
                      Activity:
                    </Typography>
                    <Typography
                      variant="string"
                      sx={{ fontSize: 14, fontWeight: "bold" }}
                    >
                      {selectedItem?.type}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            ) : null}
          </Popup>
        </Box>
      </Box>
      <InstallationDrawer
        id={selectedInstallationId}
        onClose={() => dispatch(setSelectedInstallationId(null))}
      />
      <ProjectDrawer
        id={selectedProjectId}
        onClose={() => dispatch(setSelectedProjectId(null))}
        onLeaseRoundClick={onLeaseRoundClick}
      />
    </Box>
  );
};
