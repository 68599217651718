import { ProjectLookup } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = undefined;

type ResponseData = { data: ProjectLookup[] };

export const getProjects: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = () => ({
  url: `/WindProjects?api-version=1.0`,
  method: "GET",
});
