import { Option } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = undefined;

type ResponseData = { data: Option[] };

export const getFactoryStatus: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = () => ({
  url: `/FactoryStatus?api-version=1.0`,
  method: "GET",
});
