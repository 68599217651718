import { Map } from "mapbox-gl";

import { MapLayer, MapSource, ThemeModeEnum } from "../../../types";

export class Country {
  init(map: Map, themeMode: ThemeModeEnum) {
    const fillColor = themeMode === ThemeModeEnum.Light ? "#FF6347" : "white";
    map.addSource(MapSource.CountrySource, {
      type: "vector",
      url: "mapbox://mapbox.country-boundaries-v1",
    });

    map.addLayer({
      id: MapLayer.Country,
      type: "fill",
      source: MapSource.CountrySource,
      "source-layer": MapLayer.CountryBoundaries,
      paint: {
        "fill-color": fillColor,
        "fill-opacity": [
          "case",
          ["==", ["feature-state", "highlighted"], true],
          0.1,
          0,
        ],
      },
    });
  }

  disable(map: Map) {
    map.setLayoutProperty(MapLayer.Country, "visibility", "none");
  }

  visible(map: Map) {
    map.setLayoutProperty(MapLayer.Country, "visibility", "visible");
  }

  remove(map: Map) {
    if (map.getSource(MapSource.CountrySource)) {
      map.removeLayer(MapLayer.Country);
      map.removeSource(MapSource.CountrySource);
    }
  }

  resetState(map: Map) {
    map.getCanvas().style.cursor = "";
    map.removeFeatureState({
      source: MapSource.CountrySource,
      sourceLayer: MapLayer.CountryBoundaries,
    });
  }
}
