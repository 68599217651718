import { getCompanies } from "./getCompanies";
import { getLeaseRound } from "./getLeaseRound";
import { getLeaseRoundStatus } from "./getLeaseRoundStatus"
import { getLeaseRoundType } from "./getLeaseRoundType";
import { getProjectStatus } from "./getProjectStatus";
import { getProjectType } from "./getProjectType";
import { getProjects } from "./getProjects";
import { getTarget } from "./getTarget";

export const lookup = {
  getLeaseRoundStatus,
  getLeaseRoundType,
  getProjectStatus,
  getProjects,
  getLeaseRound,
  getProjectType,
  getCompanies,
  getTarget
};
