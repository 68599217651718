/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Table,
  Typography,
} from "@esgian/esgianui";

import {
  calculateFilterCount,
  filterDataBasedOnDynamicKeys,
  mapFilterData,
} from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getIsLookupLoading,
  getRowsPerPage,
  getSearchText,
  getThemeMode,
} from "../../store/selector/common";
import {
  getCountryListInfo,
  getLeaseListInfo,
  getLeaseRoundListInfo,
  getListViewIsLoading,
  getListViewSelectedTab,
  getProjectListInfoListInfo,
  getProjectSelectedFilter,
  getSelectedFilter,
  getSortingInfo,
  getVisibleColumns,
} from "../../store/selector/windLeasingAndProject";
import { setRowsPerPage } from "../../store/slice/common";
import {
  setSelectedCountryName,
  setSelectedLeaseAreaId,
  setSelectedLeaseRoundId,
  setSelectedProjectId,
} from "../../store/slice/common";
import {
  fetchCountryInfo,
  fetchLeaseRegionInfo,
  fetchLeaseRoundInfo,
  fetchProjectInfo,
  setListViewSelectedTab,
  setSortingInfo,
  setVisibleColumns,
} from "../../store/slice/windLeasingAndProject";
import {
  GenericType,
  IdNamePair,
  List,
  SelectedFilterIds,
  WindLeasingAndProjectTabEnum,
} from "../../types";
import { FilterBtn } from "../FilterBtn";
import { Loading } from "../Loading";

import { NotFound } from "./NotFound";

export const WindLeasingAndProjectList = () => {
  const dispatch = useDispatch();
  const rowsPerPage = useSelector(getRowsPerPage);
  const themeMode = useSelector(getThemeMode);
  const countryList = useSelector(getCountryListInfo);
  const leaseRegionList = useSelector(getLeaseListInfo);
  const projectList = useSelector(getProjectListInfoListInfo);
  const leaseRoundList = useSelector(getLeaseRoundListInfo);
  const isLoading = useSelector(getListViewIsLoading);
  const isLookupLoading = useSelector(getIsLookupLoading);
  const selectedFilter = useSelector(getSelectedFilter);
  const projectSelectedFilter = useSelector(getProjectSelectedFilter);
  const searchText = useSelector(getSearchText);
  const tab = useSelector(getListViewSelectedTab);
  const sortingInfo = useSelector(getSortingInfo);
  const visibleColumns = useSelector(getVisibleColumns);
  const [tableData, setTableData] = useState<List[] | [] | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setListViewSelectedTab(
        event.target.value as WindLeasingAndProjectTabEnum,
      ),
    );
  };

  const title = useMemo(() => {
    return tab[0].toUpperCase() + tab.substring(1).replace(/-/g, " ");
  }, [tab]);

  const fetchList = () => {
    if (!selectedFilter) return;
    const filter: SelectedFilterIds = {
      ...mapFilterData(selectedFilter),
    };
    switch (tab) {
      case WindLeasingAndProjectTabEnum.Country:
        dispatch(fetchCountryInfo(filter));
        break;
      case WindLeasingAndProjectTabEnum.LeaseArea:
        dispatch(fetchLeaseRegionInfo(filter));
        break;
      case WindLeasingAndProjectTabEnum.LeaseRound:
        dispatch(fetchLeaseRoundInfo(filter));
        break;
      case WindLeasingAndProjectTabEnum.Project:
        dispatch(fetchProjectInfo());
        break;
    }
  };

  const selectTabData = () => {
    let list: List[] | [] = [];
    switch (tab) {
      case WindLeasingAndProjectTabEnum.Country:
        list =
          countryList?.map((item) => ({ ...item, name: item.countryName })) ||
          [];
        break;
      case WindLeasingAndProjectTabEnum.LeaseArea:
        list = leaseRegionList || [];
        break;
      case WindLeasingAndProjectTabEnum.LeaseRound:
        list = leaseRoundList || [];
        break;
      case WindLeasingAndProjectTabEnum.Project:
        list = projectList
          ? filterDataBasedOnDynamicKeys(projectList, projectSelectedFilter)
          : [];
        break;
      default:
        return [];
    }

    if (searchText?.length && list?.length) {
      list = list?.filter((item) => isMatch(item, [searchText]));
    }

    setTableData(list);
  };

  useEffect(() => {
    dispatch(
      setSortingInfo({
        direction: "asc",
        name: tab === WindLeasingAndProjectTabEnum.Project ? "project" : "name",
      }),
    );
  }, [tab]);

  useEffect(() => {
    if (!isLookupLoading) {
      selectTabData();
    }
  }, [tab, isLoading, isLookupLoading, searchText, projectSelectedFilter]);

  useEffect(() => {
    fetchList();
  }, [tab, selectedFilter]);

  const LEASE_ROUND_COLUMNS = useMemo(
    () => [
      {
        label: "Lease round",
        name: "name",
        options: {
          setCellProps: () => ({ style: { cursor: "pointer" } }),
          customBodyRender: (value: IdNamePair) => value || "N/A",
        },
      },
      {
        label: "Lease area type",
        name: "leaseRoundType",
      },
      {
        label: "Lease round status",
        name: "leaseRoundStatus",
      },
      {
        label: "Country/region",
        name: "country",
      },
      {
        label: "Lease areas",
        name: "leaseAreas",
      },
      {
        label: "Target / awarded capacity (MW)",
        name: "targetCapacityMW",
      },
      {
        label: "Confirmed / Operational projects",
        name: "projectsConfirmed",
      },
      {
        label: "Number of bids",
        name: "bidsNumber",
      },
    ],
    [],
  );

  const LEASE_AREA_COLUMNS = useMemo(
    () => [
      {
        label: "Lease area",
        name: "name",
        options: {
          setCellProps: () => ({ style: { cursor: "pointer" } }),
          customBodyRender: (value: IdNamePair) => value || "N/A",
        },
      },
      {
        label: "Country/region",
        name: "country",
      },
      {
        label: "Sea region",
        name: "seaRegion",
      },
      {
        label: "Estimated capacity MW",
        name: "estimatedCapacityMW",
      },
      {
        label: "Lease round",
        name: "leaseRoundName",
      },
      {
        label: "Lease area type",
        name: "leaseRoundType",
      },
      {
        label: "Lease round status",
        name: "leaseRoundStatus",
      },
    ],
    [],
  );

  const COUNTRY_COLUMNS = useMemo(
    () => [
      {
        label: "Country",
        name: "name",
        options: {
          setCellProps: () => ({ style: { cursor: "pointer" } }),
          customBodyRender: (value: IdNamePair) => value || "N/A",
        },
      },
      {
        label: "Target by 2050 GW",
        name: "targetMW2050",
      },
      {
        label: "Installed capacity",
        name: "currentInstalledCapacityMW",
        options: {
          customBodyRender: (value: number) => {
            return `${value || 0} MW`;
          },
        },
      },
      {
        label: "Confirmed project capacity",
        name: "confirmedProjectCapacityMW",
        options: {
          customBodyRender: (value: number) => {
            return `${value || 0} MW`;
          },
        },
      },
      {
        label: "Scheduled lease round capacity",
        name: "scheduledLeaseRoundCapacityMW",
        options: {
          customBodyRender: (value: number) => {
            return `${value || 0} MW`;
          },
        },
      },
      {
        label: "Current lease rounds",
        name: "currentLeaseRounds",
      },
      {
        label: "Scheduled lease rounds",
        name: "scheduledLeaseRounds",
      },
      {
        label: "Completed lease rounds",
        name: "completedLeaseRounds",
      },
    ],
    [],
  );

  const PROJECT_COLUMNS = useMemo(
    () => [
      {
        label: "Project",
        name: "project",
        options: {
          setCellProps: () => ({ style: { cursor: "pointer" } }),
          customBodyRender: (value: IdNamePair) => value || "N/A",
        },
      },
      {
        label: "Country",
        name: "country",
      },
      {
        label: "Developers",
        name: "developers",
      },
      {
        label: "Project status",
        name: "projectStatus",
      },
      {
        label: "Project type",
        name: "projectType",
      },
      {
        label: "Capacity (MW)",
        name: "capacity",
      },
      {
        label: "Number of turbines",
        name: "numberOfTurbines",
      },
      {
        label: "Operation date",
        name: "operationDate",
      },
      {
        label: "Decommissioning date",
        name: "decommissioningDate",
      },
      {
        label: "Foundation installation start date",
        name: "foundationInstallationStartDate",
      },
      {
        label: "Turbine installation start date",
        name: "turbineInstallationStartDate",
      },
      {
        label: "Lease round",
        name: "leaseRound",
      },
      {
        label: "Maximum water depth (m)",
        name: "maximumWaterDepth",
      },
      {
        label: "Foundation installer",
        name: "foundationInstaller",
        options: { display: visibleColumns.includes("foundationInstaller") },
      },
      {
        label: "FID",
        name: "fid",
        options: { display: visibleColumns.includes("fid") },
      },
      {
        label: "PPA",
        name: "ppaDetails",
        options: { display: visibleColumns.includes("ppaDetails") },
      },
      {
        label: "Intertidal",
        name: "intertidal",
        options: { display: visibleColumns.includes("intertidal") },
      },
      {
        label: "Fresh water location",
        name: "freshWaterLocation",
        options: { display: visibleColumns.includes("freshWaterLocation") },
      },
      {
        label: "Distance to shore (km)",
        name: "distanceShore",
        options: { display: visibleColumns.includes("distanceShore") },
      },
      {
        label: "Area (km2)",
        name: "area",
        options: { display: visibleColumns.includes("area") },
      },
      {
        label: "Turbine supplier",
        name: "turbineSupplier",
        options: { display: visibleColumns.includes("turbineSupplier") },
      },
      {
        label: "Turbine model",
        name: "turbineModel",
        options: { display: visibleColumns.includes("turbineModel") },
      },
      {
        label: "Turbine nominal power (MW)",
        name: "turbineNominalPower",
        options: {
          display: visibleColumns.includes("turbineNominalPower"),
          sortCompare: (order: string) => {
            return (a: { data: string }, b: { data: string }) => {
              const aVal = parseFloat(a?.data?.split(", ")[0] || "0");
              const bVal = parseFloat(b?.data?.split(", ")[0] || "0");

              if (order === "asc") {
                if (aVal < bVal) return -1;
                if (aVal > bVal) return 1;
              } else if (order === "desc") {
                if (aVal < bVal) return 1;
                if (aVal > bVal) return -1;
              }

              return 0;
            };
          },
        },
      },
      {
        label: "Tip height (m)",
        name: "tipHeight",
        options: { display: visibleColumns.includes("tipHeight") },
      },
      {
        label: "Hub height (m)",
        name: "hubHeight",
        options: { display: visibleColumns.includes("hubHeight") },
      },
      {
        label: "Rotor diameter (m)",
        name: "rotorDiameter",
        options: { display: visibleColumns.includes("rotorDiameter") },
      },
      {
        label: "Foundation supplier",
        name: "foundationSupplier",
        options: { display: visibleColumns.includes("foundationSupplier") },
      },
      {
        label: "Foundation type",
        name: "foundationType",
        options: { display: visibleColumns.includes("foundationType") },
      },
      {
        label: "Main port name",
        name: "mainPortName",
        options: { display: visibleColumns.includes("mainPortName") },
      },
      {
        label: "Turbine installer",
        name: "turbineInstaller",
        options: { display: visibleColumns.includes("turbineInstaller") },
      },
      {
        label: "Cable installer",
        name: "cableInstaller",
        options: { display: visibleColumns.includes("cableInstaller") },
      },

      {
        label: "Floating material",
        name: "floatingMaterial",
        options: { display: visibleColumns.includes("floatingMaterial") },
      },
      {
        label: "Floating technology",
        name: "floatingTechnology",
        options: { display: visibleColumns.includes("floatingTechnology") },
      },
      {
        label: "Floater type",
        name: "floaterType",
        options: { display: visibleColumns.includes("floaterType") },
      },
      {
        label: "Anchor material",
        name: "anchorMaterial",
        options: { display: visibleColumns.includes("anchorMaterial") },
      },
      {
        label: "Total number of anchors",
        name: "totalNumberAnchors",
        options: { display: visibleColumns.includes("totalNumberAnchors") },
      },
      {
        label: "Mooring line type",
        name: "mooringLineType",
        options: { display: visibleColumns.includes("mooringLineType") },
      },
      {
        label: "Total number of mooring lines",
        name: "totalNumberMooringLines",
        options: {
          display: visibleColumns.includes("totalNumberMooringLines"),
        },
      },
      {
        label: "Mooring line length (m)",
        name: "mooringLineLength",
        options: { display: visibleColumns.includes("mooringLineLength") },
      },
      {
        label: "Displacement per unit",
        name: "displacementPerUnit",
        options: { display: visibleColumns.includes("displacementPerUnit") },
      },
      {
        label: "Turbines per floating unit",
        name: "turbinesPerFloatingUnit",
        options: {
          display: visibleColumns.includes("turbinesPerFloatingUnit"),
        },
      },
      {
        label: "Draught (m3)",
        name: "draught",
        options: { display: visibleColumns.includes("draught") },
      },
      {
        label: "Hull mass per unit (m)",
        name: "hullMassPerUnit",
        options: { display: visibleColumns.includes("hullMassPerUnit") },
      },
      {
        label: "Ballast mass per unit (m)",
        name: "ballastMassPerUnit",
        options: { display: visibleColumns.includes("ballastMassPerUnit") },
      },
      {
        label: "Hydrogen",
        name: "hydrogen",
        options: {
          options: { display: visibleColumns.includes("hydrogen") },
        },
      },
      {
        label: "Hydrogen project",
        name: "hydrogenProject",
        options: { display: visibleColumns.includes("hydrogenProject") },
      },
      {
        label: "Hydrogen developer",
        name: "hydrogenDeveloper",
        options: { display: visibleColumns.includes("hydrogenDeveloper") },
      },
      {
        label: "Hydrogen technology",
        name: "hydrogenTechnology",
        options: { display: visibleColumns.includes("hydrogenTechnology") },
      },
      {
        label: "Date online",
        name: "dateOnline",
        options: { display: visibleColumns.includes("dateOnline") },
      },
      {
        label: "Electrolysis method",
        name: "electrolysisMethod",
        options: { display: visibleColumns.includes("electrolysisMethod") },
      },
      {
        label: "Electrolyser supplier",
        name: "electrolyserSupplier",
        options: { display: visibleColumns.includes("electrolyserSupplier") },
      },
      {
        label: "Storage method",
        name: "storageMethod",
        options: { display: visibleColumns.includes("storageMethod") },
      },
      {
        label: "Distribution method",
        name: "distributionMethod",
        options: { display: visibleColumns.includes("distributionMethod") },
      },
      {
        label: "Oil and gas",
        name: "oilAndGas",
        options: { display: visibleColumns.includes("oilAndGas") },
      },
      {
        label: "Oil and gas fields",
        name: "oilGasFields",
        options: { display: visibleColumns.includes("oilGasFields") },
      },
      {
        label: "End of life",
        name: "endLife",
        options: { display: visibleColumns.includes("endLife") },
      },
      {
        label: "Oil and gas field ownership",
        name: "oilGasFieldOwnership",
        options: { display: visibleColumns.includes("oilGasFieldOwnership") },
      },
    ],
    [visibleColumns],
  );

  const COLUMNS: GenericType = {
    country: COUNTRY_COLUMNS,
    "lease-round": LEASE_ROUND_COLUMNS,
    "lease-area": LEASE_AREA_COLUMNS,
    project: PROJECT_COLUMNS,
  };

  return (
    <Box sx={{ height: "100%", position: "relative" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <FormControl component="fieldset" sx={{ px: 2 }} fullWidth={true}>
          <Grid
            container={true}
            alignItems="center"
            spacing={2}
            fullWidth={true}
          >
            <Grid item={true}>
              <FormLabel id="view-radio-buttons">View by</FormLabel>
            </Grid>
            <Grid item={true}>
              <RadioGroup
                row={true}
                aria-labelledby="view-radio-buttons"
                name="view-radio-buttons"
                value={tab}
                onChange={handleChange}
              >
                <FormControlLabel
                  value={WindLeasingAndProjectTabEnum.Project}
                  control={<Radio />}
                  label="Project"
                />
                <FormControlLabel
                  value={WindLeasingAndProjectTabEnum.Country}
                  control={<Radio />}
                  label="Country"
                />
                <FormControlLabel
                  value={WindLeasingAndProjectTabEnum.LeaseRound}
                  control={<Radio />}
                  label="Lease round"
                />
                <FormControlLabel
                  value={WindLeasingAndProjectTabEnum.LeaseArea}
                  control={<Radio />}
                  label="Lease area"
                />
              </RadioGroup>
            </Grid>
            <Grid
              item={true}
              style={{
                marginLeft: "auto",
              }}
            >
              <FilterBtn
                count={
                  tab === WindLeasingAndProjectTabEnum.Project
                    ? calculateFilterCount(projectSelectedFilter)
                    : calculateFilterCount(selectedFilter)
                }
              />
            </Grid>
          </Grid>
        </FormControl>
      </Box>
      {!isLoading ? (
        <Box sx={{ px: 2, maxWidth: "calc(100vw - 265px)" }}>
          <Typography variant="body1" sx={{ py: 2 }}>
            {`Displaying ${tableData?.length} ${title}`}
          </Typography>
          {tableData?.length ? (
            <Table
              app="wind"
              title={title}
              columnDefs={[]}
              columns={COLUMNS[tab]}
              data={tableData}
              elevation={0}
              mode={themeMode}
              options={{
                download: true,
                search: false,
                downloadOptions: {
                  filename: `${title} data.csv`,
                },
                expandableRows: false,
                filter: false,
                filterType: "dropdown",
                hideToolbarMargin: false,
                pagination: true,
                responsive: "vertical",
                rowsPerPage,
                onChangeRowsPerPage: (numberOfRows: number) => {
                  dispatch(setRowsPerPage(numberOfRows));
                },
                onRowClick: (_: number, rowMeta: GenericType) => {
                  const { dataIndex }: GenericType = rowMeta;
                  const item: GenericType = tableData[dataIndex];
                  switch (tab) {
                    case WindLeasingAndProjectTabEnum.Country:
                      dispatch(setSelectedCountryName(item.name));
                      break;
                    case WindLeasingAndProjectTabEnum.LeaseArea:
                      dispatch(setSelectedLeaseAreaId(item.id));
                      break;
                    case WindLeasingAndProjectTabEnum.LeaseRound:
                      dispatch(setSelectedLeaseRoundId(item.id));
                      break;
                    case WindLeasingAndProjectTabEnum.Project:
                      dispatch(setSelectedProjectId(item.id));
                      break;
                  }
                },
                rowsPerPageOptions: [25, 50, 100],
                selectableRows: "none",
                sortOrder: sortingInfo,
                onColumnSortChange: (name: string, direction: string) => {
                  dispatch(setSortingInfo({ name, direction }));
                },
                showResponsive: true,
                tableBodyHeight: "calc(100vh - 340px)",
                toolbar: true,
                print: false,
                viewColumns: true,
                sortThirdClickReset: true,
                onViewColumnsChange: (c: string | string[]) => {
                  dispatch(setVisibleColumns(c));
                },
              }}
            />
          ) : (
            <NotFound />
          )}
        </Box>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
