import { useEffect } from "react";
import { Box } from "@esgian/esgianui";

import { TurbineModelDrawer } from "../../components/Drawer/TurbineModelDrawer";
import { FilterModal } from "../../components/Modal/FilterModal";
import { TurbineModelsList } from "../../components/TurbineModelsList";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getFilterModal,
  getSelectedTurbineModelId,
} from "../../store/selector/common";
import {
  getSelectedFilter,
  getTurbineModelFilterOptions,
} from "../../store/selector/turbineModels";
import {
  setFilterModal,
  setSearchObjectCoordinates,
  setSearchText,
  setSelectedTurbineModelId,
} from "../../store/slice/common";
import { setSelectedFilter } from "../../store/slice/turbineModels";

export const TurbineModel = () => {
  const dispatch = useDispatch();

  const isFilterModalOpen = useSelector(getFilterModal);
  const options = useSelector(getTurbineModelFilterOptions);
  const selectedTurbineModelId = useSelector(getSelectedTurbineModelId);
  const selectedFilter = useSelector(getSelectedFilter);

  useEffect(() => {
    return () => {
      dispatch(setSelectedFilter({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      <TurbineModelDrawer
        id={selectedTurbineModelId}
        onClose={() => dispatch(setSelectedTurbineModelId(null))}
      />
      <TurbineModelsList />
      {options ? (
        <FilterModal
          open={isFilterModalOpen}
          handleClose={() => dispatch(setFilterModal(false))}
          onChange={(filterValues) => dispatch(setSelectedFilter(filterValues))}
          options={options}
          selectedFilter={selectedFilter}
        />
      ) : null}
    </Box>
  );
};
