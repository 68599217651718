import { FilterItemType, RootState } from "../../types";

export const getFloatingTechnologyLoading = (state: RootState) => {
  return state.floatingTechnologies.isLoading;
};

export const getFloatingTechnologyFilterOptions = (state: RootState) => {
  const filterOption = state.floatingTechnologies.filterOptions;
  return {
    company: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Company",
      options: filterOption?.companies || [],
    },
    technology: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Technology",
      options: filterOption?.technology || [],
    },
    floaterType: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Floater type",
      options: filterOption?.floaterType || [],
    },
    floaterMaterial: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Floater material",
      options: filterOption?.floaterMaterial || [],
    },
  };
};

export const getFloatingTechnologyList = (state: RootState) => {
  return state.floatingTechnologies.floatingTechnologyList;
};

export const getSelectedFilter = (state: RootState) => {
  return state.floatingTechnologies.selectedFilter;
};

export const getFloatingTechnologyChartData = (state: RootState) => {
  return state.floatingTechnologies.chartData;
};

export const getSortingInfo = (state: RootState) => {
  return state.floatingTechnologies.sortingInfo;
};
