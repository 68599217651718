/* eslint-disable indent */
import { FC, useCallback, useEffect, useState } from "react";
import {
  ArrowDropDownIcon,
  ArrowDropUpIcon,
  Box,
  Button,
  DateRangeMobi,
  Divider,
  FilterSlider,
  FormControl,
  getEsgianTheme,
  IconButton,
  Modal,
  Typography,
} from "@esgian/esgianui";
import moment from "moment";

import { useSelector } from "../../../hooks/use-selector";
import { getThemeMode } from "../../../store/selector/common";
import {
  FilterItemType,
  FilterParamOptionItem,
  FilterParamOptions,
  GenericType,
  Option,
  SelectedFilterItem,
  THEME,
  ThemeModeEnum,
} from "../../../types";
import { StylesSelect } from "../..";
import { StyledAutocomplete } from "../../StyledAutocomplete";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);

  return {
    container: {
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        p: 2,
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      },
    },
    bottomBox: {
      mt: "auto",
      py: 2,
      width: 1,
    },
    applyBtn: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    filterSlide: {
      mt: 3,
    },
    groupContainer: { border: "1px solid", borderRadius: 1, mb: 2, p: 1 },
    groupHeader: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "space-between",
    },
    iconBtn: {
      p: 0,
      pr: "2px",
    },
    arrowIcon: {
      color:
        themeMode === ThemeModeEnum.Dark
          ? theme.palette.common.white
          : theme.palette.common.black,
      fontSize: "20px",
    },
    filterDate: {
      display: "flex",
      flexDirection: "column",
      mt: 3,
    },
  };
};

type Prop = {
  open: boolean;
  options?: FilterParamOptions;
  groupsOption?: GenericType;
  selectedFilter?: GenericType;
  initialFilter?: GenericType;
  onChange: (v: GenericType) => void;
  handleClose: () => void;
};

export const FilterModal: FC<Prop> = ({
  open,
  options,
  groupsOption,
  selectedFilter,
  initialFilter = {},
  onChange,
  handleClose,
}) => {
  const themeMode = useSelector(getThemeMode);
  const [currentFilter, setCurrentFilter] = useState<GenericType>(
    selectedFilter || {},
  );
  const [isOpenArray, setIsOpenArray] = useState<string[]>([]);

  const classes = useStyles(themeMode);

  const filterItem = useCallback(
    (item: FilterParamOptionItem, key: string) => {
      const itemOptions = item.options;
      if (item.type === FilterItemType.Autocomplete) {
        return (
          <FormControl key={key} fullWidth={true} sx={{ mt: 3 }}>
            <StyledAutocomplete
              disableClearable={true}
              options={itemOptions as Option[]}
              selectedItem={currentFilter[key] || []}
              setSelectedItem={(value) =>
                setCurrentFilter({
                  ...currentFilter,
                  [key]: value as SelectedFilterItem[],
                })
              }
              labelKey={item?.labelKey || ""}
              groupBy={item?.groupBy}
              inputLabelText={item?.inputLabelText}
              maxHeight={item?.maxHeight}
            />
          </FormControl>
        );
      }
      if (item.type === FilterItemType.MultiSelect) {
        return (
          <FormControl fullWidth={true} sx={{ mt: 3 }} key={key}>
            <StylesSelect
              options={itemOptions as Option[]}
              selectedItem={currentFilter[key] || []}
              setSelectedItem={(value) => {
                setCurrentFilter({ ...currentFilter, [key]: value });
              }}
              labelKey={item?.labelKey || ""}
              inputLabelText={item?.inputLabelText || ""}
              valueKey="id"
            />
          </FormControl>
        );
      }
      if (item.type === FilterItemType.Slider) {
        return (
          <Box sx={classes.filterSlide} key={key}>
            <FilterSlider
              sliderWidth="92%"
              key={item}
              marks={[
                {
                  label: itemOptions[0],
                  value: itemOptions[0],
                },
                {
                  label: itemOptions[1],
                  value: itemOptions[1],
                },
              ]}
              min={itemOptions[0]}
              max={itemOptions[1]}
              disableMarkRotation={true}
              onChange={(value: [number, number]) => {
                setCurrentFilter((prevFilter) => ({
                  ...prevFilter,
                  [key]: value,
                }));
              }}
              title={item?.inputLabelText}
              value={currentFilter[key] || itemOptions}
            />
          </Box>
        );
      }
      if (item.type === FilterItemType.Date) {
        return (
          <Box sx={classes.filterDate}>
            <DateRangeMobi
              startDate={currentFilter?.[key]?.[0]}
              endDate={currentFilter?.[key]?.[1]}
              setEndDate={currentFilter?.[key]?.[0]}
              setStartDate={currentFilter?.[key]?.[1]}
              minStartDate={item.options?.[0]}
              maxDate={item.options?.[1]}
              dateFormat={item.format}
              placeholder={item.inputLabelText}
              onChange={(e: GenericType) => {
                setCurrentFilter({
                  ...currentFilter,
                  [key]: e.length ? e : null,
                });
              }}
              onClose={(e: GenericType) => {
                if (
                  e?.value[0]?.format(item.format) &&
                  e?.value[1]?.format(item.format)
                ) {
                  let endDate = e?.value[1]?.format(item.format);
                  if (item.format === "YYYY") {
                    endDate = moment(e?.value[1])
                      .endOf("year")
                      .format("MMM-YYYY");
                  }
                  const values = [e?.value[0]?.format(item.format), endDate];
                  setCurrentFilter({
                    ...currentFilter,
                    [key]: values,
                  });
                } else {
                  setCurrentFilter({
                    ...currentFilter,
                    [key]: null,
                  });
                }
              }}
            />
          </Box>
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFilter],
  );

  const handleApplyFilter = () => {
    onChange(currentFilter);
    handleClose();
  };

  const handleResetFilter = () => {
    setCurrentFilter(initialFilter);
    onChange(initialFilter);
    handleClose();
  };

  const handleDropDownClick = (key: string) => {
    if (!isOpenArray.includes(key)) {
      setIsOpenArray([...isOpenArray, key]);
    } else {
      setIsOpenArray(isOpenArray.filter((i: string) => i !== key));
    }
  };

  useEffect(() => {
    if (selectedFilter) {
      setCurrentFilter(selectedFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter, open]);

  useEffect(() => {
    if (!open) {
      setCurrentFilter({});
    }
  }, [open]);

  return (
    <Modal
      sx={classes.container}
      open={open}
      size="sm"
      title="Filter"
      handleClose={handleClose}
    >
      {options
        ? Object.keys(options).map((key) => filterItem(options[key], key))
        : null}
      {groupsOption
        ? Object.keys(groupsOption).map((key) => (
            <Box key={key} sx={classes.groupContainer}>
              <Box
                onClick={() => handleDropDownClick(key)}
                sx={{
                  ...classes.groupHeader,
                  borderBottom: isOpenArray.includes(key) ? "1px solid" : null,
                  pb: isOpenArray.includes(key) ? 1 : 0,
                }}
              >
                <Typography variant="subtitle1">
                  {key
                    .split(/(?=[A-Z])/)
                    .join(" ")
                    .toLocaleLowerCase()
                    .replace(/^./, (firstLetter) => firstLetter.toUpperCase())}
                </Typography>
                <IconButton sx={classes.iconBtn}>
                  {!isOpenArray.includes(key) ? (
                    <ArrowDropDownIcon sx={classes.arrowIcon} />
                  ) : (
                    <ArrowDropUpIcon sx={classes.arrowIcon} />
                  )}
                </IconButton>
              </Box>
              {isOpenArray.includes(key) ? (
                <Box>
                  {Object.keys(groupsOption[key]).map((subKey) =>
                    filterItem(groupsOption[key][subKey], subKey),
                  )}
                </Box>
              ) : null}
            </Box>
          ))
        : null}
      <Box sx={classes.bottomBox}>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="text" color="inherit" onClick={handleResetFilter}>
            Reset All
          </Button>
          <Button
            color="inherit"
            sx={classes.applyBtn}
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
