import { api } from "../api/api";
import { getAuthToken } from "../helper/cookies";
import { getIsAuthenticated, getUser } from "../store/selector/common";
import { setIsAuthenticated, setUser } from "../store/slice/common";
import { AuthorizeUser } from "../types";

import { useDispatch } from "./use-dispatch";
import { useSelector } from "./use-selector";
import {
  initializeAppInsights,
  updateTelemetryInitializer,
} from "./useAppInsights";

export const useAuth = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const user = useSelector(getUser);

  const verifyToken = async (userToken: string) => {
    try {
      const resp = await api.auth.getProfile(userToken);
      const {
        data: {
          profile: { permissionList },
        },
      } = resp;
      const result = permissionList.find(
        (item: string) => item === "WIND_premiumAccount",
      );
      if (!result) {
        throw new Error("User doesn't have permission");
      }
      return { isValid: true, user: resp };
    } catch (error) {
      return { isValid: false, user: null };
    }
  };

  const authorizeUser = async () => {
    dispatch(setIsAuthenticated(AuthorizeUser.IsLoading));
    if (process.env.REACT_APP_ENV === "LOCAL") {
      // Local environment
      dispatch(setIsAuthenticated(AuthorizeUser.Authorized));
    } else {
      const userToken = getAuthToken();
      if (userToken) {
        try {
          const { isValid, user: respUser } = await verifyToken(userToken);
          if (isValid) {
            initializeAppInsights();
            updateTelemetryInitializer(respUser.data);
            dispatch(setUser(respUser.data));
            dispatch(setIsAuthenticated(AuthorizeUser.Authorized));
          } else {
            dispatch(setIsAuthenticated(AuthorizeUser.NonAuthorized));
          }
        } catch (error) {
          dispatch(setIsAuthenticated(AuthorizeUser.NonAuthorized));
        }
      } else {
        dispatch(setIsAuthenticated(AuthorizeUser.NonAuthorized));
      }
    }
  };

  return {
    authorizeUser,
    isAuthenticated,
    user,
  };
};
