import { CancelTokenSource } from "axios";

import { Project } from "../../types";
import { ApiMethodDeclaration } from "../types";
type ParamsData = {
  input: { polygon: string };
  cancelToken: CancelTokenSource;
};

type ResponseData = { data: Project[] };

export const getPolygon: ApiMethodDeclaration<ParamsData, ResponseData> = (
  data,
) => ({
  url: `/Region/PolygonRegions?api-version=1.0`,
  method: "POST",
  data: data.input,
  cancelToken: data.cancelToken.token,
});
