import { FC } from "react";
import { useSelector } from "react-redux";
import { Divider, getEsgianTheme } from "@esgian/esgianui";

import { getThemeMode } from "../../../store/selector/common";
import { GenericType, THEME, ThemeModeEnum } from "../../../types";
import DropDownList from "../DropDownList";

type Prop = {
  properties: GenericType;
};

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
    loadingContainer: {
      height: "calc(100vh - 66px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
};

const VesselDropdownList: FC<Prop> = ({ properties }) => {
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);

  return (
    <>
      {Object.hasOwn(properties, "cable") && properties.cable && (
        <>
          <Divider sx={classes.divider} />
          <DropDownList
            title="Cable lay contracts"
            subTitleKey="title"
            data={properties.cableData}
            defaultExpand={false}
            properties={{
              awardDate: { key: "Contract award date", type: "date" },
              vesselName: { key: "Vessel name" },
              vesselOwner: { key: "Vessel owner" },
              startDate: { key: "Start date", type: "date" },
              endDate: { key: "End date", type: "date" },
              cableType: { key: "Cable type" },
            }}
          />
        </>
      )}

      {Object.hasOwn(properties, "floating") && properties.floating && (
        <>
          <Divider sx={classes.divider} />
          <DropDownList
            title="Floating installation contracts"
            subTitleKey="title"
            data={properties.floatingData}
            defaultExpand={false}
            properties={{
              awardDate: { key: "Contract award date", type: "date" },
              vesselName: { key: "Vessel name" },
              vesselOwner: { key: "Vessel owner" },
              startDate: { key: "Start date", type: "date" },
              endDate: { key: "End date", type: "date" },
              floatingTechnology: { key: "Floating technology" },
            }}
          />
        </>
      )}

      {Object.hasOwn(properties, "installation") && properties.installation && (
        <>
          <Divider sx={classes.divider} />
          <DropDownList
            title="Installation contracts"
            subTitleKey="title"
            data={properties.installationData}
            defaultExpand={false}
            properties={{
              awardDate: { key: "Contract award date", type: "date" },
              vesselName: { key: "Vessel name" },
              vesselOwner: { key: "Vessel owner" },
              startDate: { key: "Start date", type: "date" },
              endDate: { key: "End date", type: "date" },
              workType: { key: "Work type" },
              foundationType: { key: "Foundation type" },
              turbineModel: { key: "Turbine model" },
            }}
          />
        </>
      )}
    </>
  );
};

export default VesselDropdownList;
