import { ApiMethodDeclaration } from "../types";

type ParamsData = string;

type ResponseData = undefined;

export const deleteToken: ApiMethodDeclaration<ParamsData, ResponseData> = (
  token: string,
) => ({
  url: `/logout?token=${token}`,
  method: "Delete",
});
