import { VesselInformation } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = { vesselTypeIds: number[]; assetIds: number[] };

type ResponseData = { data: VesselInformation[] };

export const getRegion: ApiMethodDeclaration<ParamsData, ResponseData> = (
  data,
) => ({
  url: `/Region/RegionDataByFilters?api-version=1.0`,
  method: "POST",
  data,
});
