import { FC, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  Box,
  Divider,
  getEsgianTheme,
  HelpOutlineIcon,
  ToolTip,
  Typography,
} from "@esgian/esgianui";
import moment from "moment";

import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import {
  GenericType,
  SupplyDemandData,
  SupplyDemandSeriesEnum,
  THEME,
  ThemeModeEnum,
} from "../../types";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    Chart: {
      width: "99.9%",
      "& .tooltip": {
        py: 1,
        px: 2,
        "& .tooltip-title": {
          display: "flex",
          "& .tooltip-title_subitem": {
            marginLeft: 4,
          },
        },
        "& .tooltip-subtitle": {
          mr: 1,
        },
      },
      "& .apexcharts-menu": {
        background:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        "& .apexcharts-menu-item": {
          ":hover": {
            color: theme.palette.common.black,
          },
        },
      },
    },
  };
};

type Prop = {
  data: SupplyDemandData[] | null;
  categories: string[];
};

export const SupplyDemandChart: FC<Prop> = ({ data, categories }) => {
  const themeMode = useSelector(getThemeMode);
  const theme = getEsgianTheme(themeMode, THEME);
  const classes = useStyles(themeMode);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<GenericType | null>(
    null,
  );
  const [selectedItem, setSelectedItem] = useState<GenericType | null>(null);
  const [selectedSeriesType, setSelectedSeriesType] =
    useState<SupplyDemandSeriesEnum | null>(null);

  const options: GenericType = {
    chart: {
      stacked: true,
      zoom: {
        enabled: false,
      },
      background:
        themeMode === ThemeModeEnum.Dark && theme.palette.common.black,
      events: {
        click: (e: GenericType) => {
          const rel = e.target.getAttribute("selected");
          if (!rel) {
            setTooltipVisible(false);
            setTooltipPosition(null);
            setSelectedItem(null);
            setSelectedSeriesType(null);
          }
        },
        dataPointSelection: (
          e: GenericType,
          g: GenericType,
          config: GenericType,
        ) => {
          const key =
            `series_${config.seriesIndex}` as keyof typeof SupplyDemandSeriesEnum;

          const dateMoment = moment(
            categories[config.dataPointIndex],
            "MMM YYYY",
          );
          const item = data?.find((d) =>
            moment(d.date, "YYYY-MM")
              .startOf("month")
              .isSame(dateMoment.startOf("month")),
          );

          if (key && item) {
            const seriesKey = SupplyDemandSeriesEnum[key];
            const seriesData = item[seriesKey];
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            let x = e.x;
            let y = e.y;
            if (x + 550 > viewportWidth) {
              x -= 570;
            }
            if (y + 120 + seriesData.length * 34 > viewportHeight) {
              const tmp = seriesData.length * 34;
              y = y - tmp;
            }

            setTooltipVisible(true);
            setSelectedItem({
              date: item.date,
              value: seriesData.length,
              list: seriesData,
            });
            setTooltipPosition({
              x: x - 256,
              y: y - 100,
            });
            setSelectedSeriesType(seriesKey);
          }
        },
      },
    },
    xaxis: {
      categories,
    },
    yaxis: {
      labels: {
        style: {
          colors:
            themeMode === ThemeModeEnum.Dark
              ? theme.palette.common.white
              : theme.palette.common.black,
        },
      },
    },
    labels: categories,
    plotOptions: {
      bar: {
        columnWidth: categories.length > 75 ? 10 : 20,
      },
    },
    stroke: {
      width: 1,
      colors: [
        themeMode === ThemeModeEnum.Dark
          ? theme.palette.common.black
          : theme.palette.common.white,
      ],
    },
    markers: {
      size: [7, 8],
      strokeWidth: 0,
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      labels: {
        colors:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.white
            : theme.palette.common.black,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  const title = useMemo(() => {
    if (selectedSeriesType === SupplyDemandSeriesEnum.series_0) {
      return "Contracted demand";
    }
    if (selectedSeriesType === SupplyDemandSeriesEnum.series_1) {
      return "Confirmed";
    }
    if (selectedSeriesType === SupplyDemandSeriesEnum.series_2) {
      return "Proposed";
    }
    if (selectedSeriesType === SupplyDemandSeriesEnum.series_3) {
      return "Supply";
    }
  }, [selectedSeriesType]);

  const findSeriesData = <K extends keyof SupplyDemandData>(
    dateList: string[],
    items: SupplyDemandData[],
    key: K,
  ): (number | null)[] => {
    if (items.length) {
      return dateList?.map((dateStr) => {
        const dateMoment = moment(dateStr, "MMM YYYY");
        const item = items?.find((d) =>
          moment(d.date, "YYYY-MM")
            .startOf("month")
            .isSame(dateMoment.startOf("month")),
        );
        return item ? (item[key] as number) : null;
      });
    }
    return [];
  };

  const series = useMemo(() => {
    return [
      {
        name: "Contracted demand",
        type: "bar",
        color: "rgb(143, 187, 255)",
        data: data?.length
          ? findSeriesData(categories, data, "contractedDemandCount")
          : [],
      },
      {
        name: "Confirmed",
        type: "bar",
        color: "rgb(102, 232, 190)",
        data: data ? findSeriesData(categories, data, "confirmedCount") : [],
      },
      {
        name: "Proposed",
        type: "bar",
        color: "rgb(153, 239, 211)",
        data: data ? findSeriesData(categories, data, "proposedCount") : [],
      },
      {
        name: "Supply",
        type: "line",
        color: "rgb(111, 44, 193)",
        data: data ? findSeriesData(categories, data, "supplyCount") : [],
      },
    ];
  }, [data, categories]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={classes.Chart}>
        <ReactApexChart
          type="line"
          options={options}
          series={series}
          height={860}
        />
      </Box>
      <ToolTip
        sx={{ position: "absolute", left: 220, top: -76 }}
        title="The supply / demand balance is for turbine installation vessels. Projects in China and vessels flagged as Chinese are not included in the balance. For demand, we include any planned wind farm projects that developers provide an operational date for. We do not include floating wind farms and those that we know have a maximum water depth of less than 15 metres, neither of which will require jack up or heavy lift vessel to install turbines. For supply, we include confirmed new build vessels. The turbine size filter further segregates the balance by incorporating our view on the maximum turbine size capabilities of vessels and the turbine sizes due to be installed on future wind farms."
      >
        <HelpOutlineIcon />
      </ToolTip>
      {tooltipVisible && selectedItem && (
        <Box
          sx={{
            position: "absolute",
            left: tooltipPosition?.x,
            top: tooltipPosition?.y,
            background:
              themeMode === ThemeModeEnum.Dark
                ? theme.palette.common.black
                : theme.palette.common.white,
            color:
              themeMode === ThemeModeEnum.Dark
                ? theme.palette.common.white
                : theme.palette.common.black,
            fontSize: 12,
            borderRadius: 2,
          }}
        >
          <Box sx={{ px: 3, py: 2 }}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              {moment(selectedItem.date).format("MMM YYYY")}
            </Typography>
            <Box sx={{ display: "flex" }}>
              <Typography variant="subtitle2" sx={{ mr: 1 }}>
                {title}:
              </Typography>
              <Typography variant="subtitle2">
                {selectedItem.value} vessels
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{ px: 2, py: 1, width: 550, maxHeight: 400, overflow: "auto" }}
          >
            <Box
              sx={{
                display: "flex",
                borderBottom: 1,
                fontWeight: 600,
                pb: 1,
              }}
            >
              {selectedSeriesType === SupplyDemandSeriesEnum.series_3 ? (
                <>
                  <Box sx={{ width: "33%" }}>Vessel name</Box>
                  <Box sx={{ width: "33%" }}>Owner</Box>
                  <Box sx={{ width: "33%" }}>Max capability</Box>
                </>
              ) : (
                <>
                  <Box sx={{ width: "25%" }}>Wind farm name</Box>
                  <Box sx={{ width: "25%" }}>Country</Box>
                  <Box sx={{ width: "25%" }}>Turbines</Box>
                  <Box sx={{ width: "25%" }}>Model</Box>
                </>
              )}
            </Box>
            {selectedItem.list.map((item: GenericType, i: number) => (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  py: 1,
                }}
              >
                {selectedSeriesType === SupplyDemandSeriesEnum.series_3 ? (
                  <>
                    <Box sx={{ width: "33%" }}>{item?.vesselName}</Box>
                    <Box sx={{ width: "33%" }}>
                      {item?.owners?.map((o: GenericType) => o.companyName)}
                    </Box>
                    <Box sx={{ width: "33%" }}>
                      {item?.maxTurbineCapabilityMw}
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ width: "25%" }}>{item?.windFarmName}</Box>
                    <Box sx={{ width: "25%" }}>{item?.country}</Box>
                    <Box sx={{ width: "15%" }}>{item?.numTurbines}</Box>
                    <Box sx={{ width: "35%" }}>{item?.models}</Box>
                  </>
                )}
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};
