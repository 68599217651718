import { GenericType } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = GenericType;

type ResponseData = { data: GenericType[] };

export const getNewsFeed: ApiMethodDeclaration<ParamsData, ResponseData> = (
  data,
) => ({
  url: `/Content/ByFilters?api-version=1.0`,
  method: "Post",
  data,
});
