import { FC } from "react";
import {
  Box,
  Button,
  FormControl,
  getEsgianTheme,
  MenuItem,
  Select,
  Typography,
  VisibilityIcon,
} from "@esgian/esgianui";

import { ReactComponent as DownloadIcon } from "../../assets/download.svg";
import { ReactComponent as FoldIcon } from "../../assets/fold.svg";
import { ReactComponent as ShareIcon } from "../../assets/share.svg";
import { ReactComponent as UnfoldIcon } from "../../assets/unfold.svg";
import { calculateFilterCount } from "../../helper/fllter";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import {
  getLeaseRoundTotalCount,
  getSelectedFilter,
} from "../../store/selector/leaseRoundTimeline";
import { setCollapsedAllResource } from "../../store/slice/leaseRoundTimeline";
import { GenericType, THEME, ThemeModeEnum } from "../../types";
import { FilterBtn } from "../FilterBtn";
import { Legend } from "../Legend";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    box: { mx: 2, fontFamily: "Roboto" },
    topBtnBox: {
      position: "absolute",
      top: "16px",
      right: "8px",
    },
    topBtn: {
      mr: 2,
      color:
        themeMode === ThemeModeEnum.Dark
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
    title: { fontSize: 12 },
    legendBox: { display: "flex", justifyContent: "space-between" },
    legendContainer: { my: 2, fontFamily: "Noto Sans" },
    fontSize10: { fontSize: 10 },
    displayFlex: { display: "flex" },
    legend: { ml: 1 },
    btnContainer: { display: "flex", height: 36 },
    btnUnfold: {
      color:
        themeMode === ThemeModeEnum.Dark
          ? theme.palette.common.white
          : theme.palette.common.black,
      "& path": {
        fill:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.white
            : theme.palette.common.black,
      },
    },
    viewTitle: {
      display: "flex",
      mr: 2,
      alignItems: "center",
      "& svg": { mr: 1 },
    },
    viewSelect: { width: 120, padding: 1, "& div": { p: 0 }, mr: 1 },
  };
};

type Prop = {
  setViewType: (value: "year" | "month") => void;
  setCollapsedAll: (value: boolean) => void;
  collapsedAll: boolean;
  viewType: "year" | "month";
  exportCsv: () => void;
  disableFilter: boolean;
};

export const LeaseRoundHeader: FC<Prop> = ({
  setViewType,
  setCollapsedAll,
  collapsedAll,
  viewType,
  exportCsv,
  disableFilter,
}) => {
  const dispatch = useDispatch();
  const leaseRoundTotalCount = useSelector(getLeaseRoundTotalCount);
  const selectedFilter = useSelector(getSelectedFilter);
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);

  const copyUrlToClipboard = () => {
    const url = window.location.href;

    navigator.clipboard.writeText(url);
  };

  return (
    <Box sx={classes.box}>
      <Box sx={classes.topBtnBox}>
        <Button
          onClick={exportCsv}
          variant="text"
          sx={classes.topBtn}
          startIcon={<DownloadIcon />}
        >
          DOWNLOAD
        </Button>
        <Button
          onClick={copyUrlToClipboard}
          variant="text"
          sx={classes.topBtn}
          startIcon={<ShareIcon />}
        >
          SHARE
        </Button>
      </Box>
      <Box>
        {!disableFilter && (
          <Typography sx={classes.title}>
            Displaying {leaseRoundTotalCount} lease rounds
          </Typography>
        )}
      </Box>
      <Box sx={classes.legendBox}>
        <Box sx={classes.legendContainer}>
          <Box sx={classes.displayFlex}>
            <Typography sx={classes.fontSize10}>
              Lease round key date:
            </Typography>
            <Legend
              radius="50%"
              title="Accepting proposals"
              color="#FF9800"
              sx={classes.legend}
              toolTip="Round is open for new project proposals"
            />
            <Legend
              radius="50%"
              title="Closed"
              color="#EF5350"
              sx={classes.legend}
              toolTip="Round is closed for new project proposals"
            />
            <Legend
              radius="50%"
              title="Completed"
              color="#4CAF50"
              sx={classes.legend}
              toolTip="Round has concluded and a winner(s) has been selected"
            />
          </Box>
          <Box sx={classes.displayFlex}>
            <Typography sx={classes.fontSize10}>Bid status:</Typography>
            <Legend
              radius="1px"
              title="Submitted"
              color="#88C7FF"
              sx={classes.legend}
              toolTip="Bidder has submitted proposal to the round"
            />
            <Legend
              radius="1px"
              title="Proposed bid"
              hatchedColor="repeating-linear-gradient(
                45deg,
                white 0 10px,
                #88C7FF 10px 15px
              )"
              sx={classes.legend}
              toolTip="Developer has announced an interest in taking part in round"
            />
            <Legend
              radius="1px"
              title="Winner"
              color="#0E9EFF"
              sx={classes.legend}
              toolTip="Bidder has been selected as winner of the round"
            />
            <Legend
              radius="1px"
              title="Unsuccessful"
              color="#7B7B7B"
              sx={classes.legend}
              toolTip="Bidder has submitted proposal and been unsuccessful"
            />
            <Legend
              radius="1px"
              title="Withdrawn"
              color="#7B7B7B"
              sx={classes.legend}
              toolTip="Bidder has withdrawn proposal from round"
            />
          </Box>
        </Box>
        <Box sx={classes.btnContainer}>
          {!disableFilter && (
            <FilterBtn count={calculateFilterCount(selectedFilter)} />
          )}
          <Button
            onClick={() => {
              setCollapsedAll(!collapsedAll);
              dispatch(setCollapsedAllResource(!collapsedAll));
            }}
            sx={classes.btnUnfold}
            variant="text"
            startIcon={collapsedAll ? <UnfoldIcon /> : <FoldIcon />}
          >
            {collapsedAll ? "Unfold" : "Fold"} all
          </Button>
          <Typography sx={classes.viewTitle}>
            <VisibilityIcon /> View by
          </Typography>
          <FormControl>
            <Select
              value={viewType}
              onChange={(event: GenericType) => setViewType(event.target.value)}
              sx={classes.viewSelect}
            >
              <MenuItem value="year">Year</MenuItem>
              <MenuItem value="month">Month</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
