import { LeaseArea } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = { id: number };

type ResponseData = { data: { leaseRound: LeaseArea } };

export const getLeaseRegion: ApiMethodDeclaration<ParamsData, ResponseData> = (
  id,
) => ({
  url: `/Wind/LeaseRound/LeaseRegionInformation/${id}?api-version=1.0`,
  method: "GET",
});
