import { LETTER_MAPPING } from "../types";

function expandOptions(options: string[], newChars: string[]): string[] {
  const newOptions: string[] = [];
  options.forEach((option) => {
    newChars.forEach((char) => {
      newOptions.push(option + char);
    });
  });
  return newOptions;
}

export function getAllTransliterations(input: string): string[] {
  let transliterationOptions: string[] = [""];

  for (const char of input.toUpperCase()) {
    if (LETTER_MAPPING[char]) {
      transliterationOptions = expandOptions(
        transliterationOptions,
        LETTER_MAPPING[char],
      );
    } else {
      // Handle characters that are not in the mapping
      transliterationOptions = transliterationOptions.map((opt) => opt + char);
    }
  }

  return transliterationOptions;
}

export const keyToReadableString = (camelCaseString: string) => {
  return (
    camelCaseString.charAt(0).toUpperCase() +
    camelCaseString.toLocaleLowerCase().slice(1)
  );
};
