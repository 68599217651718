import { GenericType } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = undefined;

type ResponseData = { data: GenericType[] };

export const getProducts: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = () => ({
  url: `/Lookups/Products?api-version=1.0`,
  method: "GET",
});
