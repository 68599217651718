import { GenericType } from "../../types";
import { Analytic } from "../../types/analytics";
import { ApiMethodDeclaration } from "../types";

type ParamsData = GenericType;

type ResponseData = { data: Analytic[] };

export const getAnalytics: ApiMethodDeclaration<ParamsData, ResponseData> = (
  data,
) => ({
  url: `/Wind/Analytics/CountryCapacityByFilters?api-version=1.0`,
  method: "POST",
  data,
});
