import { ErrorResponse } from "../types";

export const getErrorMessage = (err: unknown): string => {
  const error = err as ErrorResponse;

  if (error.response?.data?.title) {
    return error.response.data.title;
  }

  return "Something Went Wrong";
};
