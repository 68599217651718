import { FC, useMemo } from "react";
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@esgian/esgianui";

import { useSelector } from "../../hooks/use-selector";
import {
  getFloatingTechnologyChartData,
  getFloatingTechnologyLoading,
} from "../../store/selector/floatingTechnology";
import { FloatingTechnologyChartTabEnum, GenericType } from "../../types";
import { BarChart } from "../BarChart";

type Prop = {
  tab: FloatingTechnologyChartTabEnum;
  setTab: (e: FloatingTechnologyChartTabEnum) => void;
};

export const FloatingTechnologyChart: FC<Prop> = ({ tab, setTab }) => {
  const loading = useSelector(getFloatingTechnologyLoading);

  const chartData = useSelector(getFloatingTechnologyChartData);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTab(event.target.value as FloatingTechnologyChartTabEnum);
  };

  const data: GenericType | null = useMemo(() => {
    if (
      tab === FloatingTechnologyChartTabEnum.MarketAdaption &&
      chartData?.adaption
    ) {
      return chartData.adaption;
    }
    if (
      tab === FloatingTechnologyChartTabEnum.MarketShare &&
      chartData?.share
    ) {
      return chartData.share;
    }
    return null;
  }, [chartData, tab]);

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControl component="fieldset" sx={{ px: 2 }} fullWidth={true}>
          <RadioGroup
            row={true}
            aria-labelledby="view-radio-buttons"
            name="view-radio-buttons"
            value={tab}
            onChange={handleChange}
          >
            <FormControlLabel
              value={FloatingTechnologyChartTabEnum.MarketAdaption}
              control={<Radio />}
              label="Market adaption"
            />
            <FormControlLabel
              value={FloatingTechnologyChartTabEnum.MarketShare}
              control={<Radio />}
              label="Market share"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box sx={{ height: "calc(100vh - 115px)", overflowY: "auto", px: 1 }}>
        {tab === FloatingTechnologyChartTabEnum.MarketAdaption ? (
          <Grid container={true} direction="column" spacing={0}>
            <Grid item={true} xs={12} sm={4}>
              <BarChart
                type="horizontal"
                data={data?.capacity || []}
                title="MW capacity"
                loading={loading}
                height={1050}
              />
              <Divider sx={{ my: 2 }} />
            </Grid>
            <Grid item={true} xs={12} sm={4}>
              <BarChart
                type="horizontal"
                data={data?.turbine || []}
                title="Number of turbines"
                loading={loading}
                height={1050}
              />
              <Divider sx={{ my: 2 }} />
            </Grid>
            <Grid item={true} xs={12} sm={4}>
              <BarChart
                type="horizontal"
                data={data?.project || []}
                title="Number of projects"
                loading={loading}
                height={1050}
              />
            </Grid>
          </Grid>
        ) : (
          <Box>
            <Grid container={true} direction="column" spacing={0}>
              <Grid item={true} xs={12} sm={4}>
                <BarChart
                  type="vertical"
                  data={data?.capacity || []}
                  title="MW"
                  multiSeries={true}
                  loading={loading}
                />
                <Divider sx={{ my: 2 }} />
              </Grid>
              <Grid item={true} xs={12} sm={4}>
                <BarChart
                  type="vertical"
                  data={data?.turbine || []}
                  title="Number of turbines"
                  multiSeries={true}
                  loading={loading}
                />
                <Divider sx={{ my: 2 }} />
              </Grid>
              <Grid item={true} xs={12} sm={4}>
                <BarChart
                  type="vertical"
                  data={data?.project || []}
                  title="Number of projects"
                  multiSeries={true}
                  loading={loading}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </Box>
  );
};
