import { useEffect } from "react";
import { Box } from "@esgian/esgianui";

import { InstallationDrawer } from "../../components/Drawer/Installation";
import { FilterModal } from "../../components/Modal/FilterModal";
import { VesselAvailabilityList } from "../../components/VesselAvailabilityList";
import { VesselTimeline } from "../../components/VesselTimeline";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getFilterModal,
  getSelectedInstallationId,
  getViewTab,
} from "../../store/selector/common";
import {
  getFilterOptions,
  getSelectedFilter,
  getVesselContractChart,
} from "../../store/selector/vesselAvailability";
import {
  fetchLookup,
  setFilterModal,
  setSearchObjectCoordinates,
  setSearchText,
  setSelectedInstallationId,
  setViewTab,
} from "../../store/slice/common";
import {
  fetchVesselContractChart,
  setSelectedFilter,
} from "../../store/slice/vesselAvailability";
import { FilterParamOptions, GenericType, ViewTabEnum } from "../../types";

const legends: GenericType = {
  Foundation: "rgb(145, 51, 200)",
  OMS: "rgb(214, 236, 11)",
  Turbine: "rgb(82, 202, 238)",
  "Vessel under construction": "rgb(0, 139, 106)",
  "Cable lay": "rgb(255, 105, 180)",
};

export const InstallationVesselAvailability = () => {
  const dispatch = useDispatch();

  const viewTab = useSelector(getViewTab);
  const options = useSelector(
    getFilterOptions,
  ) as unknown as FilterParamOptions;
  const selectedFilter = useSelector(getSelectedFilter);
  const isFilterModalOpen = useSelector(getFilterModal);
  const selectedInstallationId = useSelector(getSelectedInstallationId);
  const vesselContractChart = useSelector(getVesselContractChart);

  useEffect(() => {
    dispatch(fetchLookup(["projects", "countries", "vesselType"]));
    dispatch(setViewTab(ViewTabEnum.List));
    dispatch(fetchVesselContractChart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ px: 2, maxWidth: "calc(100vw - 265px)" }}>
      {viewTab === ViewTabEnum.List ? (
        <VesselAvailabilityList />
      ) : (
        <VesselTimeline
          data={vesselContractChart || []}
          height="calc(100vh - 100px)"
          legends={legends}
          projectDrawer={false}
        />
      )}
      {options ? (
        <FilterModal
          open={isFilterModalOpen}
          handleClose={() => dispatch(setFilterModal(false))}
          onChange={(filterValues) => {
            dispatch(setSelectedFilter(filterValues));
          }}
          selectedFilter={selectedFilter}
          options={options}
        />
      ) : null}
      <InstallationDrawer
        id={selectedInstallationId}
        onClose={() => dispatch(setSelectedInstallationId(null))}
      />
    </Box>
  );
};
