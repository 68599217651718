import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { api } from "../../api/api";
import { generateOptions } from "../../helper/fllter";
import {
  BarChart,
  FloatingTechnology,
  FloatingTechnologyChart,
  GenericType,
  RootState,
  SortingInfo,
} from "../../types";

type State = {
  isLoading: boolean;
  floatingTechnologyList: FloatingTechnology[] | null;
  chartData: FloatingTechnologyChart | null;
  filterOptions: GenericType | null;
  selectedFilter: GenericType;
  sortingInfo: SortingInfo;
};

const initialState: State = {
  isLoading: false,
  floatingTechnologyList: null,
  chartData: null,
  filterOptions: null,
  selectedFilter: {},
  sortingInfo: {
    direction: "asc",
    name: "technology",
  },
};

export const fetchFloatingTechnologyList = createAsyncThunk(
  "floatingTechnology/list",
  async () => {
    const { data } = await api.floatingTechnology.getFloatingTechnology({});
    const tableData = data.map((item: GenericType) => ({
      ...item.floatingTechnology,
      company: item?.floatingTechnology?.suppliers
        ?.map((c: GenericType) => c.companyName)
        .join(", "),
      floaterType: item?.floatingTechnology?.floaterType?.type,
      floaterTypeId: item?.floatingTechnology?.floaterType?.id,
      floaterMaterial: item?.floatingTechnology?.materialType?.material,
      floaterMaterialId: item?.floatingTechnology?.materialType?.id,
    }));
    const chartFilterOptions: GenericType = {
      floaterType: [],
      floaterMaterial: [],
    };
    data.forEach((item: GenericType) => {
      const floaterMaterial = {
        id: item?.floatingTechnology?.materialType?.id,
        value: item?.floatingTechnology?.materialType?.material,
      };
      const floaterType = {
        id: item?.floatingTechnology?.floaterType?.id,
        value: item?.floatingTechnology?.floaterType?.type,
      };

      if (
        !chartFilterOptions.floaterType.some(
          (e: GenericType) => e.id === floaterType.id,
        )
      ) {
        chartFilterOptions.floaterType.push(floaterType);
      }

      if (
        !chartFilterOptions.floaterMaterial.some(
          (e: GenericType) => e.id === floaterMaterial.id,
        )
      ) {
        chartFilterOptions.floaterMaterial.push(floaterMaterial);
      }
    });
    return {
      data: tableData,
      filterOptions: {
        ...generateOptions(tableData, [
          "company",
          "technology",
          "floaterType",
          "floaterMaterial",
        ]),
      },
      selectedFilter: {},
      chartFilterOptions,
    };
  },
);

export const fetchFloatingTechnologyChart = createAsyncThunk(
  "fetchFloatingTechnologyChart",
  async (_, { getState }) => {
    try {
      const { floatingTechnologies } = getState() as RootState;
      const selectedFilter = floatingTechnologies.selectedFilter;
      const filter = {
        companyIds:
          selectedFilter?.company?.map((item: GenericType) => item.id) ||
          undefined,
        floatingTechnologyIds:
          selectedFilter?.technology?.map((item: GenericType) => item.id) ||
          undefined,
        floaterTypeIds:
          selectedFilter?.floaterTypeIds?.map((item: GenericType) => item.id) ||
          undefined,
        floaterMaterialIds:
          selectedFilter?.floaterMaterial?.map(
            (item: GenericType) => item.id,
          ) || undefined,
      };
      const { data: adaption } =
        await api.floatingTechnology.getFloatingTechnologyAdaption(filter);
      const adaptionCapacity: BarChart[] = [];
      const adaptionProject: BarChart[] = [];
      const adaptionTurbine: BarChart[] = [];
      adaption.forEach((item: GenericType) => {
        const company = item.company
          ?.map((c: GenericType) => c.companyName)
          .join(", ");
        const technology = item?.technology?.technology;
        adaptionCapacity.push([
          technology,
          Math.round(item.marketCapacity.knownCapacity),
          Math.round(item.marketCapacity.unknownCapacity),
          company,
          item?.technology?.id,
        ]);
        adaptionProject.push([
          technology,
          Math.round(item.windProjectCapacity.knownCapacity),
          Math.round(item.windProjectCapacity.unknownCapacity),
          company,
          item?.technology?.id,
        ]);
        adaptionTurbine.push([
          technology,
          Math.round(item.turbineCapacity.knownCapacity),
          Math.round(item.turbineCapacity.unknownCapacity),
          company,
          item?.technology?.id,
        ]);
      });
      let shareCapacity: BarChart[] = [];
      let shareProject: BarChart[] = [];
      let shareTurbine: BarChart[] = [];
      const { data: share } =
        await api.floatingTechnology.getFloatingTechnologyShare(filter);

      share.forEach((item: GenericType) => {
        const company = item.company
          ?.map((c: GenericType) => c.companyName)
          .join(", ");
        const technology = item?.technology?.technology;
        item.mwCapacity.forEach((mw: GenericType) => {
          shareCapacity.push([
            moment(mw.date).format("YYYY"),
            company,
            Math.round(mw.capacity),
            technology,
          ]);
        });
        item.projects.forEach((mw: GenericType) => {
          shareProject.push([
            moment(mw.date).format("YYYY"),
            company,
            Math.round(mw.capacity),
            technology,
          ]);
        });
        item.turbines.forEach((mw: GenericType) => {
          shareTurbine.push([
            moment(mw.date).format("YYYY"),
            company,
            Math.round(mw.capacity),
            technology,
          ]);
        });
      });

      let chartFilterOptions: GenericType = {
        technology: [],
        companies: [],
      };
      [...adaption, ...share].forEach((item: GenericType) => {
        const technology = {
          id: item.technology.id,
          value: item.technology.technology,
        };
        const companies = item?.company?.map((c: GenericType) => ({
          id: c.id,
          value: c.companyName,
        }));
        const filteredCompanies = companies.filter(
          (c: GenericType) =>
            !chartFilterOptions.companies.some(
              (ec: GenericType) => ec.id === c.id,
            ),
        );
        chartFilterOptions.companies.push(...filteredCompanies);
        if (
          !chartFilterOptions.technology.some(
            (e: GenericType) => e.id === technology.id,
          )
        ) {
          chartFilterOptions.technology.push(technology);
        }
      });
      chartFilterOptions = {
        technology: chartFilterOptions.technology.sort(
          (a: GenericType, b: { value: number }) => {
            return a?.value?.localeCompare(b.value);
          },
        ),
        companies: chartFilterOptions.companies.sort(
          (a: GenericType, b: { value: number }) => {
            return a?.value?.localeCompare(b.value);
          },
        ),
      };
      if (selectedFilter.dateRange) {
        const selectedDateRange = selectedFilter.dateRange;
        shareCapacity = shareCapacity.filter(
          (item) =>
            moment(item[0]).isSameOrAfter(moment(selectedDateRange[0])) &&
            moment(item[0]).isSameOrBefore(moment(selectedDateRange[1])),
        );
        shareProject = shareProject.filter(
          (item) =>
            moment(item[0]).isSameOrAfter(moment(selectedDateRange[0])) &&
            moment(item[0]).isSameOrBefore(moment(selectedDateRange[1])),
        );
        shareTurbine = shareTurbine.filter(
          (item) =>
            moment(item[0]).isSameOrAfter(moment(selectedDateRange[0])) &&
            moment(item[0]).isSameOrBefore(moment(selectedDateRange[1])),
        );
      }
      return {
        chartData: {
          adaption: {
            capacity: adaptionCapacity.sort(
              (a: GenericType, b: GenericType) => {
                const sumA = a[1] + a[2];
                const sumB = b[1] + b[2];

                return sumB - sumA;
              },
            ),
            project: adaptionProject.sort((a: GenericType, b: GenericType) => {
              const sumA = a[1] + a[2];
              const sumB = b[1] + b[2];

              return sumB - sumA;
            }),
            turbine: adaptionTurbine.sort((a: GenericType, b: GenericType) => {
              const sumA = a[1] + a[2];
              const sumB = b[1] + b[2];

              return sumB - sumA;
            }),
          },
          share: {
            capacity: shareCapacity,
            project: shareProject,
            turbine: shareTurbine,
          },
        },
        chartFilterOptions,
      };
    } catch (err) {
      return null;
    }
  },
);

const floatingTechnologiesSlice = createSlice({
  name: "floatingTechnologies",
  initialState,
  reducers: {
    setSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      state.selectedFilter = action.payload;
    },
    setSortingInfo: (state, action: PayloadAction<SortingInfo>) => {
      state.sortingInfo = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchFloatingTechnologyList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchFloatingTechnologyList.rejected, (state) => {
        state.isLoading = false;
        state.floatingTechnologyList = null;
      })
      .addCase(fetchFloatingTechnologyList.fulfilled, (state, action) => {
        state.floatingTechnologyList = action?.payload?.data;
        state.selectedFilter = {
          ...state.selectedFilter,
          ...action?.payload?.selectedFilter,
        };
        state.filterOptions = {
          ...state?.filterOptions,
          ...action?.payload?.chartFilterOptions,
        };
      });
    builder
      .addCase(fetchFloatingTechnologyChart.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchFloatingTechnologyChart.rejected, (state) => {
        state.isLoading = false;
        state.chartData = null;
      })
      .addCase(fetchFloatingTechnologyChart.fulfilled, (state, action) => {
        state.chartData = action?.payload?.chartData || null;
        state.filterOptions = {
          ...action?.payload?.chartFilterOptions,
          ...state.filterOptions,
        };
        state.isLoading = false;
      });
  },
});

export const { setSelectedFilter, setSortingInfo } =
  floatingTechnologiesSlice.actions;
export const floatingTechnologies = floatingTechnologiesSlice.reducer;
