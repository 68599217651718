import mapboxgl, { IControl, Map, Marker } from "mapbox-gl";
import moment from "moment";

import FilterSVG from "../assets/filter.svg";
import InfoSVG from "../assets/info.svg";
import { CoordinatesObj, GenericType, Option, ThemeModeEnum } from "../types";

const { REACT_APP_DARK_MAP_STYLE, REACT_APP_LIGHT_MAP_STYLE } = process.env;
const { REACT_APP_MAP_TOKEN } = process.env;

export const THRESHOLD_ZOOM = 4;
export const CIRCLE_CLICK_ZOOM = 5.5;
export const ZOOM = 2;
export const CENTER: [number, number] = [7.278294066469044, 63.03666599692656];

export const CreateMap = (
  zoom: number,
  center: [number, number] | CoordinatesObj,
  move: boolean,
  theme: string,
) =>
  new mapboxgl.Map({
    container: "map",
    style:
      theme === ThemeModeEnum.Dark
        ? REACT_APP_DARK_MAP_STYLE
        : REACT_APP_LIGHT_MAP_STYLE,
    center,
    zoom,
    dragPan: move,
    scrollZoom: move,
    boxZoom: move,
    doubleClickZoom: move,
  });

const filterButtonContent = (filterCount: number) =>
  `<img src=${FilterSVG} alt="Filter" /> ${
    filterCount ? `<span class="filter-count">${filterCount}</span>` : ""
  }`;
export const searchZoom = (map: Map, item: Option) => {
  if (item.centroidCoordinates) {
    map.flyTo({
      center: item.centroidCoordinates,
      zoom: THRESHOLD_ZOOM + 0.1,
    });
  }
};

export const UpdateFilterButton = (map: Map, filterCount: number) => {
  const div = map.getContainer().querySelector(".mapboxgl-ctrl-filter-button");
  if (div) {
    div.innerHTML = div.innerHTML = filterButtonContent(filterCount);
  }
};

export const AddFilterButton = (
  map: Map,
  styles: GenericType,
  filterCount: number,
  handleClick: () => void,
) => {
  class FilterButton {
    onAdd() {
      const div = document.createElement("div");
      div.className =
        "mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl-filter-button";
      div.innerHTML = filterButtonContent(filterCount);
      Object.assign(div.style, styles);

      div.addEventListener("click", () => {
        handleClick();
      });

      return div;
    }

    onRemove() {}
  }

  const filterBtn: IControl = new FilterButton();
  map.addControl(filterBtn, "top-right");
};

export const removeMarkers = (
  markerList: Marker[],
  callBack: (v: []) => void,
) => {
  markerList.forEach((marker) => marker.remove());
  callBack([]);
};

const OFFSET_DISTANCE = 1;

const calculateOffset = (
  lat: string,
  lon: string,
  angle: number,
  distance: number,
) => {
  const angleRadians = (angle * Math.PI) / 180;

  const newLat = lat + distance * Math.sin(angleRadians);
  const newLon = lon + distance * Math.cos(angleRadians);

  return { lat: newLat, lon: newLon };
};

export const transformAndAddOffsets = (items: GenericType[]) => {
  return items.map((itemGroup) => {
    const centerCoordinates = itemGroup[0].centroidCoordinates;
    const totalItems = itemGroup.length;

    const transformedItems = itemGroup.map(
      (item: GenericType, index: number) => {
        const angle = (360 / totalItems) * index;

        const newCoordinates = calculateOffset(
          centerCoordinates.lat,
          centerCoordinates.lon,
          angle,
          OFFSET_DISTANCE,
        );

        return {
          ...item,
          centroidCoordinates: newCoordinates,
        };
      },
    );

    return {
      centerCoordinates,
      items: transformedItems,
    };
  });
};

export const fetchStateName = async (latitude: number, longitude: number) => {
  const accessToken = REACT_APP_MAP_TOKEN;
  const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${accessToken}&types=region`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    const stateName = data.features[0]?.place_name;
    return stateName.split(",")[0];
  } catch (error) {
    console.error("Error fetching state name:", error);
  }
};

export const AddInfoButton = (
  map: Map,
  styles: GenericType,
  handleClick: () => void,
) => {
  class InfoButton {
    onAdd() {
      const div = document.createElement("div");
      div.className = "mapboxgl-ctrl mapboxgl-ctrl-group filter-btn";
      div.innerHTML = `<img src=${InfoSVG} alt="Info" />`;
      Object.assign(div.style, styles);

      div.addEventListener("click", () => {
        handleClick();
      });

      return div;
    }

    onRemove() {}
  }

  const infoBtn: IControl = new InfoButton();
  map.addControl(infoBtn, "top-left");
};

export const extractContracts = (data: GenericType[]) => {
  return data.flatMap((item) => {
    const vesselInfo = {
      ...item.vessel,
    };

    const turbineContracts = item.turbineContracts.map(
      (contract: GenericType) => ({
        ...contract,
        vesselInfo,
        title: moment(contract.awardDate).format("YYYY-MM-DD"),
      }),
    );

    const cableContracts = item.cableContracts.map((contract: GenericType) => ({
      ...contract,
      vesselInfo,
      title: moment(contract.awardDate).format("YYYY-MM-DD"),
    }));

    return [...turbineContracts, ...cableContracts];
  });
};
