/* eslint-disable indent */
import { useEffect, useState } from "react";
import { Box } from "@esgian/esgianui";

import { FloatingTechnologyDrawer } from "../../components/Drawer/FloatingTechnologyDrawer";
import { FilterBtn } from "../../components/FilterBtn";
import { FloatingTechnologyChart } from "../../components/FloatingTechnologyChart";
import { FloatingTechnologyList } from "../../components/FloatingTechnologyList";
import { FilterModal } from "../../components/Modal/FilterModal";
import { calculateFilterCount } from "../../helper/fllter";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getFilterModal,
  getSelectedFloatingTechnologyId,
  getViewTab,
} from "../../store/selector/common";
import {
  getFloatingTechnologyFilterOptions,
  getSelectedFilter,
} from "../../store/selector/floatingTechnology";
import {
  setFilterModal,
  setSearchObjectCoordinates,
  setSearchText,
  setSelectedFloatingTechnologyId,
  setViewTab,
} from "../../store/slice/common";
import {
  fetchFloatingTechnologyChart,
  fetchFloatingTechnologyList,
  setSelectedFilter,
} from "../../store/slice/floatingTechnology";
import {
  FilterItemType,
  FloatingTechnologyChartTabEnum,
  ViewTabEnum,
} from "../../types";

export const FloatingTechnology = () => {
  const dispatch = useDispatch();

  const viewTab = useSelector(getViewTab);
  const isFilterModalOpen = useSelector(getFilterModal);
  const selectedFilter = useSelector(getSelectedFilter);
  const options = useSelector(getFloatingTechnologyFilterOptions);
  const [tab, setTab] = useState<FloatingTechnologyChartTabEnum>(
    FloatingTechnologyChartTabEnum.MarketAdaption,
  );

  const selectedFloatingTechnologyId = useSelector(
    getSelectedFloatingTechnologyId,
  );

  useEffect(() => {
    dispatch(setViewTab(ViewTabEnum.List));
    dispatch(fetchFloatingTechnologyList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchFloatingTechnologyChart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflowY: "auto",
      }}
    >
      <Box sx={{ position: "absolute", right: 20, top: 4, zIndex: 1 }}>
        <FilterBtn count={calculateFilterCount(selectedFilter)} />
      </Box>
      {viewTab === ViewTabEnum.List ? (
        <FloatingTechnologyList />
      ) : (
        <FloatingTechnologyChart tab={tab} setTab={setTab} />
      )}
      <FloatingTechnologyDrawer
        id={selectedFloatingTechnologyId}
        onClose={() => dispatch(setSelectedFloatingTechnologyId(null))}
      />
      {options ? (
        <FilterModal
          open={isFilterModalOpen}
          handleClose={() => dispatch(setFilterModal(false))}
          selectedFilter={selectedFilter || {}}
          options={
            viewTab === ViewTabEnum.Chart &&
            tab === FloatingTechnologyChartTabEnum.MarketShare
              ? {
                  ...options,
                  dateRange: {
                    options: [],
                    type: FilterItemType.Date,
                    inputLabelText: "Date Range",
                    format: "YYYY",
                  },
                }
              : options
          }
          onChange={(filterValues) => dispatch(setSelectedFilter(filterValues))}
        />
      ) : null}
    </Box>
  );
};
