import { getEsgianTheme } from "@esgian/esgianui";
import { IControl, Map } from "mapbox-gl";

import ExportSVG from "../../../assets/export.svg";

import { GenericType, THEME, Target, ThemeModeEnum } from "../../../types";
import moment from "moment";

export const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);

  return {
    filterButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "32px",
      width: "32px",
      borderRadius: "50%",
      border: "none",
      marginTop: "16px",
      overflow: "visible",
      position: "relative",
      background: theme.palette.common.white,
    },
    map: {
      overflow: "hidden",
      height: "calc(100% - 60px)",
      width: "100%",
      "& .filter-count": {
        position: "absolute",
        background: "#80DFEB",
        height: "12px",
        width: "12px",
        fontSize: 10,
        top: -5,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        color: "black",
      },
      "& .mapboxgl-popup": {
        "& .mapboxgl-popup-tip,": {
          borderRightColor: "rgba(0, 0, 0, 0.6)",
        },
        "& .mapboxgl-popup-content": {
          background: "rgba(0, 0, 0, 0.6)",
        },
        "& .popup-container": {
          display: "flex",
          "& .list": {
            display: "flex",
            flexDirection: "column",
            width: 170,
          },
        },
      },
      "& .mapboxgl-control-container": {
        "& .mapboxgl-ctrl-top-right": {
          right: 16,
          cursor: "pointer",
        },
        "& img": {
          width: 16,
        },
      },
      "& .mapboxgl-ctrl-filter-button": {
        background: `${theme.palette.common.white} !important`,
      },
    },
  };
};

export const generatePopupHTML = (data: Target) => {
  return `<div class="popup-container">
            <div class="list">
              <span>Country/State/Region</span>  
              <span>Target year</span>  
              <span>Target capacity (GW)</span>  
              <span>Date announced</span>  
              <span>Target type</span>  
            </div>
            <div class="list">
              <span>${data.country !== undefined ? data.country : data.landRegion}</span>
              <span>${data.year ?? "-"}</span>  
              <span>${data.capacity}</span>  
              <span>${moment(data.announcedDate).format("YYYY-MM-DD") !== "0001-01-01" ? moment(data.announcedDate).format("YYYY-MM-DD") : "-"}</span>  
              <span>${data.type}</span>  
            </div>
          </div>`;
};

export const ExportBtn = (
  map: Map,
  styles: GenericType,
  handleClick: () => void,
) => {
  class FilterButton {
    onAdd() {
      const div = document.createElement("div");
      div.className =
        "mapboxgl-ctrl mapboxgl-ctrl-group mapboxgl-ctrl-filter-button";
      div.innerHTML = `<img src=${ExportSVG} alt="Export" /> `;
      Object.assign(div.style, styles);

      div.addEventListener("click", () => {
        handleClick();
      });

      return div;
    }

    onRemove() {}
  }

  const filterBtn: IControl = new FilterButton();
  map.addControl(filterBtn, "top-right");
};
