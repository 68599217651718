import { sortValues } from "../../helper/fllter";
import { FilterItemType, GenericType, RootState } from "../../types";

export const getVesselAvailabilityList = (state: RootState) => {
  return state.vesselAvailability.vesselAvailabilityList;
};

export const getSelectedFilter = (state: RootState) => {
  return state.vesselAvailability.selectedFilter;
};

export const getFilterOptions = (state: RootState) => {
  return {
    project: {
      type: FilterItemType.MultiSelect,
      labelKey: "value",
      inputLabelText: "Project",
      options: sortValues(
        state.common.projects
          ?.map((item) => ({
            id: item.assetId,
            value: item.name,
          }))
          .filter((item: GenericType) => item.value),
      ),
    },
    installationDate: {
      type: FilterItemType.Date,
      inputLabelText: "Installation Date",
      format: "YYYY MM",
      labelKey: "value",
    },
    foundationWeight: {
      type: FilterItemType.Slider,
      options: [0, 10000],
      inputLabelText: "Foundation weight (t)",
      labelKey: "value",
    },
    hubHeight: {
      type: FilterItemType.Slider,
      options: [0, 10000],
      inputLabelText: "Hub height (m)",
      labelKey: "value",
    },
    vesselCapability: {
      type: FilterItemType.MultiSelect,
      labelKey: "value",
      inputLabelText: "Vessel Capability",
      options: sortValues(
        ["Jacket", "Monopile", "Turbine"]?.map((item) => ({
          id: item,
          value: item,
        })),
      ),
    },
  };
};

export const getIsLoading = (state: RootState) => {
  return state.vesselAvailability.isLoading;
};

export const getVesselContractChart = (state: RootState) => {
  return state.vesselAvailability.vesselChartData;
};

export const getSortingInfo = (state: RootState) => {
  return state.vesselAvailability.sortingInfo;
};
