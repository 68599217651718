import { useEffect, useState } from "react";
import { Box, FormControlLabel, Radio, RadioGroup } from "@esgian/esgianui";

import { Loading } from "../../components/Loading";
import { FilterModal } from "../../components/Modal/FilterModal";
import { PowerOutputProjectList } from "../../components/PowerOutputProjectList";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getFilterModal,
  getIsLookupLoading,
} from "../../store/selector/common";
import {
  getIsLoading,
  getOptions,
  getSelectedFilter,
  getTab,
} from "../../store/selector/powerOutput";
import { setFilterModal } from "../../store/slice/common";
import {
  fetchList,
  setSelectedFilter,
  setTab,
} from "../../store/slice/powerOutput";
import { GenericType, PowerOutputEnum } from "../../types";

export const PowerOutput = () => {
  const dispatch = useDispatch();

  const isLookupLoading = useSelector(getIsLookupLoading);
  const isFilterModalOpen = useSelector(getFilterModal);
  const options = useSelector(getOptions);
  const selectedFilter = useSelector(getSelectedFilter);
  const isLoading = useSelector(getIsLoading);
  const tab = useSelector(getTab);

  const [selectedView, setSelectedView] = useState("Capacity");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTab(event?.target?.value as PowerOutputEnum));
  };

  useEffect(() => {
    if (!isLookupLoading) {
      dispatch(fetchList());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLookupLoading]);

  const specificFilters: GenericType = [];
  if (options && tab && options[tab]) {
    Object.keys(options[tab]).forEach((filter) => {
      if (filter.includes(selectedView) && filter !== "installedCapacity") {
        specificFilters.push(options![tab][filter]);
      }
      if (filter === "name" || filter === "installedCapacity") {
        specificFilters.push(options![tab][filter]);
      }
    });
  }

  return (
    <Box>
      <Box sx={{ mx: 2 }}>
        <RadioGroup
          row={true}
          aria-labelledby="view-radio-buttons"
          name="view-radio-buttons"
          value={tab}
          onChange={handleChange}
        >
          <FormControlLabel
            value={PowerOutputEnum.WindFarm}
            control={<Radio />}
            label="Wind Farm"
          />
          <FormControlLabel
            value={PowerOutputEnum.Developer}
            control={<Radio />}
            label="Developer"
          />
          <FormControlLabel
            value={PowerOutputEnum.TurbineSupplier}
            control={<Radio />}
            label="Turbine"
          />
          <FormControlLabel
            value={PowerOutputEnum.Country}
            control={<Radio />}
            label="Country"
          />
          <FormControlLabel
            value={PowerOutputEnum.ControlArea}
            control={<Radio />}
            label="Control Area"
          />
        </RadioGroup>
      </Box>
      {!isLookupLoading && !isLoading ? (
        <PowerOutputProjectList
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          tab={tab}
        />
      ) : (
        <Loading />
      )}
      {specificFilters.length > 0 ? (
        <FilterModal
          open={isFilterModalOpen}
          handleClose={() => dispatch(setFilterModal(false))}
          onChange={(filterValues) => dispatch(setSelectedFilter(filterValues))}
          options={specificFilters}
          selectedFilter={selectedFilter}
        />
      ) : null}
    </Box>
  );
};
