import { Map } from "mapbox-gl";

import { Factory, Lookup, MapLayer, MapSource } from "../types";

import { THRESHOLD_ZOOM } from "./map";

export class Circle {
  init(map: Map) {
    map.addSource(MapSource.CircleSource, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      cluster: true,
      clusterRadius: 70,
    });

    map.addLayer({
      id: MapLayer.Circle,
      type: "circle",
      source: MapSource.CircleSource,
      // filter: ["has", "point_count"],
      paint: {
        "circle-color": "#0E9EFF",
        "circle-radius": [
          "max",
          ["min", ["*", ["get", "point_count_abbreviated"], 5], 30],
          10,
        ],
      },
      maxzoom: THRESHOLD_ZOOM,
    });
    map.addLayer({
      id: MapLayer.CircleCount,
      type: "symbol",
      source: MapSource.CircleSource,
      // filter: ["has", "point_count"],
      layout: {
        "text-field": "{point_count_abbreviated}",
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
      },
      maxzoom: THRESHOLD_ZOOM,
    });
  }

  update(map: Map, data: (Lookup | Factory)[]) {
    const features: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: "FeatureCollection",
      features: data.map((item) => {
        const isLookup = "centroidCoordinates" in item && "regionId" in item;
        const coordinates = isLookup
          ? [item?.centroidCoordinates?.lon, item?.centroidCoordinates?.lat]
          : [item?.coordinates?.lon, item.coordinates?.lat];
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates,
          },
          properties: {
            countryId: item?.countryId,
            regionId: isLookup && item?.regionId,
            name: !isLookup && item?.name,
          },
        };
      }),
    };
    const source = map.getSource(
      MapSource.CircleSource,
    ) as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData(features);
    }
    this.visible(map);
  }

  disable(map: Map) {
    map.setLayoutProperty(MapLayer.Circle, "visibility", "none");
    map.setLayoutProperty(MapLayer.CircleCount, "visibility", "none");
  }

  visible(map: Map) {
    map.setLayoutProperty(MapLayer.Circle, "visibility", "visible");
    map.setLayoutProperty(MapLayer.CircleCount, "visibility", "visible");
  }

  remove(map: Map) {
    if (map.getSource(MapSource.CircleSource)) {
      map.removeLayer(MapLayer.CircleCount);
      map.removeLayer(MapLayer.Circle);
      map.removeSource(MapSource.CircleSource);
    }
  }
}
