import { WindVesselAvailability } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = undefined;

type ResponseData = { data: WindVesselAvailability[] };

export const getVesselAvailability: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/Vessels/Availability?api-version=1.0`,
  method: "GET",
  data,
});
