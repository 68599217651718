import { GenericType } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = string;

type ResponseData = GenericType;

export const getProfile: ApiMethodDeclaration<ParamsData, ResponseData> = (
  token: string,
) => ({
  url: `/profile?token=${token}`,
  method: "Get",
});
