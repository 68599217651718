import { Country } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = undefined;
type ResponseData = { data: Country[] };

export const getCountryList: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = () => ({
  url: `/Lookup/Countries?api-version=1.0`,
  method: "GET",
});
