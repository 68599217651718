import { ApiMethodDeclaration } from "../types";

type ParamsData = {
  to: string;
  from: string;
  subject: string;
  body: string;
  html: boolean;
};

type ResponseData = undefined;

export const sendEmail: ApiMethodDeclaration<ParamsData, ResponseData> = (
  data,
) => ({
  url: `/Notification/Email?api-version=1.0`,
  method: "POST",
  data,
});
