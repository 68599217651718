import { AnyLayer, Map } from "mapbox-gl";

import {
  MapLayer,
  MapSource,
  SpiderTarget,
  ThemeModeEnum,
} from "../../../types";

const DarkSpiderCenterCircle: AnyLayer = {
  id: MapLayer.SpiderCenterCircle,
  type: "circle",
  source: MapSource.SpiderCenterCircle,
  paint: {
    "circle-radius": 8,
    "circle-color": "white",
  },
};

const LightSpiderCenterCircle: AnyLayer = {
  id: MapLayer.SpiderCenterCircle,
  type: "circle",
  source: MapSource.SpiderCenterCircle,
  paint: {
    "circle-radius": 8,
    "circle-color": "black",
  },
};

const DarkSpiderLine: AnyLayer = {
  id: MapLayer.SpiderLine,
  type: "line",
  source: MapSource.SpiderLine,
  layout: {
    "line-cap": "round",
    "line-join": "round",
  },
  paint: {
    "line-color": "white",
    "line-width": 2,
  },
};

const LightSpiderLine: AnyLayer = {
  id: MapLayer.SpiderLine,
  type: "line",
  source: MapSource.SpiderLine,
  layout: {
    "line-cap": "round",
    "line-join": "round",
  },
  paint: {
    "line-color": "black",
    "line-width": 2,
  },
};

export class SpiderCircleLayer {
  init(map: Map, themeMode: ThemeModeEnum) {
    map.addSource(MapSource.SpiderLine, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      cluster: false,
    });

    map.addSource(MapSource.SpiderCenterCircle, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      cluster: false,
    });

    map.addSource(MapSource.SpiderCircle, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      cluster: false,
    });

    if (themeMode === ThemeModeEnum.Dark) {
      map.addLayer(DarkSpiderCenterCircle);
      map.addLayer(DarkSpiderLine);
    } else {
      map.addLayer(LightSpiderCenterCircle);
      map.addLayer(LightSpiderLine);
    }

    map.addLayer({
      id: MapLayer.SpiderCircle,
      type: "circle",
      source: MapSource.SpiderCircle,
      paint: {
        "circle-radius": [
          "step",
          ["get", "capacity"],
          20,
          20,
          25,
          40,
          30,
          60,
          35,
          80,
          40,
        ],
        "circle-color": [
          "match",
          ["get", "type"],
          "Total",
          "#C4A9F9",
          "Floating foundation",
          "#9DF9FF",
          "Fixed foundation",
          "#FFFFFF",
          "#FFFFFF",
        ],
        "circle-stroke-width": 4,
        "circle-stroke-color": [
          "case",
          ["==", ["get", "isSelectedDate"], true],
          "#0000FF",
          "#75757e",
        ],
      },
    });

    map.addLayer({
      id: MapLayer.SpiderCircleLabel,
      type: "symbol",
      source: MapSource.SpiderCircle,
      layout: {
        "text-field": ["concat", ["to-string", ["get", "capacity"]], "GW"],
        "text-size": 12,
      },
      paint: {
        "text-color": "#000000",
      },
    });
  }

  update(map: Map, data: SpiderTarget[], selectedDate: number | null) {
    const centersSource = map.getSource(
      MapSource.SpiderCenterCircle,
    ) as mapboxgl.GeoJSONSource;
    const targetSource = map.getSource(
      MapSource.SpiderCircle,
    ) as mapboxgl.GeoJSONSource;
    const lineSource = map.getSource(
      MapSource.SpiderLine,
    ) as mapboxgl.GeoJSONSource;
    const centerCoordinates: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: "FeatureCollection",
      features: data.map((item: SpiderTarget) => {
        const coordinates = [
          item.centerCoordinates.lon,
          item.centerCoordinates.lat,
        ];
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates,
          },
          properties: {
            ...item,
          },
        };
      }),
    };

    const targetList: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: "FeatureCollection",
      features: data.flatMap((group) =>
        group.items.map((item) => ({
          type: "Feature",
          properties: {
            ...item,
            connectedLon: group.centerCoordinates.lon,
            connectedLat: group.centerCoordinates.lat,
            isSelectedDate: Number(item.year) === selectedDate,
          },
          geometry: {
            type: "Point",
            coordinates: [
              item.centroidCoordinates.lon,
              item.centroidCoordinates.lat,
            ],
          },
        })),
      ),
    };

    const linesList: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: "FeatureCollection",
      features: data.flatMap((group) =>
        group.items.map((item) => ({
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: [
              [group.centerCoordinates.lon, group.centerCoordinates.lat],
              [item.centroidCoordinates.lon, item.centroidCoordinates.lat],
            ],
          },
        })),
      ),
    };
    if (centersSource) {
      centersSource.setData(centerCoordinates);
    }
    if (targetSource) {
      targetSource.setData(targetList);
    }
    if (lineSource) {
      lineSource.setData(linesList);
    }
  }

  disable(map: Map) {
    map.setLayoutProperty(MapLayer.Circle, "visibility", "none");
    map.setLayoutProperty(MapLayer.CircleCount, "visibility", "none");
  }

  visible(map: Map) {
    map.setLayoutProperty(MapLayer.Circle, "visibility", "visible");
    map.setLayoutProperty(MapLayer.CircleCount, "visibility", "visible");
  }

  remove(map: Map) {
    if (map.getSource(MapSource.CircleSource)) {
      map.removeLayer(MapLayer.CircleCount);
      map.removeLayer(MapLayer.Circle);
      map.removeSource(MapSource.CircleSource);
    }
  }
}
