import { useEffect, useMemo } from "react";
import { Box, Table, Typography } from "@esgian/esgianui";

import {
  calculateFilterCount,
  filterDataBasedOnDynamicKeys,
} from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getRowsPerPage,
  getSearchText,
  getThemeMode,
} from "../../store/selector/common";
import {
  getSelectedFilter,
  getSortingInfo,
  getVesselAvailabilityList,
} from "../../store/selector/vesselAvailability";
import {
  setRowsPerPage,
  setSelectedInstallationId,
} from "../../store/slice/common";
import {
  fetchVesselAvailabilityList,
  setSortingInfo,
} from "../../store/slice/vesselAvailability";
import { GenericType, IdNamePair, VesselAvailability } from "../../types";
import { FilterBtn } from "../FilterBtn";
import { Loading } from "../Loading";

export const VesselAvailabilityList = () => {
  const dispatch = useDispatch();

  const theme = useSelector(getThemeMode);
  const searchText = useSelector(getSearchText);
  const rowsPerPage = useSelector(getRowsPerPage);
  const sortingInfo = useSelector(getSortingInfo);
  const selectedFilter = useSelector(getSelectedFilter);
  const vesselAvailabilityList = useSelector(getVesselAvailabilityList);

  const COLUMNS = useMemo(() => {
    return [
      {
        label: "Vessel Name",
        name: "vesselName",
        options: {
          setCellProps: () => ({ style: { cursor: "pointer" } }),
          customBodyRender: (value: IdNamePair) => value || "N/A",
        },
      },
      {
        label: "Vessel Type",
        name: "vesselType",
      },
      { label: "Owner", name: "owner" },
      { label: "Draught (m)", name: "draught" },
      { label: "Max water depth (m)", name: "maxWaterDepth" },
      { label: "Max hook height (m)", name: "maxHookHeight" },
      { label: "Short boom capacity (mt)", name: "shortBoomMass" },
      { label: "	Vessel capability", name: "vesselCapability" },
    ];
  }, []);

  const data = useMemo(() => {
    let list = filterDataBasedOnDynamicKeys(
      vesselAvailabilityList ?? [],
      selectedFilter,
    );

    if (searchText?.length && list?.length) {
      list = list?.filter((item: VesselAvailability) =>
        isMatch(item, [searchText]),
      );
    }
    return list;
  }, [searchText, selectedFilter, vesselAvailabilityList]);

  useEffect(() => {
    dispatch(fetchVesselAvailabilityList());
  }, [dispatch]);

  return (
    <Box>
      {data ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ py: 2 }}>
              {`Displaying ${data?.length} Vessel Availability`}
            </Typography>
            <FilterBtn count={calculateFilterCount(selectedFilter)} />
          </Box>
          <Table
            app="wind"
            columnDefs={[]}
            columns={COLUMNS}
            data={data}
            elevation={0}
            mode={theme}
            options={{
              download: true,
              search: false,
              downloadOptions: {
                filename: `floating_technology_data.csv`,
              },
              expandableRows: false,
              filter: false,
              filterType: "multiselect",
              hideToolbarMargin: false,
              pagination: true,
              responsive: "standard",
              rowsPerPage,
              onChangeRowsPerPage: (numberOfRows: number) => {
                dispatch(setRowsPerPage(numberOfRows));
              },
              onRowClick: (_: number, rowMeta: GenericType) => {
                const { dataIndex }: GenericType = rowMeta;
                const item: GenericType = data[dataIndex];
                dispatch(setSelectedInstallationId(item?.vesselId || null));
              },
              rowsPerPageOptions: [25, 50, 100],
              selectableRows: "none",
              sortOrder: sortingInfo,
              onColumnSortChange: (name: string, direction: string) => {
                dispatch(setSortingInfo({ name, direction }));
              },
              showResponsive: true,
              tableBodyHeight: "calc(100vh - 290px)",
              toolbar: true,
              print: false,
              viewColumns: true,
              onViewColumnsChange: () => {},
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
