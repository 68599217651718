import { GenericType } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = undefined;

type ResponseData = { data: GenericType[] };

export const getAuthor: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = () => ({
  url: `/Lookups/Authors?api-version=1.0`,
  method: "GET",
});
