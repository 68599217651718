import { RootState } from "../../types";

export const getEquipmentContractList = (state: RootState) => {
  return state.contract.equipmentContract;
};

export const getEquipmentContractChart = (state: RootState) => {
  return state.contract.equipmentChartData;
};

export const getEquipmentContractFilterOptions = (state: RootState) => {
  return state.contract.equipmentFilterOptions;
};

export const getEquipmentContractSelectedFilter = (state: RootState) => {
  return state.contract.equipmentSelectedFilter;
};

export const getVesselContractList = (state: RootState) => {
  return state.contract.vesselContract;
};

export const getVesselContractChart = (state: RootState) => {
  return state.contract.vesselChartData;
};

export const getVesselContractFilterOptions = (state: RootState) => {
  return state.contract.vesselFilterOptions;
};

export const getVesselContractSelectedFilter = (state: RootState) => {
  return state.contract.vesselSelectedFilter;
};

export const getVesselIsLoading = (state: RootState) => {
  return state.contract.vesselIsLoading;
};

export const getEquipmentIsLoading = (state: RootState) => {
  return state.contract.equipmentIsLoading;
};

export const getSortingInfo = (state: RootState) => {
  return state.contract.sortingInfo;
};
