import { useMemo } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@esgian/esgianui";

import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getList,
  getSelectedItem,
  getSelectedTimePeriod,
  getTab,
} from "../../store/selector/powerOutput";
import {
  setNewSelectedItem,
  setTab,
  setTimePeriod,
} from "../../store/slice/powerOutput";
import { GenericType, SelectedFilterItem } from "../../types";
import { PowerOutputEnum, PowerOutputPeriodEnum } from "../../types/enum";
import { StyledAutocomplete } from "../StyledAutocomplete";

const useStyles = () => {
  return {
    secondRow: {
      display: "flex",
      mt: 2,
    },
    formControl: {
      width: 340,
      "& .MuiSelect-select": {
        height: "38px !important",
        lineHeight: "36px",
      },
      mr: 2,
    },
    periodBox: {
      ml: 2,
    },
    filterDate: {
      "& .MuiFormControl-root": {
        width: 340,
      },
    },
  };
};

export const PowerOutputHeader = () => {
  const dispatch = useDispatch();
  const timePeriod = useSelector(getSelectedTimePeriod);
  const selectedItem = useSelector(getSelectedItem);
  const list = useSelector(getList);
  const tab: PowerOutputEnum = useSelector(getTab);
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTab(event?.target?.value as PowerOutputEnum));
  };

  const title = useMemo(() => {
    return tab
      .replace(/-/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }, [tab]);

  const convertEnumToArray = useMemo(() => {
    return Object.keys(PowerOutputPeriodEnum)
      .filter((key) => isNaN(Number(key)))
      .map((key) => ({
        title: key,
        id: PowerOutputPeriodEnum[key as unknown as PowerOutputPeriodEnum],
      }));
  }, []);

  return (
    <Box>
      <Box>
        <RadioGroup
          row={true}
          aria-labelledby="view-radio-buttons"
          name="view-radio-buttons"
          value={tab}
          onChange={handleChange}
        >
          <FormControlLabel
            value={PowerOutputEnum.WindFarm}
            control={<Radio />}
            label="Wind Farm"
          />
          <FormControlLabel
            value={PowerOutputEnum.Developer}
            control={<Radio />}
            label="Developer"
          />
          <FormControlLabel
            value={PowerOutputEnum.TurbineSupplier}
            control={<Radio />}
            label="Turbine"
          />
          <FormControlLabel
            value={PowerOutputEnum.Country}
            control={<Radio />}
            label="Country"
          />
          <FormControlLabel
            value={PowerOutputEnum.ControlArea}
            control={<Radio />}
            label="Control Area"
          />
        </RadioGroup>
      </Box>
      <Box sx={classes.secondRow}>
        <FormControl sx={classes.formControl}>
          <StyledAutocomplete
            disableClearable={true}
            disableCloseOnSelect={false}
            options={list?.[tab] || []}
            selectedItem={
              list?.[tab]?.find(
                (p: GenericType) => selectedItem?.[0] === p.id,
              ) as SelectedFilterItem
            }
            setSelectedItem={(value: GenericType) =>
              dispatch(setNewSelectedItem(value?.id))
            }
            labelKey="name"
            multipleSelection={false}
            size="medium"
            placeholder={title}
            maxHeight="44vh"
          />
        </FormControl>
        <FormControl sx={classes.formControl}>
          <StyledAutocomplete
            disableClearable={true}
            disableCloseOnSelect={false}
            options={(convertEnumToArray as unknown as GenericType[]) || []}
            selectedItem={timePeriod as unknown as SelectedFilterItem}
            setSelectedItem={(value: GenericType) => {
              dispatch(setTimePeriod(value as SelectedFilterItem));
            }}
            labelKey="title"
            multipleSelection={false}
            size="medium"
            placeholder="Time Period"
            maxHeight="44vh"
          />
        </FormControl>
        <FormControl sx={classes.filterDate}></FormControl>
      </Box>
    </Box>
  );
};
