import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";

import { api } from "../../api/api";
import { AllContracts, GenericType, IdTypePair, RootState } from "../../types";

type State = {
  isLoading: boolean;
  contractList: AllContracts | null;
};

const initialState: State = {
  isLoading: true,
  contractList: null,
};

export const fetchFactoryContractList = createAsyncThunk(
  "factoryContract/list",
  async (id: string, { getState }) => {
    try {
      const state = getState() as RootState;
      const countries = state.common.countries;

      const { data } = await api.windFactoryContract.getFactoryInfo({
        ids: [id],
      });
      const contracts = data?.[0];
      const mappedData = {
        "turbine-contracts": contracts?.turbineContracts?.map(
          ({
            contract: { contract, windProject },
            turbineModel,
            numOrdered,
          }: GenericType) => ({
            awardDate: contract?.awardDate
              ? moment(contract?.awardDate).format("YYYY-MM-DD")
              : "",
            windFarm: windProject?.name,
            turbineModel: turbineModel?.name,
            country: countries?.find(
              (item) => item.id === windProject?.countryId,
            )?.name,
            numberOfUnits: numOrdered,
          }),
        ),
        "floating-contracts": contracts?.floatingContracts?.map(
          ({
            contract: { contract, windProject },
            numOrdered,
            floatingTechnology,
          }: GenericType) => {
            return {
              awardDate: contract?.awardDate
                ? moment(contract?.awardDate).format("YYYY-MM-DD")
                : "",
              windFarm: windProject?.name,
              country: countries?.find(
                (item) => item.id === windProject?.countryId,
              )?.name,
              numberOfUnits: numOrdered,
              equipmentType: contract?.equipmentTypes
                ?.map((eq: IdTypePair) => eq.type)
                .join(", "),
              technologyType: floatingTechnology?.technology,
            };
          },
        ),
        "foundation-contracts": contracts?.foundationContracts?.map(
          ({
            contract: { contract, windProject },
            numOrdered,
            fixedFoundationMetrics,
          }: GenericType) => {
            return {
              awardDate: contract?.awardDate
                ? moment(contract?.awardDate).format("YYYY-MM-DD")
                : "",
              windFarm: windProject?.name,
              country: countries?.find(
                (item) => item.id === windProject?.countryId,
              )?.name,
              numberOfUnits: numOrdered,
              foundationType: contract?.equipmentTypes?.[0]?.type,
              maxFoundationLength: fixedFoundationMetrics?.maxFoundationDepthM,
              maxFoundationWeight: fixedFoundationMetrics?.maxFoundationMassMt,
              maxFoundationDiameter:
                fixedFoundationMetrics?.maxFoundationDiameterM,
              maxFoundationThickness:
                fixedFoundationMetrics?.maxFoundationThicknessM,
            };
          },
        ),
        "cable-contracts": contracts?.cableContracts?.map(
          ({
            contract: { contract, windProject },
            numOrdered,
            cable,
          }: GenericType) => ({
            awardDate: contract?.awardDate
              ? moment(contract?.awardDate).format("YYYY-MM-DD")
              : "",
            windFarm: windProject?.name,
            country: countries?.find(
              (item) => item.id === windProject?.countryId,
            )?.name,
            factories: data[0].factoryInformation.name,
            voltage: 33,
            numberOfCables: numOrdered,
            cableLength: cable?.cableLengthKm,
          }),
        ),
        "substation-contracts": contracts?.substationContracts?.map(
          ({
            contract: { contract, windProject },
            substation,
            numOrdered,
          }: GenericType) => ({
            awardDate: contract?.awardDate
              ? moment(contract?.awardDate).format("YYYY-MM-DD")
              : "",
            windFarm: windProject?.name,
            country: countries?.find(
              (item) => item.id === windProject?.countryId,
            )?.name,
            substation: substation?.name,
            components: contract?.equipmentTypes?.[0]?.type,
            outputCurrent: substation?.currentOutType?.type,
            voltageIn: substation?.arrayCableVoltageKv,
            voltageOut: substation?.exportCableVoltageKv,
            topsideMass: substation?.topsideMassMt,
            topsideHeight: substation?.topsideHeightM,
            topsideWidth: substation?.topsideWidthM,
            topsideLength: substation?.topsideLengthM,
            foundationType: substation?.fixedFoundationType?.type,
            foundationMass: substation?.foundationMassMt,
            foundationLength: substation?.foundationLengthM,
            foundationWidth: substation?.foundationWidthM,
            totalMass: substation?.totalMassMt,
            numberOfLegs: numOrdered,
          }),
        ),
        "tower-contracts": contracts?.towerContracts?.map(
          ({
            contract: { contract, windProject },
            numOrdered,
            towerMetrics,
          }: GenericType) => ({
            awardDate: contract?.awardDate
              ? moment(contract?.awardDate).format("YYYY-MM-DD")
              : "",
            windFarm: windProject?.name,
            country: countries?.find(
              (item) => item.id === windProject?.countryId,
            )?.name,
            numberOfUnits: numOrdered,
            numberOfSection: towerMetrics?.totalNumSections,
            towerHeight: towerMetrics?.towerHeightM,
            towerMass: towerMetrics?.towerMassMt,
          }),
        ),
      };
      return mappedData;
    } catch (err) {
      return null;
    }
  },
);

const factoryContractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchFactoryContractList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchFactoryContractList.rejected, (state) => {
        state.isLoading = false;
        state.contractList = null;
      })
      .addCase(fetchFactoryContractList.fulfilled, (state, action) => {
        state.contractList = action.payload;
        state.isLoading = false;
      });
  },
});

// export const { setSelectedFilter } = contractSlice.actions;
export const factoryContract = factoryContractSlice.reducer;
