import { TurbineModel } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = undefined;

type ResponseData = { data: TurbineModel[] };

export const getSupplyDemand: ApiMethodDeclaration<ParamsData, ResponseData> = (
  data,
) => ({
  url: `/Market/SupplyAndDemand?api-version=1.0`,
  method: "Get",
  data,
});
