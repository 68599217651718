import { FloatingTechnology } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = { ids?: number[] };

type ResponseData = { data: FloatingTechnology[] };

export const getFloatingTechnology: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/Wind/FloatingTechnology?api-version=1.0`,
  method: "POST",
  data,
});
