import { getEsgianTheme } from "@esgian/esgianui";
import * as turf from "@turf/turf";
import {
  FeatureIdentifier,
  FeatureSelector,
  Map,
  MapboxGeoJSONFeature,
  MapMouseEvent,
} from "mapbox-gl";
import mapboxgl from "mapbox-gl";
import {
  FeatureTypeEnum,
  GenericType,
  MapFeature,
  MapLayer,
  MapSource,
  THEME,
  ThemeModeEnum,
} from "../../../types";

export let Popup: mapboxgl.Popup | null = null;

export const resetMapState = (map: Map) => {
  if (map) {
    map.getCanvas().style.cursor = "";
    map.removeFeatureState({
      source: MapSource.CountrySource,
      sourceLayer: MapLayer.CountryBoundaries,
    });
    if (map.getSource(MapSource.Polygon)) {
      map.removeFeatureState({
        source: MapSource.Polygon,
        sourceLayer: MapLayer.Polygon,
      });
    }
    if (map.getSource(MapSource.Point)) {
      map.removeFeatureState({
        source: MapSource.Point,
        sourceLayer: MapLayer.Point,
      });
    }
    Popup?.remove();
  }
};

function generatePopupHTML(
  containedItems: MapFeature[],
  hoveredFeatureId: number
): string {
  const list: string = containedItems
    ?.sort((a: any, b: any) =>
      a.properties.name.localeCompare(b.properties.name)
    )
    .map(
      (containedItem: any) =>
        `<span class="overlap-popup-item" data-id=${containedItem.properties.id} data-region-type-id=${containedItem.properties.regionTypeId}>${containedItem.properties.name} (${containedItem.properties.regionTypeId === FeatureTypeEnum.LeaseArea ? "Lease Area" : "Project"})</span>`
    )
    .join("");
  return `<div class="content"><div class="overlap-popup-list">${list}</div></div>`;
}

const getOverlappingFeatures = (
  features: MapFeature[],
  targetFeature: MapFeature,
  alreadyChecked: Set<string | number> = new Set()
): MapFeature[] => {
  const remainingFeatures = features.filter(
    (feature) => !alreadyChecked.has(feature.id)
  );
  const overlappingFeatures = remainingFeatures.filter((feature) => {
    try {
      let overlap = null;
      if (
        (targetFeature.geometry.type === "Polygon" ||
          targetFeature.geometry.type === "MultiPolygon") &&
        (feature.geometry.type === "Polygon" ||
          feature.geometry.type === "MultiPolygon")
      ) {
        overlap = turf.intersect(targetFeature.geometry, feature.geometry);
      } else if (
        (targetFeature.geometry.type === "Polygon" ||
          targetFeature.geometry.type === "MultiPolygon") &&
        feature.geometry.type === "Point"
      ) {
        overlap = turf.booleanPointInPolygon(
          feature.geometry,
          targetFeature.geometry
        );
      }

      // Check if feature is a polygon and targetFeature is a point
      else if (
        (feature.geometry.type === "Polygon" ||
          feature.geometry.type === "MultiPolygon") &&
        targetFeature.geometry.type === "Point"
      ) {
        overlap = turf.booleanPointInPolygon(
          targetFeature.geometry,
          feature.geometry
        );
      } else if (
        feature.geometry.type === "Point" &&
        targetFeature.geometry.type === "Point"
      ) {
        const point1 = turf.point(feature.geometry.coordinates);
        const point2 = turf.point(targetFeature.geometry.coordinates);
        const distance = turf.distance(point1, point2, { units: "meters" });
        if (distance <= 100) {
          overlap = true;
        } else {
          false;
        }
      }

      // Return true if there is an overlap or if the point is within the polygon
      return overlap !== null && overlap !== false;
    } catch (err) {
      return false;
    }
  });

  // Mark the found features as checked
  overlappingFeatures.forEach((feature) => alreadyChecked.add(feature.id));

  // Recursively find overlaps for each newly found feature
  const nestedOverlaps = overlappingFeatures.flatMap((feature) =>
    getOverlappingFeatures(features, feature, alreadyChecked)
  );

  return [...overlappingFeatures, ...nestedOverlaps];
};

const getContainedProject = (map: Map, targetPolygonId: any): MapFeature[] => {
  const source: any = map.getSource(MapSource.Polygon);
  const pointSource: any = map.getSource(MapSource.Point);
  const undefinedSource: any = map.getSource("undefined-projects-source");
  const allUndefinedProjectIcon = undefinedSource?._data.features;
  const allFeatures: MapFeature[] = [
    ...source?._data.features,
    ...pointSource?._data.features,
  ];
  const targetedItem = allFeatures.find((item) => item.id === targetPolygonId);
  if (!targetedItem) return [];
  const overlappingFeatures = getOverlappingFeatures(allFeatures, targetedItem);

  const filteredUndefinedProjectIcons =
    allUndefinedProjectIcon?.filter((feature: MapFeature) =>
      turf.booleanPointInPolygon(feature.geometry as any, targetedItem as any)
    ) ?? [];
  return [...overlappingFeatures, ...filteredUndefinedProjectIcons];
};

const highlightFeature = (map: Map, feature: GenericType) => {
  map.setFeatureState(feature as FeatureSelector, { highlighted: true });
};

const findMostEasternCoordinate = (projects: MapFeature[]) => {
  if (projects.length === 0) {
    return null;
  }

  let mostEasternCoord = null;
  let maxLongitude = -Infinity;

  const processCoordinates = (coords: any[]) => {
    if (typeof coords[0] === "number" && coords.length >= 2) {
      if (coords[0] > maxLongitude) {
        maxLongitude = coords[0];
        mostEasternCoord = coords;
      }
    } else if (Array.isArray(coords)) {
      coords.forEach((subCoords) => processCoordinates(subCoords));
    }
  };

  projects.forEach((project) => {
    processCoordinates(project.geometry.coordinates);
  });
  return mostEasternCoord;
};

export const handleMousemove = (
  e: MapMouseEvent,
  map: Map,
  onSelect: (id: number, regionTypeId: number) => void
) => {
  const polygonSource = map.getSource(MapSource.Polygon);
  const features: MapboxGeoJSONFeature[] = map.queryRenderedFeatures(e.point, {
    layers: polygonSource
      ? [MapLayer.Country, MapLayer.Polygon, MapLayer.Point]
      : [MapLayer.Country],
  });

  if (features.length) {
    resetMapState(map);
    Popup = new mapboxgl.Popup({
      closeButton: true,
      closeOnClick: true,
      className: "hover-popup",
      maxWidth: "fit-content",
      offset: 0,
    });
    const firstFeature = features[0];
    if (!firstFeature) return
    if (firstFeature?.layer?.id === MapLayer.Country) {
      map.getCanvas().style.cursor = "pointer";
      highlightFeature(map, firstFeature);
    }
    if (
      [MapLayer.Polygon, MapLayer.Point, MapLayer.Point].includes(
        firstFeature?.layer?.id as MapLayer
      )
    ) {
      let containedProject: MapFeature[] = getContainedProject(
        map,
        firstFeature.id
      );
      if (containedProject.length > 1) {
        const hoverCoordinates = findMostEasternCoordinate(containedProject);
        if (!hoverCoordinates) return;
        Popup.setLngLat(hoverCoordinates)
          .setHTML(
            generatePopupHTML(containedProject, firstFeature?.properties?.id)
          )
          .addTo(map);
        const popupContent = document.querySelector(".content");
        popupContent?.addEventListener("mouseover", (event) => {
          map.removeFeatureState({
            source: MapSource.Polygon,
            sourceLayer: MapLayer.Polygon,
          });
          map.removeFeatureState({
            source: MapSource.Point,
            sourceLayer: MapLayer.Point,
          });
          const clickedElement = event.target as HTMLElement;
          const id = clickedElement.dataset.id;
          const selectedFeature = map.querySourceFeatures(MapSource.Polygon, {
            filter: ["==", "id", Number(id)],
          });
          const selectedPointFeature = map.querySourceFeatures(
            MapSource.Point,
            {
              filter: ["==", "id", Number(id)],
            }
          );
          if (selectedFeature.length) {
            highlightFeature(map, {
              id: selectedFeature[0].id,
              source: MapSource.Polygon,
            });
          } else if (selectedPointFeature.length) {
            highlightFeature(map, {
              id: selectedPointFeature[0].id,
              source: MapSource.Point,
            });
          } else {
            const undefinedSelectedFeature = map.querySourceFeatures(
              "undefined-projects-source",
              {
                filter: ["==", "id", Number(id)],
              }
            );
            if (undefinedSelectedFeature[0]) {
              highlightFeature(map, {
                id: undefinedSelectedFeature[0].id,
                source: "undefined-projects-source",
              });
            }
          }
        });

        popupContent?.addEventListener("click", (event) => {
          const clickedElement = event.target as HTMLElement;
          const id = clickedElement.dataset.id;
          const regionTypeId = clickedElement.dataset.regionTypeId;

          if (id && regionTypeId) {
            onSelect(Number(id), Number(regionTypeId));
          }

        });
      } else {
        if (
          firstFeature.properties?.regionTypeId === FeatureTypeEnum.Project ||
          firstFeature.properties?.regionTypeId === FeatureTypeEnum.LeaseArea
        ) {
          if (firstFeature.source === MapSource.Polygon) {
            const hoverLabelSource = map.getSource(
              MapSource.PolygonLabel
            ) as mapboxgl.GeoJSONSource;
            hoverLabelSource.setData({
              type: "FeatureCollection",
              features: [firstFeature],
            });
          }

          if (firstFeature.source === MapSource.Point) {
            const hoverPointLabelSource = map.getSource(
              MapSource.PointLabel
            ) as mapboxgl.GeoJSONSource;

            hoverPointLabelSource.setData({
              type: "FeatureCollection",
              features: [firstFeature],
            });
          }
        }

        if (
          firstFeature.properties?.regionTypeId === FeatureTypeEnum.LeaseArea
        ) {
          const hoverLabelSource = map.getSource(
            MapSource.PolygonLabel
          ) as mapboxgl.GeoJSONSource;
          hoverLabelSource.setData({
            type: "FeatureCollection",
            features: [firstFeature],
          });

          const hoverPointLabelSource = map.getSource(
            MapSource.PointLabel
          ) as mapboxgl.GeoJSONSource;

          hoverPointLabelSource.setData({
            type: "FeatureCollection",
            features: [firstFeature],
          });
        }
      }
      map.getCanvas().style.cursor = "pointer";
      highlightFeature(map, firstFeature);
    }
  } else {
    if (map.getSource(MapSource.PolygonLabel)) {
      (map.getSource(MapSource.PolygonLabel) as mapboxgl.GeoJSONSource).setData(
        {
          type: "FeatureCollection",
          features: [],
        }
      );
    }

    if (map.getSource(MapSource.PointLabel)) {
      (map.getSource(MapSource.PointLabel) as mapboxgl.GeoJSONSource).setData({
        type: "FeatureCollection",
        features: [],
      });
    }
  }
};

export const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);

  return {
    filterButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "32px",
      width: "32px",
      borderRadius: "50%",
      border: "none",
      marginTop: "16px",
      overflow: "visible",
      position: "relative",
      background: theme.palette.common.white,
    },
    blurred: {
      filter: "blur(2px)",
    },
    map: {
      overflow: "hidden",
      height: "100%",
      width: "100%",
      "& .popup-content": {
        cursor: "pointer",
      },
      "& .marker-popup": {
        color: "black",
      },
      "& .mapboxgl-canvas": {
        width: "100%",
      },
      "& .mapboxgl-control-container": {
        "& .mapboxgl-ctrl-top-right": {
          right: "16px",
        },
        "& .mapboxgl-ctrl-top-left": {
          left: "16px",
        },
        "& .mapboxgl-ctrl-zoom-in, .mapboxgl-ctrl-zoom-out": {
          background: theme.palette.common.white,
        },
        "& .mapboxgl-ctrl-filter-button": {
          cursor: "pointer",
        },
        "& .filter-count": {
          position: "absolute",
          background: "#80DFEB",
          height: "12px",
          width: "12px",
          fontSize: 10,
          top: -5,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          color: "black",
        },
      },
      "& .hover-popup": {
        minWidth: 180,
        "& .mapboxgl-popup-close-button": {
          fontSize: 26,
        },
        "& .mapboxgl-popup-content": {
          background: "#FFFFFFCC",
          color: "black",
          p: 2,
          borderRadius: 1,
          "& .title": {
            width: 128,
            fontSize: 16,
            fontWeight: 600,
            m: 0,
          },
          "& .overlap-popup-list": {
            display: "flex",
            flexDirection: "column",
            fontSize: 16,
            fontWeight: 400,

            "& .overlap-popup-item": {
              display: "block",
              cursor: "pointer",
              mt: 1,
              "&::before": {
                px: 0.75,
                content: '"\u2022"',
                color:
                  themeMode === ThemeModeEnum.Dark
                    ? theme.palette.common.black
                    : theme.palette.common.white,
                fontWeight: "bold",
                display: "inline-block",
              },
              "&:hover": {
                color: "blue",
                "&::before": {
                  color: "blue",
                },
              },
              "& .hovered-overlap-popup-item": {
                color: "blue",
                "&::before": {
                  color: "blue",
                },
              },
            },
          },
        },
        "& .mapboxgl-popup-tip": {
          display: "none",
        },
      },
    },
  };
};

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timer: NodeJS.Timeout | null = null;
  let abortController = new AbortController();

  return function (...args: Parameters<T>): void {
    if (timer) clearTimeout(timer);

    // Abort previous request
    if (abortController) abortController.abort();

    // Create a new AbortController for the new request
    abortController = new AbortController();

    timer = setTimeout(() => {
      func(...args, abortController.signal);
    }, delay);
  };
}
