import { FC } from "react";
import {
  Box,
  getEsgianTheme,
  IconButton,
  Typography,
  VisibilityOffOutlinedIcon,
} from "@esgian/esgianui";

import { useSelector } from "../../../hooks/use-selector";
import { getThemeMode } from "../../../store/selector/common";
import { THEME } from "../../../types";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);

  return {
    container: {
      position: "absolute",
      top: 16,
      left: 16,
      height: 140,
      width: 251,
      backgroundColor: theme.palette.secondary.dark,
      zIndex: 2,
      p: 2,
    },
    row: {
      display: "flex",
      alignItems: "center",
      mb: 1,
    },
    colorBox: {
      height: 12,
      width: 12,
    },
    title: {
      ml: 1,
    },
    icon: {
      height: 16,
      width: 16,
      ml: 1,
    },
    hideBtn: {
      p: 0,
    },
  };
};

type Prop = {
  open: boolean;
  handleClose: () => void;
};

export const TargetInfoModel: FC<Prop> = ({ open, handleClose }) => {
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);

  return (
    <Box sx={{ ...classes.container, display: open ? "block" : "none" }}>
      <Box sx={classes.row}>
        <Box
          sx={{
            ...classes.colorBox,
            background: "#C4A9F9",
          }}
        />
        <Typography variant="body2" sx={classes.title}>
          Total
        </Typography>
      </Box>
      <Box sx={classes.row}>
        <Box
          sx={{
            ...classes.colorBox,
            background: "#FFFFFF",
          }}
        />
        <Typography variant="body2" sx={classes.title}>
          Floating foundation
        </Typography>
      </Box>
      <Box sx={classes.row}>
        <Box
          sx={{
            ...classes.colorBox,
            background: "#9DF9FF",
          }}
        />
        <Typography variant="body2" sx={classes.title}>
          Fixed foundation
        </Typography>
      </Box>
      <IconButton sx={classes.hideBtn} onClick={handleClose}>
        <VisibilityOffOutlinedIcon fontSize="small" />
        <Typography variant="body2" sx={classes.title}>
          Hide
        </Typography>
      </IconButton>
    </Box>
  );
};
