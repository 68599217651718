import { Box } from "@esgian/esgianui";

import blurbg from "../../assets/blur-bg.png";
import { EnquiryModal } from "../../components/Modal/EnquiryModal";

export const Enquiry = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${blurbg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
      }}
    >
      <EnquiryModal />
    </Box>
  );
};
