import { AnySourceData, Map } from "mapbox-gl";

import { THRESHOLD_ZOOM } from "../../../helper/map";
import { Factory, MapLayer, MapSource } from "../../../types";

export class FactoryIcon {
  async init(map: Map) {
    const sourceDefinition: AnySourceData = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
      cluster: false,
    };

    map.addSource(MapSource.FactorySource, sourceDefinition);

    map.addLayer({
      id: MapLayer.Factory,
      type: "circle",
      source: MapSource.FactorySource,
      paint: {
        "circle-radius": ["interpolate", ["linear"], ["zoom"], 0, 3, 22, 20],
        "circle-color": "#0000FF",
        "circle-opacity": 0.8,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#000000",
        "circle-stroke-opacity": 0.9,
      },
      minzoom: THRESHOLD_ZOOM,
    });

    map.addLayer({
      id: `${MapLayer.Factory}-text`,
      type: "symbol",
      source: MapSource.FactorySource,
      layout: {
        "text-field": ["get", "name"],
        "text-anchor": "center",
        "text-offset": [0, 0],
        "text-radial-offset": 1,
        "text-justify": "auto",
        "text-allow-overlap": true,
        "text-ignore-placement": true,
        "text-size": 14,
      },
      paint: {
        "text-opacity": [
          "case",
          ["boolean", ["feature-state", "hover"], false],
          1,
          0,
        ],
        "text-color": "#000000",
        "text-halo-color": "#FFFFFF",
        "text-halo-width": 2,
        "text-halo-blur": 0,
      },
      minzoom: THRESHOLD_ZOOM,
    });
  }

  update(map: Map, data: Factory[]) {
    const features: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: "FeatureCollection",
      features: data?.map((item: Factory) => ({
        id: item.id,
        type: "Feature",
        properties: {
          name: item.name,
        },
        geometry: {
          type: "Point",
          coordinates: [item.coordinates.lon, item.coordinates.lat],
        },
      })),
    };
    const source = map.getSource(
      MapSource.FactorySource,
    ) as mapboxgl.GeoJSONSource;
    if (source) {
      source.setData(features);
      this.visible(map);
    }
  }

  disable(map: Map) {
    map.setLayoutProperty(MapLayer.Factory, "visibility", "none");
    map.setLayoutProperty(`${MapLayer.Factory}-text`, "visibility", "none");
  }

  visible(map: Map) {
    map.setLayoutProperty(MapLayer.Factory, "visibility", "visible");
    // map.setLayoutProperty(`${MapLayer.Factory}-text`, "visibility", "visible");
  }

  remove(map: Map) {
    if (map.getSource(MapSource.FactorySource)) {
      map.removeLayer(MapLayer.Factory);
      // map.removeLayer(`${MapLayer.Factory}-text`);
      map.removeSource(MapSource.FactorySource);
    }
  }
}
