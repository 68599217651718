/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment/moment";

import { api } from "../../api/api";
import { GenericType, RootState, SortingInfo } from "../../types";

type State = {
  isLoading: boolean;
  ppaProjectList: GenericType[] | null;
  selectedFilter: GenericType;
  sortingInfo: SortingInfo;
  visibleColumns: string[];
};

const initialState: State = {
  isLoading: true,
  ppaProjectList: [],
  selectedFilter: {},
  sortingInfo: {
    direction: "asc",
    name: "project",
  },
  visibleColumns: [
    "project",
    "country",
    "projectStatus",
    "ppaType",
    "buyer",
    "seller",
    "sizeMW",
    "contractStatus",
    "durationYears",
    "contractStart",
    "contractEnd",
    "notes",
  ],
};
export const fetchPPAContractList = createAsyncThunk(
  "windProjectsPPA/list",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const { data } = await api.project.getProjectListInfo({});

      const ppaWindProjects: GenericType[] = [];

      data.forEach((item: GenericType) => {
        if (item.contracts && item.contracts.windProjectContracts.length) {
          const contractsList = item.contracts.windProjectContracts;
          contractsList.forEach((contract: GenericType) => {
            if (contract.contractWorkType?.id === 7) {
              ppaWindProjects.push({
                id: item.windProject?.id,
                project: item.windProject?.name,
                country: state.common.countries?.find(
                  (c) => c.id === item.windProject?.countryId,
                )?.name,
                projectStatus: item.windProject?.projectStatus?.status,
                ppaType: contract.contractType?.type,
                buyer: contract.contractedCompanies[0]?.contractee?.name,
                seller: contract.contractedCompanies[0]?.contractor?.name,
                sizeMW: contract.ppaDetails?.sizeMW
                  ? parseFloat(contract.ppaDetails?.sizeMW).toFixed(2)
                  : "",
                contractStatus: contract.contractStatus?.status,
                durationYears: contract.ppaDetails?.duration,
                contractStart: contract.startDate
                  ? moment(contract.startDate).year()
                  : "",
                contractEnd: contract.endDate
                  ? moment(contract.endDate).year()
                  : "",
                notes: contract.ppaDetails?.notes,
              });
            }
          });
        }
      });

      return ppaWindProjects;
    } catch (err) {
      return [];
    }
  },
);

const ppaContractSlice = createSlice({
  name: "ppaContract",
  initialState,
  reducers: {
    setSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      state.selectedFilter = action.payload;
    },
    setSortingInfo: (state, action: PayloadAction<SortingInfo>) => {
      state.sortingInfo = action.payload;
    },
    setVisibleColumns: (state, action: PayloadAction<string | string[]>) => {
      const { payload } = action;

      if (Array.isArray(payload)) {
        state.visibleColumns = payload;
      } else if (state.visibleColumns.includes(payload)) {
        state.visibleColumns = state.visibleColumns.filter(
          (col) => col !== payload,
        );
      } else {
        state.visibleColumns.push(payload);
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPPAContractList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchPPAContractList.rejected, (state) => {
        state.isLoading = false;
        state.ppaProjectList = [];
      })
      .addCase(fetchPPAContractList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ppaProjectList = action.payload;
      });
  },
});

export const { setSelectedFilter, setSortingInfo, setVisibleColumns } =
  ppaContractSlice.actions;
export const ppaContract = ppaContractSlice.reducer;
