import { Bid } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = number;

type ResponseData = { data: Bid };

export const getBidById: ApiMethodDeclaration<ParamsData, ResponseData> = (
  id: number,
) => ({
  url: `/Wind/Bid/${id}?api-version=1.0`,
  method: "GET",
});
