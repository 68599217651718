import { useEffect } from "react";
import { Box } from "@esgian/esgianui";

import { FilterBtn } from "../../components/FilterBtn";
import { FilterModal } from "../../components/Modal/FilterModal";
import { SupplyDemandChart } from "../../components/SupplyDemandChart";
import { calculateFilterCount, getChangedValues } from "../../helper/fllter";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getFilterModal, getProjects } from "../../store/selector/common";
import {
  getDateList,
  getInitialFilter,
  getSelectedFilter,
  getSupplyDemandFilterOptions,
  getSupplyDemandList,
} from "../../store/selector/supplyDemand";
import { fetchLookup, setFilterModal } from "../../store/slice/common";
import {
  fetchDateList,
  fetchSupplyDemandList,
  setSelectedFilter,
} from "../../store/slice/supplyDemand";

export const SupplyDemand = () => {
  const dispatch = useDispatch();
  const isFilterModalOpen = useSelector(getFilterModal);
  const options = useSelector(getSupplyDemandFilterOptions);
  const selectedFilter = useSelector(getSelectedFilter);
  const initialFilter = useSelector(getInitialFilter);
  const dateList = useSelector(getDateList);
  const data = useSelector(getSupplyDemandList);
  const projects = useSelector(getProjects);

  useEffect(() => {
    dispatch(fetchDateList());
    dispatch(fetchLookup(["projects"]));
  }, [dispatch, selectedFilter]);

  useEffect(() => {
    if (dateList.length && projects.length) {
      dispatch(fetchSupplyDemandList());
    }
  }, [dispatch, dateList, projects]);

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <FilterBtn
          count={calculateFilterCount(
            getChangedValues(initialFilter, selectedFilter),
          )}
        />
      </Box>
      <SupplyDemandChart data={data} categories={dateList} />
      {options ? (
        <FilterModal
          open={isFilterModalOpen}
          handleClose={() => dispatch(setFilterModal(false))}
          selectedFilter={selectedFilter || {}}
          onChange={(filterValues) => dispatch(setSelectedFilter(filterValues))}
          options={options}
          initialFilter={initialFilter}
        />
      ) : null}
    </Box>
  );
};
