import { RootState } from "../../types";

export const getInstallationList = (state: RootState) => {
  return state.installation.installationList;
};

export const getSelectedFilter = (state: RootState) => {
  return state.installation.selectedFilter;
};

export const getInstallationFilterOptions = (state: RootState) => {
  return state.installation.filterOptions;
};

export const getIsLoading = (state: RootState) => {
  return state.installation.isLoading;
};

export const getSortingInfo = (state: RootState) => {
  return state.installation.sortingInfo;
};

export const getVisibleColumns = (state: RootState) => {
  return state.installation.visibleColumns;
};

export const getImoMapping = (state: RootState) => {
  return state.installation.imoMapping;
};
