import { useEffect } from "react";
import { Box } from "@esgian/esgianui";

import { Loading } from "../../components/Loading";
import { TargetMap } from "../../components/TargetMap";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import { getIsLookupLoading } from "../../store/selector/common";
import {
  fetchLookup,
  setSearchObjectCoordinates,
  setSearchText,
} from "../../store/slice/common";

export const Target = () => {
  const dispatch = useDispatch();

  const isLookupLoading = useSelector(getIsLookupLoading);

  useEffect(() => {
    dispatch(fetchLookup(["company"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(undefined));
      dispatch(setSearchObjectCoordinates(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      {!isLookupLoading ? <TargetMap /> : <Loading />}
    </Box>
  );
};
