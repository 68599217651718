/* eslint-disable indent */
import { FC, useState } from "react";
import {
  ArrowDropDownIcon,
  ArrowDropUpIcon,
  Box,
  Button,
  getEsgianTheme,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@esgian/esgianui";
import moment from "moment";

import { ReactComponent as DetailsIcon } from "../../../assets/details.svg";
import { useAuth } from "../../../hooks/use-auth";
import { useSelector } from "../../../hooks/use-selector";
import { getThemeMode } from "../../../store/selector/common";
import {
  AuthorizeUser,
  GenericType,
  THEME,
  ThemeModeEnum,
} from "../../../types";
import { BlurredComponent } from "../../Blurred";
import DottedList from "../DottedList.tsx";

const styles = {
  container: {
    mt: 1,
  },
  titleBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: 600,
  },
  listItem: {
    pt: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 1,
  },

  shareBtn: {
    display: "flex",
    alignItems: "center",
    minHeight: 0,
    minWidth: 0,
    padding: 0,
    ml: "auto",
  },
  subTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mt: 1,
  },
};

const SubTypeSection: FC<{ subType: GenericType }> = ({ subType }) => (
  <Box>
    {Object.keys(subType.prop).map((subProp) => (
      <Box key={subProp} sx={styles.subTitle}>
        <Typography>{subType.prop[subProp].key}</Typography>
        <Typography>{subType.data[subProp]}</Typography>
      </Box>
    ))}
  </Box>
);

const Btn: FC<{
  id?: string | number;
  handleClick?: (id: string | number) => void;
}> = ({ handleClick, id }) => (
  <Button
    variant="text"
    size="small"
    sx={styles.shareBtn}
    onClick={handleClick ? () => handleClick(id || 2) : undefined}
    endIcon={<DetailsIcon />}
  >
    Details
  </Button>
);

const NestedList: FC<{
  property: string;
  item: GenericType;
  properties: GenericType;
}> = ({ property, item, properties }) => {
  const themeMode = useSelector(getThemeMode);
  const theme = getEsgianTheme(themeMode, THEME);
  return (
    <Box sx={styles.container}>
      <Box>
        <Typography variant="body2">{properties[property].name}</Typography>
      </Box>
      <List disablePadding={true}>
        {item[property].map((nestedItem: GenericType, j: number) =>
          Object.keys(properties[property].props).map((nestedP) => (
            <ListItem
              disablePadding={true}
              key={nestedP + j}
              sx={{
                display: "flex",
                alignItems: "center",
                "&::before": {
                  pt: 1,
                  content: '"\u2022"',
                  color:
                    themeMode === ThemeModeEnum.Dark
                      ? theme.palette.common.white
                      : theme.palette.common.black,
                  fontWeight: "bold",
                  display: "inline-block",
                },
              }}
            >
              <Box sx={styles.listItem}>
                <Typography sx={{ ml: 1 }} variant="body2">
                  {nestedItem[nestedP]}
                </Typography>
                <Btn
                  id={nestedItem[properties[property].index]}
                  handleClick={properties[property].props[nestedP].handleClick}
                />
              </Box>
            </ListItem>
          )),
        )}
      </List>
    </Box>
  );
};

const ListParagraph: FC<{
  data: GenericType;
  properties: GenericType;
  prop: string;
  isAuthenticated: AuthorizeUser;
}> = ({ properties, prop, data, isAuthenticated }) =>
  properties[prop].key ? (
    <ListItem
      disablePadding={true}
      sx={{ ...styles.listItem, alignItems: "baseline" }}
    >
      <Typography variant="body2" sx={{ minWidth: 100 }}>
        {properties[prop].key}
      </Typography>
      {properties[prop].handleClick ? (
        <BlurredComponent
          isAuthenticated={isAuthenticated}
          publicItem={properties[prop]?.publicItem}
        >
          <Btn
            handleClick={properties[prop].handleClick}
            id={data[properties[prop].index]}
          />
        </BlurredComponent>
      ) : (
        <BlurredComponent
          isAuthenticated={isAuthenticated}
          publicItem={properties[prop]?.publicItem}
        >
          <Typography variant="body2" sx={{ textAlign: "right" }}>
            {data[prop]
              ? properties[prop].type === "date"
                ? moment(data[prop]).format("DD-MM-YYYY")
                : `${data[prop]}${
                    properties[prop].unit ? ` ${properties[prop].unit}` : ""
                  }`
              : ""}
          </Typography>
        </BlurredComponent>
      )}
    </ListItem>
  ) : null;

const DropDownList: FC<{
  title: string;
  name?: string;
  subTitleKey?: string;
  index?: string;
  properties: GenericType;
  data: GenericType;
  subType?: GenericType;
  titleHandler?: GenericType;
  defaultExpand?: boolean;
  dottedList?: GenericType;
}> = ({
  title,
  subTitleKey,
  name,
  properties,
  index,
  data,
  subType,
  titleHandler,
  defaultExpand,
  dottedList,
}) => {
  const { isAuthenticated } = useAuth();
  const [isOpenArray, setIsOpenArray] = useState<string[]>(
    defaultExpand ? [`${name}0`] : [],
  );
  const themeMode = useSelector(getThemeMode);
  const theme = getEsgianTheme(themeMode, THEME);

  const handleDropDonClick = (key: string) => {
    if (!isOpenArray.includes(key)) {
      setIsOpenArray([...isOpenArray, key]);
    } else {
      setIsOpenArray(isOpenArray.filter((i: string) => i !== key));
    }
  };

  const renderHeader = (listTitle: string, item: GenericType, key: string) => {
    const isOpen = isOpenArray.includes(key);
    return (
      <Box sx={{ ...styles.listItem, justifyContent: "flex-start" }}>
        <IconButton
          sx={{
            p: 0,
            pr: "2px",
          }}
          onClick={() => handleDropDonClick(key)}
        >
          {!isOpen ? (
            <ArrowDropDownIcon
              sx={{
                color:
                  themeMode === ThemeModeEnum.Dark
                    ? theme.palette.common.white
                    : theme.palette.common.black,
                fontSize: "20px",
              }}
            />
          ) : (
            <ArrowDropUpIcon
              sx={{
                color:
                  themeMode === ThemeModeEnum.Dark
                    ? theme.palette.common.white
                    : theme.palette.common.black,
                fontSize: "20px",
              }}
            />
          )}
        </IconButton>
        <BlurredComponent isAuthenticated={isAuthenticated} publicItem={false}>
          <Typography sx={styles.titleBar} variant="body2">
            {listTitle}
          </Typography>
        </BlurredComponent>
        {titleHandler && (
          //Implemented handle click with Project Modal
          <Btn
            handleClick={titleHandler.handleClick}
            id={item[titleHandler?.index] || index}
          />
        )}
      </Box>
    );
  };

  return (
    <Box key={title}>
      <Box sx={styles.titleBar}>
        <Typography variant="body2">{title}</Typography>
      </Box>
      {subType && <SubTypeSection subType={subType} />}
      <Box>
        {data.length > 0 &&
          data.map((item: GenericType, i: number) => {
            const listTitle: string = subTitleKey
              ? item?.[subTitleKey]
              : `${name}`;
            return (
              <Box key={`${listTitle}${i}`}>
                {renderHeader(listTitle, item, `${name}${i}`)}
                {isOpenArray.includes(`${name}${i}`) ? (
                  <List sx={{ pl: 3 }} disablePadding={true}>
                    {Object.keys(properties).map((p) =>
                      Array.isArray(item[p]) ? (
                        item[p] ? (
                          <NestedList
                            key={p}
                            property={p}
                            item={item}
                            properties={properties}
                          />
                        ) : null
                      ) : (
                        <ListParagraph
                          key={p}
                          data={item}
                          prop={p}
                          properties={properties}
                          isAuthenticated={isAuthenticated}
                        />
                      ),
                    )}
                    {dottedList ? (
                      <Box sx={{ mt: 1 }}>
                        <DottedList
                          title={dottedList.name}
                          data={item[dottedList.dataKey]}
                          displayIndex={false}
                          displayDetailedBtn={false}
                          titleVariant="body2"
                          // handleClick={properties[propertyKey].handleClick}
                        />
                      </Box>
                    ) : null}
                  </List>
                ) : null}
              </Box>
            );
          })}
      </Box>
    </Box>
  );
};

export default DropDownList;
