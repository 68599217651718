import { RootState } from "../../types";

export const getTurbineModelFilterOptions = (state: RootState) => {
  return state.turbineModels.filterOptions;
};

export const getTurbineModelList = (state: RootState) => {
  return state.turbineModels.turbineModelList;
};

export const getSelectedFilter = (state: RootState) => {
  return state.turbineModels.selectedFilter;
};

export const getSortingInfo = (state: RootState) => {
  return state.turbineModels.sortingInfo;
};
