import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "../../api/api";
import {
  Country,
  GenericType,
  RootState,
  Target,
  TargetResponse,
} from "../../types";

type State = {
  isLoading: boolean;
  targetList: Target[] | null;
  selectedFilter: GenericType;
};

const initialState: State = {
  isLoading: false,
  targetList: null,
  selectedFilter: {},
};

export const fetchTargetList = createAsyncThunk(
  "target/list",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const { data } = await api.lookup.getTarget();
    const list = data
      .filter(
        (item: TargetResponse) =>
          item?.centroidCoordinate?.lat && item?.centroidCoordinate?.lon,
      )
      .map((item: TargetResponse) => ({
        country: state.common.countries?.find(
          (c: Country) => c.id === item.offshoreWindTarget.countryId,
        )?.name,
        countryId: item.offshoreWindTarget.countryId,
        landRegion: item.region.name,
        year: item.offshoreWindTarget.targetYear,
        capacity: item.offshoreWindTarget.targetCapacityMW,
        announcedDate: item.offshoreWindTarget.announcedDate,
        type: item.offshoreWindTarget.offshoreWindTargetType.type,
        centroidCoordinates: item.centroidCoordinate,
      }));
    const output = Object.values(list);
    return output as Target[];
  },
);

const targetSlice = createSlice({
  name: "target",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTargetList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchTargetList.rejected, (state) => {
        state.isLoading = false;
        state.targetList = null;
      })
      .addCase(fetchTargetList.fulfilled, (state, action) => {
        state.targetList = action.payload;
        state.isLoading = false;
      });
  },
});

// export const {} = targetSlice.actions;
export const target = targetSlice.reducer;
