import { useMemo } from "react";
import { useState } from "react";
import { Box, Slider } from "@esgian/esgianui";

type Prop = {
  min: number;
  max: number;
  onChange: (e: number) => void;
};

export const TimeLineSlider: React.FC<Prop> = ({ min, max, onChange }) => {
  const [selectedItem, setSelectedItem] = useState(min);
  const marks = useMemo(() => {
    return Array.from({ length: max - min + 1 }, (_, index) => index + min).map(
      (item) => ({ value: item, label: item }),
    );
  }, [min, max]);

  const handleChange = (event: Event, newValue: number) => {
    if (selectedItem !== newValue) {
      setSelectedItem(newValue);
      onChange(newValue);
    }
  };
  return (
    <Box>
      <Slider
        valueLabelDisplay="auto"
        step={1}
        marks={marks}
        track={false}
        min={min}
        max={max}
        onChange={handleChange}
        sx={{
          marginX: 3,
          width: "98%",
        }}
      />
    </Box>
  );
};
