import { CountryGeo } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = number;
type ResponseData = { data: CountryGeo };

export const getAllCountries: ApiMethodDeclaration<ParamsData, ResponseData> = (
  data,
) => ({
  url: `/Countries/CountryDetails`,
  method: "POST",
  data,
});
