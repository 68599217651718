import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { api } from "../../api/api";
// import { api } from "../../api/api";
import { generateDateList } from "../../helper/fllter";
import {
  FilterItemType,
  GenericType,
  RootState,
  SupplyDemandData,
} from "../../types";

type State = {
  isLoading: boolean;
  supplyDemandList: SupplyDemandData[] | null;
  dateList: string[];
  filterOptions: GenericType | null;
  selectedFilter: GenericType;
  initialFilter: GenericType;
};

const initialState: State = {
  isLoading: false,
  supplyDemandList: null,
  dateList: [],
  filterOptions: {
    turbineSize: {
      options: [0, 20],
      type: FilterItemType.Slider,
      inputLabelText: "Turbine size (MW)",
    },
    dateRange: {
      options: ["2023-01", moment().add(12, "years").format("YYYY-MM")],
      type: FilterItemType.Date,
      inputLabelText: "Date Range",
      format: "YYYY MM",
    },
  },
  selectedFilter: {
    dateRange: ["2023-01", moment().add(5, "years").format("YYYY-MM")],
    turbineSize: [0, 20],
  },
  initialFilter: {
    dateRange: ["2023-01", moment().add(5, "years").format("YYYY-MM")],
    turbineSize: [0, 20],
  },
};

export const fetchSupplyDemandList = createAsyncThunk(
  "supply/demand",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const selectedTurbine = state.supplyDemand.selectedFilter.turbineSize;
      const { data } = await api.supplyDemand.getSupplyDemand();
      const list = data?.map((item: GenericType) => {
        // number of contract
        const contractedDemand = item.contractedDemand
          .filter((c: GenericType) => {
            return (
              c?.windProject?.numTurbines >= selectedTurbine[0] &&
              c?.windProject?.numTurbines <= selectedTurbine[1]
            );
          })
          .map((c: GenericType) => ({
            windFarmName: state.common.projects.find(
              (p) => p.windProjectId === c?.windProject?.countryId,
            )?.name,
            country: state.common.countries.find(
              (country) => country.id === c?.windProject?.countryId,
            )?.name,
            models: c?.windProject?.turbineModel
              ?.map((t: GenericType) => t.name)
              .join(", "),
            ...c,
          }));
        // number of project
        const confirmed = item.confirmed
          .filter(
            (c: GenericType) =>
              c?.windProject?.numTurbines >= selectedTurbine[0] &&
              c?.windProject?.numTurbines <= selectedTurbine[1],
          )
          .map((c: GenericType) => ({
            windFarmName: state.common.projects.find(
              (p) => p.windProjectId === c?.windProject?.countryId,
            )?.name,
            country: state.common.countries.find(
              (country) => country.id === c?.windProject?.countryId,
            )?.name,
            models: c?.windProject?.turbineModel
              ?.map((t: GenericType) => t.name)
              .join(", "),
            ...c,
          }));
        // number of project
        const proposed = item.proposed
          .filter(
            (c: GenericType) =>
              c?.windProject?.numTurbines >= selectedTurbine[0] &&
              c?.windProject?.numTurbines <= selectedTurbine[1],
          )
          .map((c: GenericType) => ({
            windFarmName: state.common.projects.find(
              (p) => p.windProjectId === c?.windProject?.countryId,
            )?.name,
            country: state.common.countries.find(
              (country) => country.id === c?.windProject?.countryId,
            )?.name,
            models: c?.windProject?.turbineModel
              ?.map((t: GenericType) => t.name)
              .join(", "),
            ...c,
          }));
        // number of vessel
        const supply = item?.supply
          ?.filter((s: GenericType) => {
            return s?.windVesselInformation?.vesselCapabilities.some(
              (vc: GenericType) => vc?.capability === "Turbine",
            );
          })
          ?.map((s: GenericType) => ({
            vesselName: s?.windVesselInformation?.vesselName,
          }));
        return {
          ...item,
          contractedDemand,
          confirmed,
          proposed,
          supply,
          contractedDemandCount: contractedDemand.length,
          confirmedCount: confirmed.length,
          proposedCount: proposed.length,
          supplyCount: supply.length,
        };
      });

      return list;
    } catch (err) {
      console.log(">>", err);
      return err;
    }
  },
);

export const fetchDateList = createAsyncThunk(
  "date/list",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const list = generateDateList(
      state.supplyDemand.selectedFilter.dateRange[0],
      state.supplyDemand.selectedFilter.dateRange[1],
    );
    return list;
  },
);

const supplyDemandSlice = createSlice({
  name: "supplyDemand",
  initialState,
  reducers: {
    setSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      state.selectedFilter = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSupplyDemandList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchSupplyDemandList.rejected, (state) => {
        state.isLoading = false;
        state.supplyDemandList = null;
      })
      .addCase(fetchSupplyDemandList.fulfilled, (state, action) => {
        state.supplyDemandList = action.payload;
        state.isLoading = false;
      });
    builder
      .addCase(fetchDateList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchDateList.rejected, (state) => {
        state.isLoading = false;
        state.dateList = [];
      })
      .addCase(fetchDateList.fulfilled, (state, action) => {
        state.dateList = action.payload;
        state.isLoading = false;
      });
  },
});

export const { setSelectedFilter } = supplyDemandSlice.actions;
export const supplyDemand = supplyDemandSlice.reducer;
