/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";

import { FilterItemType, GenericType, SelectedFilterItem } from "../types";

export const mapFilterData = (selectedFilter: GenericType) => {
  if (!Object.keys(selectedFilter).length) return {};
  const locationIdsByType = selectedFilter?.location?.reduce(
    (acc: { [key: string]: number[] }, item: SelectedFilterItem) => {
      let type = item.type;
      if (type === "other region types" || type === "sea region") {
        type = "region";
      }
      if (type) {
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(item.id);
      }

      return acc;
    },
    {},
  );

  return {
    countryIds: locationIdsByType?.country || undefined,
    regionIds: locationIdsByType?.region || undefined,
    leaseRoundStatusIds: selectedFilter?.leaseRoundStatus || undefined,
    leaseRoundTypeIds: selectedFilter?.leaseRoundType || undefined,
    projectStatusIds: selectedFilter?.projectStatus || undefined,
    projectTypeIds: selectedFilter?.projectType || undefined,
    companyIds: selectedFilter?.company || undefined,
    startDate: selectedFilter?.timeline?.[0]
      ? moment().year(selectedFilter?.timeline[0]).startOf("year")
      : undefined,
    endDate: selectedFilter?.timeline?.[1]
      ? moment().year(selectedFilter?.timeline[1]).endOf("year")
      : undefined,
    awardStartDate: selectedFilter?.awardDate?.[0]
      ? moment().year(selectedFilter?.awardDate[0]).startOf("year")
      : undefined,
    awardEndDate: selectedFilter?.awardDate?.[1]
      ? moment().year(selectedFilter?.awardDate[1]).endOf("year")
      : undefined,
    operationStartDate: selectedFilter?.operationDate?.[0]
      ? moment().year(selectedFilter?.operationDate[0]).startOf("year")
      : undefined,
    operationEndDate: selectedFilter?.operationDate?.[1]
      ? moment().year(selectedFilter?.operationDate[1]).endOf("year")
      : undefined,
  };
};

export const aggregateMinMaxValues = (
  data: GenericType[],
  attributes: string[],
) => {
  const aggregatedAttributes = attributes.reduce((acc: GenericType, attr) => {
    acc[attr] = { min: 0, max: 0 };
    return acc;
  }, {});

  data.forEach((item) => {
    attributes.forEach((attr) => {
      const value = Math.ceil(item[attr]);
      if (value) {
        aggregatedAttributes[attr].min = Math.min(
          aggregatedAttributes[attr].min,
          value,
        );
        aggregatedAttributes[attr].max = Math.max(
          aggregatedAttributes[attr].max,
          value,
        );
      }
    });
  });

  return aggregatedAttributes;
};

export const mapSetToArray = (
  set: any,
  mapFunction: (item: string) => GenericType,
) => [...set].sort().map(mapFunction);

export const transformAttributesForFilter = (attributes: GenericType) =>
  Object.fromEntries(
    Object.keys(attributes).map((key) => [
      key,
      [Math.ceil(attributes[key].min), Math.ceil(attributes[key].max)],
    ]),
  );

export const transformNumericAttributesForFilter = (attributes: GenericType) =>
  Object.fromEntries(
    Object.keys(attributes)
      .map((key) => {
        // Check if max value is 0, return undefined for the entire entry if so
        if (attributes[key].max === 0) {
          return [key, undefined];
        }

        return [
          key,
          {
            options: [
              Math.ceil(attributes[key].min),
              Math.ceil(attributes[key].max),
            ],
            type: FilterItemType.Slider,
            inputLabelText: key
              .split(/(?=[A-Z])/)
              .join(" ")
              .toLocaleLowerCase()
              .replace(/^./, (firstLetter) => firstLetter.toUpperCase()),
          },
        ];
      })
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      .filter(([_, value]) => value !== undefined), // Filter out entries that are undefined
  );

function isValidDate(value: string) {
  const format = "YYYY MM";
  // Parse the value with the specified format
  return moment(value, format, true).isValid();
}

export function filterDataBasedOnDynamicKeys<
  GenericType extends Record<string, any>,
>(
  data: GenericType[],
  filters: Record<
    string,
    { id: string; value: string }[] | [number, number] | null
  >,
): GenericType[] {
  // Return all data if no filters are selected or if all filters are null or empty
  const noFiltersSelected =
    Object.keys(filters).length === 0 ||
    Object.keys(filters).every(
      (key) => filters[key] === null || filters[key]!.length === 0,
    );

  if (noFiltersSelected) {
    return data;
  }

  return data.filter((item) => {
    return Object.keys(filters).every((key) => {
      const filterCriteria = filters[key];
      if (filterCriteria === null || filterCriteria.length === 0) {
        return true;
      }
      if (typeof filterCriteria[0] === "number") {
        const [min, max] = filterCriteria as [number, number];
        return item[key] >= min && item[key] <= max;
      } else if (
        filterCriteria?.length === 2 &&
        isValidDate(filterCriteria[0] as unknown as string) &&
        isValidDate(filterCriteria[1] as unknown as string)
      ) {
        if (item[key]) {
          let start = filterCriteria[0] as unknown as string;
          let end = filterCriteria[1] as unknown as string;
          start = start.replace(" ", "-").concat("-01");
          end = end.replace(" ", "-");
          return moment(item[key]).isBetween(
            moment(start).format("YYYY-MM-DD"),
            moment(end, "YYYY-MM").format("YYYY-MM-") +
              moment(end, "YYYY-MM").daysInMonth(),
            null,
            "[]",
          );
        }
        return false;
      } else if (
        Array.isArray(filterCriteria) &&
        filterCriteria.length > 0 &&
        filterCriteria[0] !== null &&
        typeof filterCriteria[0] === "object" &&
        "value" in filterCriteria[0]
      ) {
        return (filterCriteria as { id: string; value: any }[]).some(
          (criteria) => {
            if (item?.[key]?.includes(",")) {
              const values = item[key].split(",").map((v: string) => v.trim());
              return values.includes(criteria.value);
            }
            return item[key] === criteria.value;
          },
        );
      }

      // Exclude the item for unexpected filter types
      return false;
    });
  });
}

export const generateOptions = (data: GenericType[], properties: string[]) => {
  const options: GenericType = properties.reduce(
    (acc, prop) => ({ ...acc, [prop]: new Set() }),
    {},
  );
  data.forEach((item) => {
    properties.forEach((prop) => {
      const value = item[prop];

      if (Array.isArray(value)) {
        value.forEach((arrayItem) => options[prop].add(arrayItem));
      } else if (value) {
        options[prop].add(value);
      }
    });
  });

  const mappedOptions: GenericType = {};
  Object.keys(options).forEach((key) => {
    const labelText = key
      ?.split(/(?=[A-Z])/)
      ?.join(" ")
      ?.toLocaleLowerCase();

    mappedOptions[key] = {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText:
        labelText === "imo" || labelText === "mmsi"
          ? labelText.toUpperCase()
          : labelText.charAt(0).toUpperCase() + labelText.slice(1),

      options: Array.from(options[key])
        .map((item) => ({
          id: item,
          value: item,
        }))
        .sort((a: GenericType, b) => {
          if (typeof a.value === "number" && typeof b.value === "number") {
            return a.value - b.value;
          } else {
            return a?.value?.localeCompare(b.value);
          }
        }),
    };
  });
  return mappedOptions;
};

export const calculateFilterCount = (selectedFilter: GenericType) => {
  let count = 0;

  function traverse(o: GenericType) {
    for (const key in o) {
      if (Object.prototype.hasOwnProperty.call(o, key)) {
        if (Array.isArray(o[key]) && o[key]?.length) {
          count++;
        } else if (typeof o[key] === "object" && o[key] !== null) {
          traverse(o[key]);
        }
      }
    }
  }

  traverse(selectedFilter);
  return count;
};

export const generateDateList = (
  startDate: string,
  endDate: string,
): string[] => {
  const startMoment = moment(startDate, "YYYY-MM");
  const endMoment = moment(endDate, "YYYY-MM");
  const dateList: string[] = [];

  let currentMoment = startMoment;
  while (currentMoment <= endMoment) {
    dateList.push(currentMoment.format("MMM YYYY"));
    currentMoment = currentMoment.add(1, "month");
  }

  return dateList;
};

export const sortChildrenByDates = (node: GenericType): GenericType | null => {
  try {
    if (node?.children?.length) {
      node?.children.forEach((child: GenericType) =>
        sortChildrenByDates(child),
      );
      node?.children.sort((a: GenericType, b: GenericType) => {
        if (a.level === 3) {
          return a.name.localeCompare(b.name);
        } else {
          const openDateCompare = a?.openDate?.localeCompare(b?.openDate);
          const closeDateCompare = a?.closeDate?.localeCompare(b?.closeDate);
          const awardDateCompare = a?.awardDate?.localeCompare(b?.awardDate);

          return openDateCompare || closeDateCompare || awardDateCompare;
        }
      });
    }
    return node;
  } catch (err) {
    return null;
  }
};

export const searchOptions = (
  options: GenericType,
  keys: string[],
): GenericType[] => {
  const list: GenericType[] = [];
  keys.forEach((item) => list.push(...(options[item] || [])));

  return list;
};

export const sortValues = (values: GenericType) => {
  return values.sort((a: GenericType, b: GenericType) => {
    if (typeof a.value === "number" && typeof b.value === "number") {
      return a.value - b.value;
    } else {
      const valueA = a?.value ?? "";
      const valueB = b?.value ?? "";
      return valueA.localeCompare(valueB);
    }
  });
};

export const arraysEqual = (arr1: GenericType[], arr2: GenericType[]) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export const getChangedValues = (
  initialFilter: GenericType,
  selectedFilter: GenericType,
) => {
  const changedValues: GenericType = {};

  for (const key in selectedFilter) {
    if (Object.prototype.hasOwnProperty.call(selectedFilter, key)) {
      if (
        Array.isArray(selectedFilter[key]) &&
        Array.isArray(initialFilter[key])
      ) {
        if (!arraysEqual(selectedFilter[key], initialFilter[key])) {
          changedValues[key] = selectedFilter[key];
        }
      } else if (selectedFilter[key] !== initialFilter[key]) {
        changedValues[key] = selectedFilter[key];
      }
    }
  }

  return changedValues;
};

export const generateDateFilter = (name: string): GenericType => {
  return {
    type: FilterItemType.Date,
    inputLabelText: name
      ?.split(/(?=[A-Z])/)
      ?.join(" ")
      ?.toLocaleLowerCase()
      .replace(/^./, (firstLetter) => firstLetter.toUpperCase()),
    format: "YYYY MM",
    labelKey: "value",
  };
};

export const getUniqueItemsById = (items: GenericType[]): GenericType[] => {
  const itemMap = new Map();

  items.forEach((item) => {
    itemMap.set(item.id, item);
  });

  return Array.from(itemMap.values());
};
