import { sortValues } from "../../helper/fllter";
import { FilterItemType, RootState } from "../../types";

export const getPolygons = (state: RootState) => {
  return state.windLeasingAndProject.polygons;
};

export const getInfoModal = (state: RootState) => {
  return state.windLeasingAndProject.isInfoModalOpen;
};

export const getLeaseRegionLookup = (state: RootState) => {
  return state.windLeasingAndProject.leaseRegionLookup;
};

export const getBlurred = (state: RootState) => {
  return state.windLeasingAndProject.blurred;
};

export const getCountryListInfo = (state: RootState) => {
  return state.windLeasingAndProject.countryListInfo;
};

export const getLeaseListInfo = (state: RootState) => {
  return state.windLeasingAndProject.leaseListInfo;
};

export const getLeaseRoundListInfo = (state: RootState) => {
  return state.windLeasingAndProject.leaseRoundListInfo;
};

export const getProjectListInfoListInfo = (state: RootState) => {
  return state.windLeasingAndProject.projectListInfo;
};

export const getListViewIsLoading = (state: RootState) => {
  return state.windLeasingAndProject.isLoading;
};

export const getListViewSelectedTab = (state: RootState) => {
  return state.windLeasingAndProject.listViewSelectedTab;
};

export const getSelectedFilter = (state: RootState) => {
  return state.windLeasingAndProject.selectedFilter;
};

export const getFilterOptions = (state: RootState) => {
  const countries = sortValues(
    state.common.countries?.map((item) => ({
      id: item.id,
      value: item.name,
      type: "country",
    })),
  );

  const landArea = sortValues(
    state.common.landArea?.map((item) => ({
      id: item.regionId,
      value: item.regionName,
      type: "other region types",
    })),
  );

  const seaArea = sortValues(
    state.common.seaArea.map((item) => ({
      id: item.regionId,
      value: item.regionName,
      type: "sea region",
    })),
  );

  return {
    location: {
      type: FilterItemType.Autocomplete,
      inputLabelText: "Location",
      labelKey: "value",
      groupBy: "type",
      options: [...countries, ...landArea, ...seaArea],
    },
    leaseRoundStatus: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Lease round status",
      options: sortValues(
        state.common.leaseRoundStatus?.map((item) => ({
          id: item.id,
          value: item.status,
        })),
      ),
    },
    leaseRoundType: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Lease area type",
      options: sortValues(
        state.common.leaseRoundType?.map((item) => ({
          id: item.id,
          value: item.type,
        })),
      ),
    },
    projectStatus: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Project Status",
      options: sortValues(
        state.common.projectStatus?.map((item) => ({
          id: item.id,
          value: item.status,
        })),
      ),
    },
    projectType: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Project Type",
      options: sortValues(
        state.common.projectType?.map((item) => ({
          id: item.id,
          value: item.type,
        })),
      ),
    },
    company: {
      type: FilterItemType.Autocomplete,
      labelKey: "value",
      inputLabelText: "Company",
      options: sortValues(
        state.common.company?.map((item) => ({
          id: item.company.id,
          value: item.company.companyName,
        })),
      ),
    },
    timeline: {
      type: FilterItemType.Slider,
      options: [1990, 2060],
      inputLabelText: "Time period",
      labelKey: "value",
    },
    operationDate: {
      type: FilterItemType.Slider,
      options: [1990, 2060],
      inputLabelText: "Operation period",
      labelKey: "value",
    },
    awardDate: {
      type: FilterItemType.Slider,
      options: [1990, 2060],
      inputLabelText: "Award period",
      labelKey: "value",
    },
  };
};

export const getProjectFilterOptions = (state: RootState) => {
  return state.windLeasingAndProject.projectFilterOptions;
};

export const getProjectSelectedFilter = (state: RootState) => {
  return state.windLeasingAndProject.projectSelectedFilter;
};

export const getMapActiveLegends = (state: RootState) => {
  return state.windLeasingAndProject.mapActiveLegends;
};

export const getSortingInfo = (state: RootState) => {
  return state.windLeasingAndProject.sortingInfo;
};

export const getVisibleColumns = (state: RootState) => {
  return state.windLeasingAndProject.visibleColumns;
};
