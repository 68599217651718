import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Box, Divider, getEsgianTheme, Modal } from "@esgian/esgianui";
import moment from "moment";

import { api } from "../../../api/api";
import { getErrorMessage } from "../../../helper/errorHandling";
import { useSelector } from "../../../hooks/use-selector";
import {
  getSelectedCountry,
  getThemeMode,
} from "../../../store/selector/common";
import {
  GenericType,
  // GenericType,
  ICountryDrawer,
  LeaseRoundCountry,
  MapSource,
  MESSAGE,
  THEME,
  ThemeModeEnum,
} from "../../../types";
import { Loading } from "../../Loading";
import DropDownList from "../DropDownList";
import Paragraph from "../Paragraph";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    modalContainer: {
      "& .MuiDialog-container": {
        alignItems: "start",
        position: "absolute",
        right: 0,
        height: "auto",
        width: "22.5rem",
      },
      "& .MuiBackdrop-root": {
        opacity: "0 !important",
      },
      "& .MuiPaper-root": {
        margin: 0,
        width: 1,
        height: "100vh",
        borderRadius: 0,
        backgroundColor:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.black
            : theme.palette.common.white,
        backgroundImage: "none",
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
    },
    divider: {
      my: 3,
    },
    contentBox: {
      py: 3,
      px: 2,
    },
  };
};

export const CountryDrawer: FC<{
  onClose: () => void;
  onLeaseRoundClick: (id: number) => void;
  onSelectLeaseArea: (id: number) => void;
}> = ({ onClose, onLeaseRoundClick, onSelectLeaseArea }) => {
  const selectedCountry = useSelector(getSelectedCountry);
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);

  const [data, setData] = useState<ICountryDrawer | null>(null);

  const fetchCountryData = async (countryInfo: {
    name: string;
    id: number;
  }) => {
    try {
      const { data: countryData } = await api.country.getCountry(
        countryInfo.id,
      );

      const today = moment();
      const filterLeaseRounds = (
        filterFn: (item: LeaseRoundCountry, today: moment.Moment) => void,
      ) =>
        countryData.leaseRoundInformation.filter((item: LeaseRoundCountry) =>
          filterFn(item, today),
        );

      const result = {
        ...countryData,
        country: countryInfo.name,
        leaseRoundsScheduled: filterLeaseRounds((item: LeaseRoundCountry) => {
          return moment(item.leaseRoundOpen).isAfter(today);
        }),
        currentLeaseRounds: filterLeaseRounds(
          (item: LeaseRoundCountry) =>
            (moment(item.leaseRoundOpen).isSameOrBefore(today, "day") &&
              moment(item.leaseRoundClose).isSameOrAfter(today, "day")) ||
            (moment(item.leaseRoundClose).isBefore(today) &&
              moment(item.leaseAwardDate).isAfter(today)),
        ),
        leaseRoundsCompleted: filterLeaseRounds(
          (item: LeaseRoundCountry) =>
            moment(item.leaseRoundClose).isBefore(today) &&
            moment(item.leaseAwardDate).isSameOrBefore(today, "day"),
        ),
        windProjectInformation: {
          ...countryData.windProjectInformation,
          offshoreWindTargets:
            countryData?.windProjectInformation?.offshoreWindTargets
              .map((t: GenericType) => ({
                ...t,
                targetType: t.offshoreWindTargetType.type,
              }))
              .sort(
                (a: GenericType, b: GenericType) => a.targetYear - b.targetYear,
              ),
        },
      };
      setData(result);
    } catch (err) {
      toast.error(`${MESSAGE.Country}: ${getErrorMessage(err)}`);
    }
  };

  const handleLeaseAreaClick = (leaseAreaId: number) => {
    onClose();
    onSelectLeaseArea(leaseAreaId);
  };

  useEffect(() => {
    setData(null);
    if (selectedCountry) {
      fetchCountryData(selectedCountry);
    }
  }, [selectedCountry]);

  return (
    <Modal
      sx={classes.modalContainer}
      open={!!selectedCountry?.id}
      size="sm"
      key={selectedCountry?.id}
      title={data ? data.country : "Country"}
      handleClose={onClose}
    >
      {data ? (
        <Box sx={classes.contentBox}>
          <DropDownList
            title="Targets"
            name="Target"
            data={data.windProjectInformation.offshoreWindTargets}
            defaultExpand={true}
            properties={{
              targetYear: { key: "Target year" },
              targetCapacityMW: {
                key: "Target capacity",
                unit: "GW",
              },
              targetType: { key: "Target Type" },
              announcedDate: { key: "Date announced", type: "date" },
            }}
          />
          <Divider sx={classes.divider} />
          <Paragraph
            title="Capacity"
            properties={{
              currentInstalledCapacity: {
                key: "Current installed capacity",
                unit: "MW",
              },
              installedPctNxtTgtFulfilled: {
                key: "% of next target fullfilled",
                unit: "%",
                spaceBottom: 2,
              },
              confirmedProjectCapacityMW: {
                key: "Confirmed projects",
                unit: "MW",
              },
              scheduledLeaseRoundCapacityMW: {
                key: "Scheduled lease round capacity",
                unit: "MW",
              },
            }}
            data={data.windProjectInformation.capacity}
          />
          {data.currentLeaseRounds?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Lease rounds - Current lease rounds"
                subTitleKey="leaseRoundName"
                name="leaseRound"
                data={data.currentLeaseRounds}
                defaultExpand={true}
                properties={{
                  leaseRoundStatus: { key: "Status" },
                  timeline: {
                    index: "leaseRoundId",
                    key: "Timeline",
                    handleClick: onLeaseRoundClick,
                  },
                  leaseRoundOpen: { key: "Opens for proposals", type: "date" },
                  leaseRoundClose: {
                    key: "Closes for proposals",
                    type: "date",
                  },
                  leaseAwardDate: { key: "Award announcement", type: "date" },
                  leaseRoundCapacityTgtMW: {
                    key: "Target capacity",
                    unit: "MW",
                  },
                  leaseRegions: {
                    name: "Lease Areas",
                    index: "regionId",
                    props: {
                      regionName: {
                        key: "leaseAreaName",
                        handleClick: handleLeaseAreaClick,
                      },
                    },
                  },
                  projects: {
                    name: MapSource.Polygon,
                    index: "projectId",
                    props: {
                      projectName: {
                        key: "projectName",
                        url: "http://localhost:3000",
                      },
                    },
                  },
                }}
              />
            </>
          ) : null}
          {data.leaseRoundsScheduled?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Lease rounds - Scheduled lease rounds"
                subTitleKey="leaseRoundName"
                name="leaseRound"
                data={data.leaseRoundsScheduled}
                properties={{
                  leaseRoundStatus: { key: "Status" },
                  timeline: {
                    index: "leaseRoundId",
                    key: "Timeline",
                    handleClick: onLeaseRoundClick,
                  },
                  leaseRoundOpen: { key: "Opens for proposals", type: "date" },
                  leaseRoundClose: {
                    key: "Closes for proposals",
                    type: "date",
                  },
                  leaseAwardDate: { key: "Award announcement", type: "date" },
                  leaseRoundCapacityTgtMW: {
                    key: "Target capacity",
                    unit: "MW",
                  },
                  leaseRegions: {
                    name: "Lease Areas",
                    index: "regionId",
                    props: {
                      regionName: {
                        key: "leaseAreaName",
                        handleClick: handleLeaseAreaClick,
                      },
                    },
                  },
                }}
              />
            </>
          ) : null}
          {data.leaseRoundsCompleted?.length ? (
            <>
              <Divider sx={classes.divider} />
              <DropDownList
                title="Lease rounds - Completed lease rounds"
                subTitleKey="leaseRoundName"
                data={data.leaseRoundsCompleted}
                name="leaseRound"
                properties={{
                  leaseRoundStatus: { key: "Status" },
                  leaseAwardDate: { key: "Date awarded", type: "date" },
                  leaseRoundCapacityTgtMW: { key: "Awarded capacity" },
                  leaseRegions: {
                    name: "Lease Areas",
                    index: "regionId",
                    props: {
                      regionName: {
                        key: "leaseAreaName",
                        handleClick: handleLeaseAreaClick,
                      },
                    },
                  },
                  projects: {
                    name: MapSource.Polygon,
                    index: "projectId",
                    props: {
                      projectName: {
                        key: "projectName",
                        handleClick: handleLeaseAreaClick,
                      },
                    },
                  },
                }}
              />
            </>
          ) : null}
        </Box>
      ) : (
        <Loading />
      )}
    </Modal>
  );
};
