import { GenericType } from "../types";

export const isMatch = (
  value: GenericType | string,
  searchStrings: string[],
): boolean => {
  if (value === null || value === undefined) return false;

  if (typeof value === "object" && value !== null) {
    // If it's an object (including array), iterate over its properties or elements
    return Object.values(value).some((innerValue) =>
      isMatch(innerValue, searchStrings),
    );
  }

  // Convert value to string and perform the search
  const valueAsString: string = value.toString().toLowerCase();
  return searchStrings.some((searchString) =>
    valueAsString.includes(searchString.toLowerCase()),
  );
};

export const validateIMONumber = (imoNumber: number) => {
  const stringImo = imoNumber.toString();
  if (typeof stringImo !== "string" || stringImo.length !== 7) {
    return false;
  }

  // Extract the first six digits and the check digit
  const mainDigits = stringImo.slice(0, 6);
  const checkDigit = parseInt(stringImo[6], 10);

  // Factors for multiplication
  const factors = [7, 6, 5, 4, 3, 2];

  // Calculate the sum of products
  let sum = 0;
  for (let i = 0; i < mainDigits.length; i++) {
    sum += parseInt(mainDigits[i], 10) * factors[i];
  }

  // Calculate the expected check digit
  const expectedCheckDigit = sum % 10;

  // Validate the check digit
  return expectedCheckDigit === checkDigit;
};

export const isFullUrl = (urlOrPath: string) => {
  return urlOrPath.startsWith("http://") || urlOrPath.startsWith("https://");
};
