import { RootState } from "../../types";

export const getIsLoading = (state: RootState) => {
  return state.powerOutput.isLoading;
};

export const getSortingInfo = (state: RootState) => {
  return state.powerOutput.sortingInfo;
};

export const getList = (state: RootState) => {
  return state.powerOutput.list;
};

export const getCountry = (state: RootState) => {
  return state.common.countries;
};

export const getOptions = (state: RootState) => {
  return state.powerOutput.filterOptions;
};

export const getSelectedFilter = (state: RootState) => {
  return state.powerOutput.selectedFilter;
};

export const getTab = (state: RootState) => {
  return state.powerOutput.tab;
};

export const getSelectedItem = (state: RootState) => {
  return state.powerOutput.selectedItem;
};

export const getSelectedTimePeriod = (state: RootState) => {
  return state.powerOutput.timePeriod;
};

export const getDateRange = (state: RootState) => {
  return state.powerOutput.dateRange;
};

export const getKeyInfo = (state: RootState) => {
  return state.powerOutput.keyInfo;
};

export const getHistoricalCapacity = (state: RootState) => {
  return state.powerOutput.historicalCapacity;
};
