import { CountryInfo } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = number;
type ResponseData = { data: CountryInfo };

export const getCountry: ApiMethodDeclaration<ParamsData, ResponseData> = (
  id,
) => ({
  url: `/Wind/CountryInformation/${id}?api-version=1.0`,
  method: "GET",
});
