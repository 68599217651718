/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

import { api } from "../../api/api";
import {
  aggregateMinMaxValues,
  generateOptions,
  transformAttributesForFilter,
  transformNumericAttributesForFilter,
} from "../../helper/fllter";
import {
  CableLay,
  GenericType,
  RootState,
  SortingInfo,
  VesselInformation,
} from "../../types";

type State = {
  isLoading: boolean;
  cableLayList: CableLay[] | null;
  filterOptions: GenericType | null;
  selectedFilter: GenericType;
  sortingInfo: SortingInfo;
  visibleColumns: string[];
};

const initialState: State = {
  isLoading: true,
  cableLayList: null,
  filterOptions: null,
  selectedFilter: {},
  sortingInfo: {
    direction: "asc",
    name: "vesselName",
  },
  visibleColumns: [
    "vesselName",
    "owner",
    "design",
    "maxCableCapacity",
    "dateOfDelivery",
    "newbuild",
    "vesselType",
    "status",
    "vesselCapability",
  ]
};

export const fetchCableLayList = createAsyncThunk(
  "cableLay/list",
  async (_, { getState }) => {
    try {
      const state = getState() as RootState;
      const cableTypes = [
        "Subsea ",
        "Pile Driving Barge",
        "Wind Multi Purpose Vessel (MPV)",
        "Fall Pipe Rock Installation Vessel (FPRIV)",
        "Crane Pontoon",
        "Construction Support Vessel (CSV)",
        "Cable Lay Vessel (CLV)",
        "Cable Lay Barge (CLB)",
        "Anchor Handling Tug Vessel",
        "Anchor Handling Tug Supply (AHTS)",
        "Cable Lay Vessel",
      ];
      const vesselTypeIds = state?.common?.vesselType
        ?.filter((item: GenericType) => cableTypes.includes(item.type))
        ?.map((t: GenericType) => t.id);

      if (!vesselTypeIds?.length) {
        return null;
      }

      const { data } = await api.wind.getVesselInformation({
        vesselTypeIds,
      });

      const tableData = data.map(({ vessel }: VesselInformation) => ({
        id: vessel?.id,
        vesselName: vessel?.vesselName,
        owner: vessel?.owners?.map((c) => c.companyName)?.join(", "),
        design: vessel?.vesselDesign?.design,
        maxCableCapacity: vessel?.cableLayingDetails?.capacityMt,
        dateOfDelivery: vessel?.dateOfDelivery
          ? moment(vessel?.dateOfDelivery).format("DD-MM-YYYY")
          : "",
        newbuild: vessel?.dateOfDelivery
          ? moment(vessel?.dateOfDelivery)
            .startOf("day")
            .isSameOrAfter(moment().startOf("day"), "day")
            ? "Yes"
            : "No"
          : "Yes",
        vesselType: vessel?.vesselType?.type,
        status: vessel?.vesselStatus?.status,
        vesselCapability: vessel?.vesselCapabilities
          ?.map((c) => c.capability)
          ?.join(", "),
        class: vessel?.classification?.class,
        imo: vessel?.imo,
        flag: vessel?.flag?.name,
        constructedYard: vessel?.shipyard?.name,
        mmsi: vessel?.mmsi,
        length: vessel?.loa,
        width: vessel?.mouldedBreadth,
        draught: vessel?.draught,
        maxDeckStrength: vessel?.vesselCapacityDetail?.deckStrengthMtSqm,
        deckArea: vessel?.vesselCapacityDetail?.deckAreaSqm,
        maxSpeed: vessel?.maximumSpeed,
        maxPob: vessel?.vesselCapacityDetail?.accommodation,
        primaryTurntableCapacity:
          vessel?.cableLayingDetails?.primaryTurntableCapacityMt,
        primaryOuterDiameter: vessel?.cableLayingDetails?.primaryOuterDiameterM,
        primaryCoreDiameter: vessel?.cableLayingDetails?.primaryCoreDiameterM,
        primaryCoreHeight: vessel?.cableLayingDetails?.primaryCoreHeightM,
        secondaryTurntableCapacity:
          vessel?.cableLayingDetails?.secondaryTurntableCapacityMt,
        secondaryOuterDiameter:
          vessel?.cableLayingDetails?.secondaryOuterDiameterM,
        secondaryCoreHeight: vessel?.cableLayingDetails?.secondayCoreDiameterM,
        maxLayingVelocity: vessel?.cableLayingDetails?.maxLayingVelocityMHr,
        bollardPull: vessel?.vesselCapacityDetail?.bollardPullMt,
        craneType: vessel?.craneDetails
          ?.map((detail) => detail?.craneModel?.craneType?.type)
          .join(", "),
        craneModel: vessel?.craneDetails
          ?.map((detail) => detail?.craneModel?.modelName)
          .join(", "),
      }));

      const groups: GenericType = {
        keyInfo: [
          "vesselName",
          "owner",
          "design",
          "maxCableCapacity",
          "dateOfDelivery",
          "newbuild",
          "vesselType",
          "status",
          "vesselCapability",
        ],
        vesselDetails: [
          "class",
          "imo",
          "flag",
          "constructedYard",
          "mmsi",
          "length",
          "width",
          "draught",
          "maxDeckStrength",
          "deckArea",
          "maxSpeed",
          "maxPob",
        ],
        cableDetails: [
          "primaryTurntableCapacity",
          "primaryOuterDiameter",
          "primaryCoreDiameter",
          "primaryCoreHeight",
          "secondaryTurntableCapacity",
          "secondaryOuterDiameter",
          "secondaryCoreHeight",
          "maxLayingVelocity",
          "bollardPull",
          "craneType",
          "craneModel",
        ],
      };
      const numericAttributesList = [
        "maxCableCapacity",

        "length",
        "width",
        "draught",
        "maxDeckStrength",
        "deckArea",
        "maxSpeed",
        "maxPob",
        "primaryTurntableCapacity",
        "primaryOuterDiameter",
        "primaryCoreDiameter",
        "primaryCoreHeight",
        "secondaryTurntableCapacity",
        "secondaryOuterDiameter",
        "secondaryCoreHeight",
        "maxLayingVelocity",
        "bollardPull",
      ];

      const multiSelectAttributesList = [
        "vesselName",
        "owner",
        "imo",
        "mmsi",
        "design",
        "vesselType",
        "Newbuild",
        "status",
        "vesselCapability",
        "class",
        "flag",
        "constructedYard",
        "craneType",
        "craneModel",
      ];
      let selectedAttributes: GenericType = {};
      const filterOptions: GenericType = {
        keyInfo: {},
        vesselDetails: {},
      };

      Object.keys(groups).forEach((group) => {
        groups[group].forEach((attribute: string) => {
          if (numericAttributesList.includes(attribute)) {
            const currentAttribute = aggregateMinMaxValues(tableData, [
              attribute,
            ]);
            selectedAttributes = {
              ...selectedAttributes,
              ...currentAttribute,
            };
            filterOptions[group] = {
              ...filterOptions[group],
              ...transformNumericAttributesForFilter(currentAttribute),
            };
          } else if (multiSelectAttributesList.includes(attribute)) {
            filterOptions[group][attribute] = generateOptions(tableData, [
              attribute,
            ])[attribute];
          }
        });
      });
      return {
        data: tableData,
        filterOptions,
        selectedFilter: {
          ...transformAttributesForFilter(selectedAttributes),
        },
      };
    } catch (err) {
      console.log(err);
      return null;
    }
  },
);

const cableLaySlice = createSlice({
  name: "cableLay",
  initialState,
  reducers: {
    setSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      state.selectedFilter = action.payload;
    },
    setSortingInfo: (state, action: PayloadAction<SortingInfo>) => {
      state.sortingInfo = action.payload;
    },
    setVisibleColumns: (state, action: PayloadAction<string | string[]>) => {
      const { payload } = action;
      if (Array.isArray(payload)) {
        state.visibleColumns = payload;
      }
      else if (state.visibleColumns.includes(payload)) {
        state.visibleColumns = state.visibleColumns.filter((col) => col !== payload);
      } else {
        state.visibleColumns.push(payload);
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCableLayList.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(fetchCableLayList.rejected, (state) => {
        state.isLoading = false;
        state.cableLayList = null;
      })
      .addCase(fetchCableLayList.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action?.payload?.data) {
          state.cableLayList = action.payload.data;
        }
        if (action?.payload?.filterOptions) {
          state.filterOptions = action.payload.filterOptions;
        }
      });
  },
});

export const { setSelectedFilter, setSortingInfo, setVisibleColumns } = cableLaySlice.actions;
export const cableLay = cableLaySlice.reducer;
