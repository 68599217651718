import { TurbineModel } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = { ids?: number[] };

type ResponseData = { data: TurbineModel[] };

export const getTurbines: ApiMethodDeclaration<ParamsData, ResponseData> = (
  data,
) => ({
  url: `/TurbineModels?api-version=1.0`,
  method: "POST",
  data,
});
