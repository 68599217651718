import { VesselInformation } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = { vesselTypeIds: number[]; assetIds: number[] };

type ResponseData = { data: VesselInformation[] };

export const getVesselInformation: ApiMethodDeclaration<
  ParamsData,
  ResponseData
> = (data) => ({
  url: `/Vessel/VesselInformationByFilters?api-version=1.0`,
  method: "POST",
  data,
});
