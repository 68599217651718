import { Box, CircularProgress } from "@esgian/esgianui";

export const Loading = () => {
  return (
    <Box
      sx={{
        height: "calc(100vh - 66px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};
