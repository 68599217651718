import { FC } from "react";
import { Box, getEsgianTheme, Typography } from "@esgian/esgianui";

import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import { getKeyInfo } from "../../store/selector/powerOutput";
import { GenericType, THEME, ThemeModeEnum } from "../../types";

type Prop = {
  sx?: GenericType;
};

export const PowerOutputKeyBox: FC<Prop> = ({ sx }) => {
  const themeMode = useSelector(getThemeMode);
  const theme = getEsgianTheme(themeMode, THEME);
  const keyInfo = useSelector(getKeyInfo);

  return (
    <Box
      sx={{
        background:
          themeMode === ThemeModeEnum.Dark ? "#171717" : "rgb(217, 217, 217)",
        p: 2,
        height: "100%",
        borderRadius: 4,
        color:
          themeMode === ThemeModeEnum.Dark
            ? theme.palette.common.white
            : theme.palette.common.black,
        ...sx,
      }}
    >
      <Typography variant="h5">Key figures</Typography>
      <Box sx={{ display: "flex", mt: 10 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        >
          <Typography variant="string">Installed capacity (MW)</Typography>
          <Typography variant="h6">{keyInfo?.capacityMW}MW</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        >
          <Typography variant="string">Control area</Typography>
          <Typography variant="h6">{keyInfo?.controlArea}</Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", mt: 10 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        >
          <Typography variant="string">Turbine size (MW)</Typography>
          <Typography variant="h6">{keyInfo?.turbineSize}MW</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        >
          <Typography variant="string">Number of turbines</Typography>
          <Typography variant="h6">{keyInfo?.numberOfTurbine}</Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", mt: 10 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        >
          <Typography variant="string">Turbine supplier</Typography>
          <Typography variant="h6">{keyInfo?.turbineSupplier}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "50%",
          }}
        >
          <Typography variant="string">Turbine model</Typography>
          <Typography variant="h6">{keyInfo?.turbineModel}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
