import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

import "./index.css";

const rootElement = document.getElementById("root") as HTMLElement;
const root = createRoot(rootElement);

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS as string);

root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
