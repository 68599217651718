/* eslint-disable indent */
import {
  createAsyncThunk,
  createSlice,
  current,
  PayloadAction,
} from "@reduxjs/toolkit";
import moment from "moment";

import { api } from "../../api/api";
import { sortChildrenByDates } from "../../helper/fllter";
import {
  Country,
  GenericType,
  Resource,
  RootState,
  SelectedFilterIds,
  Timeline,
  TimelineBid,
  TimelineEvent,
  TimelineLeaseRound,
} from "../../types";

type State = {
  isLoading: boolean;
  startDate: Date | null;
  endDate: Date | null;
  resource: Resource[] | null;
  events: TimelineEvent[] | undefined;
  totalLeaseRoundCount: number | null;
  selectedFilter: GenericType;
};

const initialState: State = {
  isLoading: false,
  startDate: null,
  endDate: null,
  resource: null,
  events: undefined,
  totalLeaseRoundCount: null,
  selectedFilter: {},
};

export const fetchLeaseRoundTimelineEvents = createAsyncThunk(
  "leaseRoundTimeline/events",
  async (param: { filter: SelectedFilterIds; id?: string }, { getState }) => {
    const { filter, id } = param;
    const { common } = getState() as RootState;
    const { countryIds, regionIds, leaseRoundStatusIds } = filter;
    const { data } = await api.lease.getLeaseRoundTimeline({
      countryIds,
      regionIds,
      leaseRoundStatusIds,
    });

    const list = data
      .map((event: Timeline) => ({
        ...event,
        leaseRounds: event.leaseRounds.filter(
          (leaseRound) =>
            leaseRound.openDate !== null &&
            leaseRound.closeDate !== null &&
            leaseRound.awardDate !== null,
        ),
      }))
      .filter((country: Timeline) => {
        if (id) {
          const filteredLeaseRounds = country.leaseRounds.filter(
            (leaseRound) => leaseRound.id === Number(id),
          );
          return filteredLeaseRounds.length > 0;
        }
        return country.leaseRounds.length;
      });
    const resource = list
      .map((item: Timeline) => {
        return {
          id: item.countryId,
          itemId: item.countryId,
          name: common.countries?.find((c) => c.id === item.countryId)?.name,
          level: 1,
          collapsed: true,
          children: item?.leaseRounds?.length
            ? item.leaseRounds?.map((leaseRound: TimelineLeaseRound) => ({
                id: `${leaseRound.id}_${leaseRound.name}_2`,
                itemId: leaseRound.id,
                level: 2,
                name: leaseRound.name,
                openDate: leaseRound.openDate,
                closeDate: leaseRound.closeDate,
                awardDate: leaseRound.awardDate,
                collapsed: true,
                children: leaseRound?.bids?.length
                  ? leaseRound?.bids?.map((bid: TimelineBid, i: number) => ({
                      id: `${bid.id}_${leaseRound.id}_${bid.name}_3`,
                      itemId: bid.id,
                      level: 3,
                      name: bid.name,
                      bidNumber: i,
                      submittedDate: bid.submittedDate,
                      withdrawalDate: bid.withdrawalDate,
                      unsuccessfulDate: bid.unsuccessfulDate,
                      successfulDate: bid.successfulDate,
                      status: bid.status,
                      companies: bid.companies,
                    }))
                  : undefined,
              }))
            : undefined,
        };
      })
      ?.sort((a: Country, b: Country) => a?.name?.localeCompare(b?.name));
    const sortedResource = resource.map((item: Timeline) =>
      sortChildrenByDates(item),
    );
    let currentStartDate = list[0].leaseRounds[0].openDate;
    let currentEndDate = list[0].leaseRounds[0].awardDate;
    let currentLeaseRoundTotal = 0;
    const events: TimelineEvent[] = [];
    let tmpIds = 1;

    list.forEach((country: Timeline) => {
      currentLeaseRoundTotal =
        currentLeaseRoundTotal + country.leaseRounds.length;
      country.leaseRounds.forEach((round: TimelineLeaseRound) => {
        events.push({
          id: tmpIds,
          resource: `${round.id}_${round.name}_2`,
          start: round.openDate,
          end: round.awardDate,
          closedDate: round.closeDate,
          estimated: round.estLeaseRoundDates || round.estAwardDate,
        });
        tmpIds++;
        if (moment(currentStartDate).isAfter(moment(round.openDate))) {
          currentStartDate = round.openDate;
        }

        if (moment(currentEndDate).isBefore(moment(round.awardDate))) {
          currentEndDate = round.awardDate;
        }
        round.bids.forEach((bid: TimelineBid) => {
          events.push({
            id: tmpIds,
            resource: `${bid.id}_${round.id}_${bid.name}_3`,
            bidName: bid.name,
            start: round.openDate,
            end: round.awardDate,
            status: bid.status,
            companies: bid.companies,
          });
        });
      });
    });

    return {
      events,
      resource: sortedResource,
      currentStartDate,
      currentEndDate,
      currentLeaseRoundTotal,
    };
  },
);

const leaseRoundTimelineSlice = createSlice({
  name: "leaseRoundTimeline",
  initialState,
  reducers: {
    setCollapsedAllResource: (state, action: PayloadAction<boolean>) => {
      const currentResource = current(state)?.resource || [];
      state.resource = currentResource?.map((item: Resource) => ({
        ...item,
        collapsed: action.payload,
      }));
    },
    setSelectedFilter: (state, action: PayloadAction<GenericType>) => {
      state.selectedFilter = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLeaseRoundTimelineEvents.pending, function (state) {
        state.resource = null;
        state.events = undefined;
        state.isLoading = true;
      })
      .addCase(fetchLeaseRoundTimelineEvents.rejected, (state) => {
        state.events = undefined;
        state.totalLeaseRoundCount = 0;
        state.isLoading = false;
      })
      .addCase(fetchLeaseRoundTimelineEvents.fulfilled, (state, action) => {
        state.resource = action.payload.resource;
        state.events = action.payload.events;
        state.startDate = action.payload.currentStartDate;
        state.endDate = action.payload.currentEndDate;
        state.totalLeaseRoundCount = action.payload.currentLeaseRoundTotal || 0;
        state.isLoading = false;
      });
  },
});

export const { setCollapsedAllResource, setSelectedFilter } =
  leaseRoundTimelineSlice.actions;
export const leaseRoundTimeline = leaseRoundTimelineSlice.reducer;
