import { FC } from "react";
import {
  Box,
  getEsgianTheme,
  HelpOutlineIcon,
  IconButton,
  ToolTip,
  Typography,
  VisibilityOffOutlinedIcon,
} from "@esgian/esgianui";

import { ReactComponent as ColorfulCircle } from "../../../assets/colorful-circle.svg";
import { useDispatch } from "../../../hooks/use-dispatch";
import { useSelector } from "../../../hooks/use-selector";
import { getThemeMode } from "../../../store/selector/common";
import { getMapActiveLegends } from "../../../store/selector/windLeasingAndProject";
import { setMapActiveLegends } from "../../../store/slice/windLeasingAndProject";
import { FeatureLegendsTypeEnum, THEME, ThemeModeEnum } from "../../../types";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);

  return {
    container: {
      position: "absolute",
      top: 16,
      left: 16,
      height: 308,
      width: 251,
      backgroundColor: theme.palette.secondary.dark,
      zIndex: 2,
      p: 2,
    },
    row: {
      display: "flex",
      alignItems: "center",
      mb: 1,
      cursor: "pointer",
    },
    colorBox: {
      height: 12,
      width: 12,
    },
    title: {
      ml: 1,
    },
    icon: {
      height: 16,
      width: 16,
      ml: 1,
    },
    hideBtn: {
      p: 0,
    },
  };
};

type Prop = {
  open: boolean;
  handleClose: () => void;
};

export const InfoModal: FC<Prop> = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);
  const mapActiveLegends = useSelector(getMapActiveLegends);

  return (
    <Box sx={{ ...classes.container, display: open ? "block" : "none" }}>
      <Box
        sx={{
          ...classes.row,
          opacity: mapActiveLegends.includes(FeatureLegendsTypeEnum.LeaseArea)
            ? 1
            : 0.5,
        }}
        onClick={() =>
          dispatch(setMapActiveLegends(FeatureLegendsTypeEnum.LeaseArea))
        }
      >
        <Box
          sx={{
            ...classes.colorBox,
            background: themeMode === ThemeModeEnum.Dark ? "#FFF" : "#000",
          }}
        />
        <Typography variant="body2" sx={classes.title}>
          Lease area
        </Typography>
        <ToolTip
          placement="right"
          title="Area selected for offshore wind lease rounds or scoping area for future rounds"
        >
          <HelpOutlineIcon sx={classes.icon} />
        </ToolTip>
      </Box>
      <Box
        sx={{
          ...classes.row,
          opacity: mapActiveLegends.includes(
            FeatureLegendsTypeEnum.ProjectProposed,
          )
            ? 1
            : 0.5,
        }}
        onClick={() =>
          dispatch(setMapActiveLegends(FeatureLegendsTypeEnum.ProjectProposed))
        }
      >
        <Box
          sx={{
            ...classes.colorBox,
            background: "#143BE7",
          }}
        />
        <Typography variant="body2" sx={classes.title}>
          Project - Proposed
        </Typography>
        <ToolTip
          placement="right"
          title="Wind farm proposal or wind energy area is not yet a part of an official leasing process"
        >
          <HelpOutlineIcon sx={classes.icon} />
        </ToolTip>
      </Box>
      <Box
        sx={{
          ...classes.row,
          opacity: mapActiveLegends.includes(
            FeatureLegendsTypeEnum.ProjectConfirmed,
          )
            ? 1
            : 0.5,
        }}
        onClick={() =>
          dispatch(setMapActiveLegends(FeatureLegendsTypeEnum.ProjectConfirmed))
        }
      >
        <Box
          sx={{
            ...classes.colorBox,
            background: "#2DA0FF",
          }}
        />
        <Typography variant="body2" sx={classes.title}>
          Project - Confirmed
        </Typography>
        <ToolTip
          placement="right"
          title="Wind farm proposal has been awarded a seabed lease"
        >
          <HelpOutlineIcon sx={classes.icon} />
        </ToolTip>
      </Box>
      <Box
        sx={{
          ...classes.row,
          opacity: mapActiveLegends.includes(
            FeatureLegendsTypeEnum.ProjectInstalling,
          )
            ? 1
            : 0.5,
        }}
        onClick={() =>
          dispatch(
            setMapActiveLegends(FeatureLegendsTypeEnum.ProjectInstalling),
          )
        }
      >
        <Box
          sx={{
            ...classes.colorBox,
            background: "#53C6CD",
          }}
        />
        <Typography variant="body2" sx={classes.title}>
          Project - Installing
        </Typography>
        <ToolTip
          placement="right"
          title=" Wind farm is currently being installed"
        >
          <HelpOutlineIcon sx={classes.icon} />
        </ToolTip>
      </Box>
      <Box
        sx={{
          ...classes.row,
          opacity: mapActiveLegends.includes(
            FeatureLegendsTypeEnum.ProjectOperational,
          )
            ? 1
            : 0.5,
        }}
        onClick={() =>
          dispatch(
            setMapActiveLegends(FeatureLegendsTypeEnum.ProjectOperational),
          )
        }
      >
        <Box
          sx={{
            ...classes.colorBox,
            background: "#9A6BF4",
          }}
        />
        <Typography variant="body2" sx={classes.title}>
          Project - Operational
        </Typography>
        <ToolTip
          placement="right"
          title="Wind farm is currently generating power and is actively operational"
        >
          <HelpOutlineIcon sx={classes.icon} />
        </ToolTip>
      </Box>
      <Box
        sx={{
          ...classes.row,
          opacity: mapActiveLegends.includes(
            FeatureLegendsTypeEnum.ProjectDecommissioned,
          )
            ? 1
            : 0.5,
        }}
        onClick={() =>
          dispatch(
            setMapActiveLegends(FeatureLegendsTypeEnum.ProjectDecommissioned),
          )
        }
      >
        <Box
          sx={{
            ...classes.colorBox,
            background: "#929292",
          }}
        />
        <Typography variant="body2" sx={classes.title}>
          Project - Decommissioned
        </Typography>
        <ToolTip
          placement="right"
          title="Wind farm has been decommissioned and is no longer operational"
        >
          <HelpOutlineIcon sx={classes.icon} />
        </ToolTip>
      </Box>
      <Box
        sx={{
          ...classes.row,
          opacity: mapActiveLegends.includes(
            FeatureLegendsTypeEnum.ProjectSuspended,
          )
            ? 1
            : 0.5,
        }}
        onClick={() =>
          dispatch(setMapActiveLegends(FeatureLegendsTypeEnum.ProjectSuspended))
        }
      >
        <Box
          sx={{
            ...classes.colorBox,
            background: "#696969",
          }}
        />
        <Typography variant="body2" sx={classes.title}>
          Project - Suspended
        </Typography>
        <ToolTip
          placement="right"
          title="Wind project development has been suspended"
        >
          <HelpOutlineIcon sx={classes.icon} />
        </ToolTip>
      </Box>
      <Box
        sx={{
          ...classes.row,
          opacity: mapActiveLegends.includes(
            FeatureLegendsTypeEnum.ProjectUndefined,
          )
            ? 1
            : 0.5,
        }}
        onClick={() =>
          dispatch(setMapActiveLegends(FeatureLegendsTypeEnum.ProjectUndefined))
        }
      >
        <ColorfulCircle />
        <Typography variant="body2" sx={classes.title}>
          Project or lease area - undefined or single location
        </Typography>
        <ToolTip
          placement="right"
          title="Project or lease area location undefined or project has a single turbine location"
        >
          <HelpOutlineIcon sx={classes.icon} />
        </ToolTip>
      </Box>
      <IconButton sx={classes.hideBtn} onClick={handleClose}>
        <VisibilityOffOutlinedIcon fontSize="small" />
        <Typography variant="body2" sx={classes.title}>
          Hide
        </Typography>
      </IconButton>
    </Box>
  );
};
