/* eslint-disable indent */
import React, { FC, useState } from "react";
import { FixedSizeList } from "react-window";
import {
  Autocomplete,
  Box,
  Chip,
  CloseIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  getEsgianTheme,
  ListItem,
  Paper,
  SearchIcon,
  TextField,
  Typography,
} from "@esgian/esgianui";

import { ReactComponent as FlagIcon } from "../../assets/flag.svg";
import { ReactComponent as LandscapeIcon } from "../../assets/landscape.svg";
import { ReactComponent as WaterIcon } from "../../assets/water.svg";
import { useSelector } from "../../hooks/use-selector";
import { getThemeMode } from "../../store/selector/common";
import {
  GenericType,
  SelectedFilterItem,
  THEME,
  ThemeModeEnum,
} from "../../types";

const useStyles = (themeMode: string) => {
  const theme = getEsgianTheme(themeMode, THEME);
  return {
    autocomplete: {
      width: 1,
    },
    paper: {
      maxHeight: "80vh",
      backgroundColor:
        themeMode === ThemeModeEnum.Dark
          ? theme.palette.common.black
          : theme.palette.common.white,
      "& .MuiAutocomplete-option[aria-selected='true'].Mui-focused, & .MuiAutocomplete-option[aria-selected='true']":
        {
          backgroundColor: `${theme.palette.secondary.dark} !important`,
          color: theme.palette.primary.main,
          "& path": {
            fill: theme.palette.primary.main,
          },
        },
    },
    groupBtn: {
      cursor: "pointer",
      py: 1,
      "& :first-letter": {
        textTransform: "uppercase",
      },
      "&:hover": {
        color: theme.palette.primary.main,
        "& path": {
          fill: theme.palette.primary.main,
        },
      },
    },
    groupTitle: {
      px: 2,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    groupSubTitle: {
      pl: 2,
      color: theme.palette.common.darkGray,
    },
    chip: {
      height: 20,
      pr: 1,
      position: "relative",
      "& .MuiChip-label": {
        mr: "4px !important",
      },
      "& svg": {
        position: "absolute",
        right: 0,
        fontSize: `16px !important`,
        "& path": {
          fill: theme.palette.common.white,
        },
      },
    },
  };
};

type Prop = {
  options: GenericType[];
  selectedItem?: number[] | SelectedFilterItem[] | SelectedFilterItem | null;
  setSelectedItem: (
    items: number[] | SelectedFilterItem[] | SelectedFilterItem,
  ) => void;
  labelKey: string;
  groupBy?: string;
  inputLabelText?: string;
  labelIcon?: React.ReactElement;
  multipleSelection?: boolean;
  size?: string;
  groupItemSubtitle?: GenericType;
  displayIcon?: boolean;
  disableCloseOnSelect?: boolean;
  searchIcon?: boolean;
  disableClearable?: boolean;
  placeholder?: string;
  sorting?: boolean;
  maxHeight?: string;
};

export const StyledAutocomplete: FC<Prop> = ({
  options,
  selectedItem,
  setSelectedItem,
  labelKey,
  groupBy,
  inputLabelText,
  labelIcon,
  size = "medium",
  multipleSelection = true,
  groupItemSubtitle,
  displayIcon = true,
  disableCloseOnSelect = true,
  disableClearable = false,
  searchIcon = false,
  sorting = false,
  placeholder,
  maxHeight = "45vh",
}) => {
  const themeMode = useSelector(getThemeMode);
  const classes = useStyles(themeMode);
  const [dropdownOpen, setDropDownOpen] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState("");

  const handleGroupClick = (value: string) => {
    if (dropdownOpen === value) {
      setDropDownOpen(null);
    } else {
      setDropDownOpen(value);
    }
  };

  const renderIcon = (type?: string) => {
    const iconMap: Record<string, JSX.Element> = {
      country: <FlagIcon />,
      "lease region": <LandscapeIcon />,
      "sea round": <WaterIcon />,
    };

    return type ? iconMap[type] : labelIcon;
  };

  const renderOption = (props: GenericType, option: GenericType) => (
    <ListItem
      {...props}
      key={`${option[labelKey]}_${option.value}_${props["data-option-index"]}`}
    >
      <Box sx={{ mr: "18px" }}>{displayIcon && renderIcon(option.type)}</Box>
      <Box>
        <Typography>{option[labelKey]}</Typography>
        {option.type && (
          <Typography
            sx={{
              fontSize: 12,
            }}
          >
            {option.subtype || option.type}
          </Typography>
        )}
      </Box>
    </ListItem>
  );

  const renderGroup = (params: GenericType) => (
    <Box key={params.key}>
      <Box sx={classes.groupBtn} onClick={() => handleGroupClick(params.group)}>
        <Box sx={classes.groupTitle}>
          <Typography variant="body1" color="info">
            {params.group}
          </Typography>

          {dropdownOpen === params.group ? (
            <ExpandMoreIcon />
          ) : (
            <ExpandLessIcon />
          )}
        </Box>
        {groupItemSubtitle && (
          <Typography
            variant="body2"
            color="secondary"
            sx={classes.groupSubTitle}
          >
            {groupItemSubtitle[params.group]}
          </Typography>
        )}
      </Box>
      {dropdownOpen === params.group && (
        <FixedSizeList
          height={
            params.children.length * 54 > 270
              ? 270
              : params.children.length * 54
          }
          itemCount={params.children.length}
          itemSize={54}
          width="100%"
        >
          {({ index, style }) => (
            <Box style={style} sx={{ overflow: "hidden" }}>
              {React.Children.toArray(params.children)[index]}
            </Box>
          )}
        </FixedSizeList>
      )}
    </Box>
  );

  const renderTags = (
    value: GenericType[],
    getTagProps: (params: { index: number }) => GenericType,
  ) =>
    value && (
      <Box>
        {value
          ?.slice(0, 2)
          .map((option, index) => (
            <Chip
              key={index}
              sx={classes.chip}
              label={option[labelKey]}
              deleteIcon={<CloseIcon />}
              {...getTagProps({ index })}
            />
          ))}
        {value?.length - 2 > 0 && `+${value.length - 2}`}
      </Box>
    );

  const CustomPaper = (props: React.ComponentProps<typeof Paper>) => (
    <Paper {...props} sx={classes.paper} />
  );

  return (
    <Autocomplete
      sx={{
        ...classes.autocomplete,
        "& .MuiAutocomplete-popupIndicator": searchIcon && {
          transform: "none",
        },
      }}
      multiple={multipleSelection}
      disableCloseOnSelect={disableCloseOnSelect}
      disableClearable={disableClearable}
      popupIcon={searchIcon ? <SearchIcon /> : undefined}
      options={
        sorting
          ? groupBy
            ? options
                .sort(
                  (a: GenericType, b: GenericType) =>
                    -b[labelKey].localeCompare(a[labelKey]),
                )
                .sort(
                  (a: GenericType, b: GenericType) =>
                    -b[groupBy].localeCompare(a[groupBy]),
                )
            : options.sort(
                (a: GenericType, b: GenericType) =>
                  -b[labelKey].localeCompare(a[labelKey]),
              )
          : options
      }
      groupBy={
        groupBy && !inputValue.length
          ? (option: GenericType) => option[groupBy]
          : undefined
      }
      getOptionLabel={(option: GenericType) =>
        labelKey ? option[labelKey] : ""
      }
      onInputChange={(_: Event, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      value={selectedItem}
      onChange={(_: Event, selectedOption: number[] | SelectedFilterItem[]) =>
        setSelectedItem(selectedOption)
      }
      renderInput={(params: GenericType) => {
        return (
          <TextField
            {...params}
            label={inputLabelText}
            placeholder={placeholder || "Select"}
            size={size}
          />
        );
      }}
      ListboxProps={{ style: { maxHeight } }}
      renderGroup={renderGroup}
      renderOption={renderOption}
      renderTags={renderTags}
      PaperComponent={CustomPaper}
    />
  );
};
