import { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Table, Typography } from "@esgian/esgianui";

import { calculateFilterCount } from "../../helper/fllter";
import { isMatch } from "../../helper/isMatch";
import { useDispatch } from "../../hooks/use-dispatch";
import { useSelector } from "../../hooks/use-selector";
import {
  getRowsPerPage,
  getSearchText,
  getThemeMode,
} from "../../store/selector/common";
import {
  getFactoryInfoList,
  getSelectedFilter,
  getSortingInfo,
} from "../../store/selector/factory";
import { setRowsPerPage, setSelectedFactoryId } from "../../store/slice/common";
import {
  fetchFactoryInfoList,
  setSortingInfo,
} from "../../store/slice/factory";
import {
  FactoryInfo,
  GenericType,
  IdNamePair,
  IdTypePair,
  ROUTES_CONFIG,
} from "../../types";
import { FilterBtn } from "../FilterBtn";
import { Loading } from "../Loading";

export const FactoriesList = () => {
  const dispatch = useDispatch();
  const factoryInfoList = useSelector(getFactoryInfoList);
  const selectedFilter = useSelector(getSelectedFilter);
  const searchText = useSelector(getSearchText);
  const theme = useSelector(getThemeMode);
  const sortingInfo = useSelector(getSortingInfo);
  const rowsPerPage = useSelector(getRowsPerPage);

  const data = useMemo(() => {
    let list = factoryInfoList;
    if (searchText?.length && list?.length) {
      list = list?.filter((item: FactoryInfo) => isMatch(item, [searchText]));
    }
    return list;
  }, [factoryInfoList, searchText]);

  const COLUMNS = useMemo(
    () => [
      {
        label: "Facility name",
        name: "name",
        options: {
          setCellProps: () => ({ style: { cursor: "pointer" } }),
          customBodyRender: (value: IdNamePair) => value || "N/A",
        },
      },
      {
        label: "Factory status",
        name: "factoryStatus",
      },
      {
        label: "Supplier",
        name: "factoryOwners",
      },
      { label: "Country", name: "country" },
      {
        label: "Factory functions",
        name: "factoryFunctions",
      },
      {
        label: "Equipment Types",
        name: "equipmentTypes",
        options: {
          customBodyRender: (value: IdTypePair[]) => {
            return value.map((item) => item.type)?.join(", ");
          },
        },
      },
      {
        label: "Contract Details",
        name: "btn",
        options: {
          filter: false,
          sort: false,
          empty: false,
          customBodyRender: (
            _: string,
            rowIndex: {
              rowData: string[];
              rowIndex: number;
            },
          ) => (
            <Button
              sx={{ cursor: "pointer" }}
              component={Link}
              to={`${ROUTES_CONFIG.FactoryContract.baseUrl}/${
                data?.find((f: GenericType) => f.name === rowIndex.rowData[0])
                  ?.id
              }`}
              variant="text"
            >
              Detail
            </Button>
          ),
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  useEffect(() => {
    dispatch(fetchFactoryInfoList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilter]);

  return (
    <Box sx={{ px: 2 }}>
      {data ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" sx={{ py: 2 }}>
              {`Displaying ${data?.length} Factories`}
            </Typography>
            <FilterBtn count={calculateFilterCount(selectedFilter)} />
          </Box>
          <Table
            app="wind"
            columnDefs={[]}
            columns={COLUMNS}
            data={data}
            elevation={0}
            mode={theme}
            options={{
              download: true,
              search: false,
              downloadOptions: {
                filename: `factories_data.csv`,
              },
              filter: false,
              filterType: "multiselect",
              hideToolbarMargin: false,
              pagination: true,
              responsive: "standard",
              rowsPerPage,
              onChangeRowsPerPage: (numberOfRows: number) => {
                dispatch(setRowsPerPage(numberOfRows));
              },
              onCellClick: (_: GenericType, cellMeta: GenericType) => {
                const { colIndex, dataIndex }: GenericType = cellMeta;
                const item: GenericType = data[dataIndex];
                if (colIndex === 0) {
                  dispatch(setSelectedFactoryId(item?.id || null));
                }
              },
              rowsPerPageOptions: [25, 50, 100],
              selectableRows: "none",
              sortOrder: sortingInfo,
              onColumnSortChange: (name: string, direction: string) => {
                dispatch(setSortingInfo({ name, direction }));
              },
              showResponsive: true,
              tableBodyHeight: "calc(100vh - 290px)",
              toolbar: true,
              print: false,
              viewColumns: true,
              sortThirdClickReset: true,
              expandableRows: false,
              expandableRowsOnClick: false,
              onViewColumnsChange: () => {},
            }}
          />
        </>
      ) : (
        <Loading />
      )}
    </Box>
  );
};
