import { getFloatingTechnology } from "./getFloatingTechnology";
import { getFloatingTechnologyInfo } from "./getFloatingTechnologyInfo";
import { getFloatingTechnologyAdaption } from "./getFloatingTechnologyAdaption";
import { getFloatingTechnologyShare } from "./getFloatingTechnologyShare";

export const floatingTechnology = {
  getFloatingTechnology,
  getFloatingTechnologyInfo,
  getFloatingTechnologyAdaption,
  getFloatingTechnologyShare
};
