import { Project } from "../../types";
import { ApiMethodDeclaration } from "../types";

type ParamsData = number;

type ResponseData = { data: Project[] };

export const getProject: ApiMethodDeclaration<ParamsData, ResponseData> = (
  id: number,
) => ({
  url: `/${id}?api-version=1.0`,
  method: "GET",
});
