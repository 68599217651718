import { getAuthor } from "./getAuthors";
import { getNewsFeed } from "./getNewsFeed";
import { getNewsFeedTagList } from "./getNewsFeedTagList";
import { getProducts } from "./getProducts";

export const newsFeed = {
  getNewsFeedTagList,
  getProducts,
  getAuthor,
  getNewsFeed
};
